/**
 * @file InfoDialog.js
 * @description Info Dialog Box
 * @exports InfoDialog - Dialog Box
 * @exports CloseDialog - Function to close the dialog box
 * @imports infoDialogMessage - Message to be displayed in the dialog box
 */

import { Grid, Dialog, DialogContent, Button } from '@mui/material';
import modalClose from "../assets/images/structure/close-black.svg";
import { useTranslation } from "react-i18next";

// Importing localised strings
const InfoDialog = ({ CloseDialog, infoDialogMessage }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={CloseDialog} className="main-modal theme-dialog">
      <img src={modalClose} alt="close" onClick={CloseDialog} className='modal-close' />
      <DialogContent>
        <h2 className='h3 text-center' style={{ marginBottom: '8px' }}>{t('OOPs')}</h2>
        <p className='p3 text-center' style={{ marginBottom: '12px', fontSize: '14px' }}>{infoDialogMessage}</p>
        <Grid item xs={12} textAlign="center" display={'flex'} justifyContent={'center'}>
          <Button type='button' size="medium" variant="text" color="error" onClick={CloseDialog}>{t('OK')}</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
};

export default InfoDialog;