import './skeleton.scss';
import {Skeleton, Stack, Avatar} from '@mui/material';

export const CategoryTypeSkeleton = ({ skeletons }) => {
  return (
    <div className='skeleton-body'>
      {Array.from(Array(skeletons), (e, i) =>
        <div className="category-type-skeleton" key={`skeleton-${i}`}>
          <Skeleton animation="wave" variant="rectangular" width={280} height={220} />
          <Skeleton animation="wave" width="50%" height={40} />
          <Skeleton animation="wave" width="85%" />
          <Skeleton animation="wave" width="30%" />
        </div>)
      }
    </div >
  );
}

export const BusinessCategortSkeleton = () => {
  return (
    <div style={{ paddingTop: '40px' }}>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width={'100%'} height={112} />
        <Skeleton variant="rectangular" width={'100%'} height={48} />
      </Stack>
      <div className='skeleton-body' >

      </div>
      <div className='skeleton-body' >
        <Skeleton variant="rectangular" width={'100%'} height={181} style={{ marginTop: '10px', marginRight: '10px' }} />
        <Skeleton variant="rectangular" width={'100%'} height={181} style={{ marginTop: '10px', marginRight: '10px' }} />
        <Skeleton variant="rectangular" width={'100%'} height={181} style={{ marginTop: '10px' }} />
      </div>
      <div className='skeleton-body' >
        <Skeleton variant="rectangular" width={'100%'} height={181} style={{ marginTop: '10px', marginRight: '10px' }} />
        <Skeleton variant="rectangular" width={'100%'} height={181} style={{ marginTop: '10px', marginRight: '10px' }} />
        <Skeleton variant="rectangular" width={'100%'} height={181} style={{ marginTop: '10px' }} />
      </div>
    </div >
  );
}