/**
 * @file ProtectedRouter.js
 * @description This component is used to check if user is logged in or not.
 * @exports ProtectedRouter component
 * @check user token in local storage
 * @redirect to Home page if user is not logged in else show the page
 * @reason to use this component is to avoid user to access pages if user is not logged in
 */

import { Outlet, Navigate } from "react-router-dom";
import { getLocalStorage, LOCAL_STORAGE_KEYS } from "../Helpers/crypto/LocalStorage";

const ProtectedRouter = () => {
  return getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN) ? <Outlet /> : <Navigate to='/' />
}

export default ProtectedRouter;