/**
 * @file snackbar.js
 * @description This file contains the snackbar store configuration
 */

import { createSlice } from "@reduxjs/toolkit";

export const snackbar = createSlice({
  name: "snackbar",
  initialState: {
    value: {
      isOpen: false,
      state: "error",
      message: "",
      vertical: 'top',
      horizontal: 'right'
    },
  },
  reducers: {
    changeSnackbar: (state, action) => {
      state.value = action?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeSnackbar } = snackbar?.actions;
export const currentSnackbar = (state) => state?.snackbar?.value;
export default snackbar?.reducer;
