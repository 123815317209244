import "./Wishlist.scss";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getRequest, deleteRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { currentWishlist, changeWishlist  } from "../../redux/reducers/wishlist";
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import Footer from "../layout/footer/Footer";
import Breadcrumb from "../../shared/Breadcrumb";
import emptyCart from "../../assets/images/structure/empty-cart.webp";
import WishlistProductBox from "../../global-modules/Product/WishlistProductBox";

const Wishlist = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  let wishlist = useSelector(currentWishlist);

  const getWishlist = async () => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const { ack, data, discountDataOfAll } = await getRequest(API_ROUTES.WISHLIST.GET_WISHLIST_DATA);
      if (ack === 1) {
        const productOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'product');
        const categoryOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'category');
        const storeOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'store');
        const businessOffers = discountDataOfAll?.filter((item) => item?.apply_type === "business_type");
        const products = data.map((item) => {
          let offers = productOffers?.filter((offer) =>
            offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id).length > 0) ?
            productOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id)) :
            productOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId));
          if (offers?.length === 0) {
            offers = categoryOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId).length > 0) ?
              categoryOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId)) :
              categoryOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id));
          }

          if (offers?.length === 0) {
            offers = storeOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id).length > 0) ?
              storeOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id)) :
              storeOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId));
          }
          if (offers?.length === 0) {
            offers = businessOffers?.filter((offer) => offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId))
          }
          item.offers = offers;
          return item;
        });
        setLocalStorage(LOCAL_STORAGE_KEYS.WISHLIST, JSON.stringify(products))
        dispatch(changeWishlist());
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: msg,
          state: "error",
        }));
      }
    }
  }

  useEffect(() => {
    getWishlist();
  }, [])

  return (
    <>
      <div className="wishlist-wrapper">
        <div className="container-body">
          {wishlist.length === 0 ? (
            <div className="empty-wishlist-block">
              <img src={emptyCart} alt="" />
              <h4 className="h4">{t("wishlist_Yourwishlistisempty")}</h4>
              <p className="p2">{t("wishlist_ThereisnothinginyourwishlistLetsaddsomeproducts")}</p>
              <Link to={"/"} className="no-decoration">
                <Button variant="contained" size="large" sx={{ width: "180px" }}>
                  {t("wishlist_ShopNow")}
                </Button>
              </Link>
            </div>
          ) : (
            <>
              <Breadcrumb
                breadcrums={[
                  {
                    title: t("wishlist_homepage"),
                    haveLink: true,
                    link: "/",
                  },
                  {
                    title: t("wishlist"),
                    haveLink: false,
                  },
                ]}
              />
              <div className="wishlist-block">
                <div className="products-block">
                  {wishlist?.map((item, index) => (
                    <WishlistProductBox
                      itemId={item?.id}
                      storesId={item?.storeId}
                      online={item?.product?.online_status === 1}
                      product={item?.product}
                      offers={item?.offers}
                      index2={index}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Wishlist;
