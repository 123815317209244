import "./ManageAddress.scss"
import { useState, useEffect, useCallback } from 'react';
import { Grid, Button } from '@mui/material';
import { Boxed } from "../../Styles-Elements/Box"
import { getRequest, deleteRequest } from '../../Helpers/Axios/Services'
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes'
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { useTranslation } from "react-i18next";
import { setLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage'
import { changeAddress } from "../../redux/reducers/address";
import Sidebar from '../layout/sidebar/Sidebar'
import deleteRed from "../../assets/images/structure/delete-red.svg"
import editBlack from "../../assets/images/structure/edit-gray.svg"
import AddAddress from '../../DialogBox/Address/AddAddress';
import RemoveAddress from './RemoveAddress';
import NoDataFound from '../../global-modules/EmptyPages/NoDataFound';
import InfoDialog from '../../DialogBox/InfoDialog';

const ManageAddress = () => {
  const { t } = useTranslation();

  const [openAddAddress, setOpenAddress] = useState(false);
  const [openRemoveCard, setOpenRemoveCard] = useState(false);
  const [openEditCard, setOpenEditCard] = useState(false);
  let [MyAddressess, setMyAddressess] = useState([]);
  let [addressRemoveId, setAddressRemoveId] = useState(0);
  let [addressEditId, setAddressEditId] = useState(0);
  let [openInfoDialog, setOpenInfoDialog] = useState(false);
  let [infoDialogMessage, setInfoDialogMessage] = useState('');

  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);

  const OpenAddAddress = () => {
    setOpenAddress(true);
  };

  const OpenEditAddress = (id) => {
    addressEditId = id;
    setAddressEditId(addressEditId)
    setOpenEditCard(true);
  };

  const OpenRemoveCard = (id) => {
    addressRemoveId = id;
    setAddressRemoveId(addressRemoveId)
    setOpenRemoveCard(true);
    // if (MyAddressess.length > 1) {
    // } else {
    //   infoDialogMessage = t('Address_atleast_one_address_required');
    //   setInfoDialogMessage(infoDialogMessage)
    //   setOpenInfoDialog(true);
    // }
  };

  const setDefaultAddress = async () => {
    const response = await getRequest(API_ROUTES.ADDRESS.GET_ADDRESS);
    if (response.ack === 1) {
      const address = response.addresses.find((address) => address.default_address === true);
      setLocalStorage(LOCAL_STORAGE_KEYS.TOTAL_ADDRESS, response.addresses.length);
      setLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_ADDRESS, JSON.stringify(address));
      dispatch(changeAddress());
    }
  }

  const handleRemoveAddress = async () => {
    dispatch(changeLoader(true));
    if (addressRemoveId !== 0) {
      const response = await deleteRequest(API_ROUTES.ADDRESS.REMOVE_ADDRESS(addressRemoveId));
      if (response.ack === 1) {
        addressRemoveId = 0; setAddressRemoveId(addressRemoveId);
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t('Address_address_removed_successfully'),
          state: "success",
        }));
        dispatch(changeLoader(false));
        await getAddressList()
        setDefaultAddress()
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        }));
        dispatch(changeLoader(false));
        getAddressList()
      }
    } else {
      dispatch(changeLoader(false));
    }
  };

  const getAddressList = useCallback(async () => {
    dispatch(changeLoader(true));
    const response = await getRequest(API_ROUTES.ADDRESS.GET_ADDRESS);
    dispatch(changeLoader(false));
    if (response.ack === 1) {
      MyAddressess = response.addresses;
      setMyAddressess(MyAddressess)
    } else {
      MyAddressess = [];
      setMyAddressess(MyAddressess)
    }
  })

  useEffect(() => {
    getAddressList();
  }, [])

  return (
    <div>
      <div className="main-wrapper full-bg">
        <Sidebar />
        <div className="main-mid-wrapper">
          <div className="manage-address-wrapper">
            <Boxed display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'20px'}>
              <div className="manage-address-head" style={{ marginBottom: '0px' }}>
                <h3 className="h3" style={{ marginRight: '0px' }}>{t('Address_manageAddress')}</h3>
              </div>
              <Button variant="contained" size="large" sx={{ width: '150px', }} className="date-picker-btn" onClick={OpenAddAddress}>{t('Address_addAddress')}</Button>
            </Boxed>
            {MyAddressess?.length > 0 ? <Grid container columnSpacing={'0px'}>
              {MyAddressess?.map((address, index) => <Grid key={`address-${index}`} item xs={12}>
                <div className='manage-address-wrap'>
                  <p className='p1  cursor-pointer' onClick={() => OpenEditAddress(address.id)}
                    style={{
                      display: 'flex'
                    }}
                  >
                    <span
                      style={{
                        maxWidth: '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'block',
                      }}
                    >{address.full_name} </span> |
                    +{address.country_code} - {address.contact_number}</p>
                  <p className='address-block  cursor-pointer' onClick={() => OpenEditAddress(address.id)}>
                    {address?.business_address && address?.business_address ?
                      `${address?.apartment_number} ${address?.building_number} ${address?.street_number} ${address?.zone_number} ${address?.business_address}` :
                      `${address?.street_number}, ${address?.building_number},${address?.zone_number}, ${address?.country}`}
                  </p>
                  <span>
                    <img src={editBlack} className="settingimg" onClick={() => OpenEditAddress(address.id)} alt="delete" />
                    <img src={deleteRed} className="settingimg" onClick={() => OpenRemoveCard(address.id)} alt="delete" />
                  </span>
                </div>
              </Grid>)}
            </Grid> : <NoDataFound text={t('Address_no_Address_found')} />}
          </div>
        </div>
      </div>

      {openRemoveCard ? <RemoveAddress
        handleClose={() => setOpenRemoveCard(false)}
        removeAddress={() => {
          setOpenRemoveCard(false);
          handleRemoveAddress();
        }}
      /> : null}

      {openEditCard ? <AddAddress
        addressEditId={addressEditId}
        handleClose={() => setOpenEditCard(false)}
        addressSaved={() => { setOpenEditCard(false); getAddressList() }}
      /> : null}

      {openAddAddress ? <AddAddress
        handleClose={() => setOpenAddress(false)}
        addressSaved={() => { setOpenAddress(false); getAddressList() }}
      /> : null}

      {openInfoDialog ? <InfoDialog
        infoDialogMessage={infoDialogMessage}
        CloseDialog={() => setOpenInfoDialog(false)}
      /> : null}

    </div>
  );
}

export default ManageAddress;
