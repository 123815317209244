import defaultImage from "../../assets/images/structure/default.jpeg";

const Image = (props) => {
  return (
		<img
			{...props}
			onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }}
			loading="lazy"
		/>
  );
};

export default Image;