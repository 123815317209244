import "./footer.scss"
import { Grid } from "@mui/material";
import {Link} from "react-router-dom";
import { useEffect } from "react";
import logo from "../../../assets/images/logo/header-logo.svg";
import facebookWhite from "../../../assets/images/structure/facebook-white.svg";
import twitterWhite from "../../../assets/images/structure/twitter-white.svg";
import youtubeWhite from "../../../assets/images/structure/youtube-white.svg";
import instagramWhite from "../../../assets/images/structure/instagram-white.svg";
import appleStore from "../../../assets/images/structure/apple-store.svg";
import googlePlay from "../../../assets/images/structure/google-play.svg";
import copyrightGray from "../../../assets/images/structure/copyright-gray.svg";
import { axiosConstant } from '../../../Helpers/Axios/Constants';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="footer-wrapper">
        <div className="container-footer">
          <div className="mid-wrap">
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={3}>
                <img src={logo} alt=".." className="footer-logo" />
                <span className="head line-top">{t("followUsOn")}</span>
                <div className="footer-social-wrap">
                  <a href="https://www.facebook.com/baladiexpress" target="_blank">
                    <img src={facebookWhite} className="social-icons" alt="facebook" />
                  </a>
                  <a href="https://twitter.com/Baladiexpress" target="_blank">
                    <img src={twitterWhite} className="social-icons" alt="twitter" />
                  </a>
                  <a href="https://www.youtube.com/@baladiexpress" target="_blank">
                    <img src={youtubeWhite} className="social-icons" alt="youtube" />
                  </a>
                  <a href="https://www.instagram.com/baladiexpress/" target="_blank">
                    <img src={instagramWhite} className="social-icons" alt="instagram" />
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <span className="head">{t("LetUsHelpYou")}</span>
                <Link to={"/privacy_policy/get"} className="p1">{t("privacyPolicy")}</Link>
                <Link to={"/terms-conditions"} className="p1">{t("termsAndConditions")}</Link>
                <Link to={"/warrenty_policy/get"} className="p1">{t("warrantyPolicy")}</Link>
                <Link to={"/delivery_information/get"} className="p1">{t("deliveryInformation")}</Link>
              </Grid>
              <Grid item xs={12} sm={3} md={2} lg={3} xl={3}>
                <span className="head">{t("GetToKnowUs")}</span>
                <Link to={"/about_baladi/get"} className="p1">{t("AboutBaladi")}</Link>
                <Link to={"/contact-us"} className="p1">{t("contactUs")}</Link>
                <Link to={"/faq"} className="p1">{t("FAQs")}</Link>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                <span className="head">{t("InstallApp")}</span>
                <span className="p1">{t("FromAppStoreOrGooglePlay")}</span>
                <a className="app-download" href="https://play.google.com/store/apps/details?id=baladi.customer.app.baladi_customer_app" target="_blank">
                  <img src={googlePlay} alt=".." className="img-height41" />
                </a>
                <a className="app-download" href="https://apps.apple.com/qa/app/baladiexpress/id1635029670" target="_blank">
                  <img src={appleStore} alt=".." className="img-height41" />
                </a>

                <a href={axiosConstant.VENDOR_URL} target="_blank" className="a-red-bold">
                  {t("BecomePartner")}
                </a>
              </Grid>
            </Grid>
          </div>
          <div className="bottom-wrap">
            {/* <img src={copyrightGray} alt="copyright" /> */}
            <span className="p2">
              Copyright &nbsp; &copy; &nbsp;{" "}
              {new Date().getFullYear() + " "}
              {t("BaladiExpressAllRightsReserved", { year: new Date().getFullYear() })}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;