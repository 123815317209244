import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";
import { setLocalStorage, LOCAL_STORAGE_KEYS } from "../Helpers/crypto/LocalStorage";
import { postRequest, getRequest } from "../Helpers/Axios/Services";
import { API_ROUTES } from "../Helpers/Axios/ApiRoutes";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "redux/reducers/snackbar";

const PaymentCancelRedirect = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleCheckPayment = async () => {
    const response = await getRequest(API_ROUTES.AUTHENTICATION.REFRESH_TOKEN)
    if (response.ack === 1) {
      setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response.data);
    }
    let data = {};
    const queryParams = Object.fromEntries(searchParams.entries());
    for (const [key, value] of Object.entries(queryParams)) {
      data[key] = value;
    }
    const { ack, msg, order_id } = await postRequest(API_ROUTES.CART.CANCEL_PAYMENT_ORDER, data)
    let orderId = order_id ? order_id : searchParams.get('trctn');
    dispatch(changeSnackbar({
      isOpen: true,
      message: msg,
      state: 'error'
    }))
    window.location.href = `/cart`;
    // navigate(`/cart`);
  };

  useEffect(() => {
    handleCheckPayment();
  }, []);

  return (
    <div className="thankyou-content">
      <CircularProgress size={100} />
      <h3 className="h3">{t('cart_checkPaymentConnection')}</h3>
    </div>
  );
};

export default PaymentCancelRedirect;
