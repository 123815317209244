import "./Products.scss"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Boxed } from "../../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { axiosConstant } from '../../../Helpers/Axios/Constants';
import { changeCart } from '../../../redux/reducers/cart';
import { currentSnackbar } from "../../../redux/reducers/snackbar";
import { postRequest } from '../../../Helpers/Axios/Services';
import { API_ROUTES } from '../../../Helpers/Axios/ApiRoutes';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../../Helpers/crypto/LocalStorage';
import { getDistanceFromLatLonInKm } from '../../../Helpers/HelperFunctions';
import { currentAddress } from '../../../redux/reducers/address';
import distanceIcon from "../../../assets/images/structure/distance.svg"
import nextRed from "../../../assets/images/structure/next-red.svg"
import jwt_decode from "jwt-decode";
import ProductBox from '../../../global-modules/Product/ProductBox';
import Image from "../../../global-modules/Image/Image";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import ComingSoon from '../../../global-modules/ComingSoon/ComingSoon';

const Products = ({ search, storeProducts, handleChangeDiscount }) => {

  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState(null);
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };
  const userAddress = useSelector(currentAddress)
  let [userToken, setUserToken] = useState(null);
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);

  const getUserToken = () => {
    const UserToken = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (UserToken) {
      userToken = UserToken;
      setUserToken(userToken);
    } else {
      userToken = null;
      setUserToken(userToken);
    }
  }

  const getCartData = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`;
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId
    });
    setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
    dispatch(changeCart());
  }

  useEffect(() => {
    getUserToken();
    getCartData();
  }, [])

  useEffect(() => {
    handleChangeDiscount(activeButton);
  }, [activeButton]);

  return (
    <div>
      <div className="container-body">
        {search !== "" ? (
          <div className="search-results-block">
            <span className="p1">{t("storefront_searchResultFor")}</span>
            <span className="p1-semibold"> {search}</span>
          </div>
        ) : null}
        <div className="products-wrapper">
          {/* <div className='discount-button-row'>
            {discountDataOfAll?.filter((discount) => discount.applly_type === "flat") ? <button
              className={activeButton === 'flat' ? 'discount-button active' : 'discount-button'}
              onClick={() => handleButtonClick('flat')}
            >
              {t("storefront_FlatDiscount")}
            </button> : null}
            {discountDataOfAll?.filter((discount) => discount.applly_type === "percentage") ?
              <button
                className={activeButton === 'percentage' ? 'discount-button active' : 'discount-button'}
                onClick={() => handleButtonClick('percentage')}
              >
                {t("storefront_PercentageDiscount")}
              </button> : null}
            {discountDataOfAll?.filter((discount) => discount.applly_type === "bogo") ?
              <button
                className={activeButton === 'bogo' ? 'discount-button active' : 'discount-button'}
                onClick={() => handleButtonClick('bogo')}
              >
                {t("storefront_BOGO_Buyonegetone_")}
              </button> : null}
          </div> */}

          {storeProducts?.length > 0
            ? storeProducts?.map((store, index) =>
              store?.products.length > 0 ? (
                <Boxed key={`store-product-${index}`}>
                  <Boxed
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    className="shop-more-block"
                  >
                    <Boxed display={"flex"} alignItems={"center"} className="">
                      <div className="logo-box">
                        <Image
                          src={axiosConstant.IMAGE_BASE_URL(store?.business_logo)}
                          className="logo-img"
                          alt={store?.stores_locales[0]?.business_name}
                        />
                      </div>
                      <div className="content-box">
                        <h3 className="h3 detail-chips-parent">
                          <span className="ml-1">
                            {store?.stores_locales[0]?.business_name}
                          </span>
                          {/* this is my offer code please don't remove code  */}
                          <div className="chips-row">
                            {store?.offers?.map((offer, index) =>
                              <span className="chips" key={`offerstoreDetails-${index}`} >{offer.name}</span>)}
                          </div>
                        </h3>
                        <Boxed display={"flex"} alignItems={"center"}>
                          <img src={distanceIcon} alt="distanceIcon" />
                          {/* <span className="p2">10 {t('storefront_mins')}</span> */}
                          <span className="p2 p2-mins">
                            {getDistanceFromLatLonInKm(
                              userAddress?.latitude,
                              userAddress?.longitude,
                              store?.latitude,
                              store?.longitude
                            )}{" "}
                            {t("kmAway")}
                          </span>
                        </Boxed>
                      </div>
                    </Boxed>
                    <Boxed display={"flex"} alignItems={"center"} style={{ cursor: "pointer" }}>
                      <Link to={`/store/${store?.slug && store?.slug !== '' ? store?.slug : store?.id}`} className="no-decoration">
                        <span className="p1">{t("storefront_shopMore")}</span>
                        <img src={nextRed} alt="nextRed" />
                      </Link>
                    </Boxed>
                  </Boxed>
                  {store?.products.length > 0 ? (
                    <div className="products-block">
                      <Slider
                        centerMode={false}
                        dots={false}
                        infinite={store?.products?.length > 5 ? true : false}
                        speed={500}
                        slidesToShow={5}
                        responsive={
                          [
                            {
                              breakpoint: 1024,
                              settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                              }
                            },
                            {
                              breakpoint: 600,
                              settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                              }
                            },
                          ]
                        }
                      >
                        {store?.products.map((product, index2) =>
                          index2 < 10 ? (
                            <ProductBox
                              key={`product-${index2}`}
                              storeId={store?.id}
                              storeSlug={store?.slug}
                              online={store?.online_status === 1}
                              product={product}
                              index2={index2}
                              uoms={product?.uoms}
                            />
                          ) : null
                        )}
                      </Slider>
                    </div>
                  ) : null}
                </Boxed>
              ) : null
            )
            : search === '' ? <ComingSoon /> : <NoDataFound text={t('storefront_NoProductsFound')} />}
        </div>
      </div>
    </div>
  );
};

export default Products;