import { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import { useTranslation } from "react-i18next";
import { putRequest } from '../Helpers/Axios/Services';
import { API_ROUTES } from '../Helpers/Axios/ApiRoutes';
import { useSelector, useDispatch } from "react-redux";
import { currentCart } from '../redux/reducers/cart';
import { axiosConstant } from '../Helpers/Axios/Constants';
import { changeSnackbar, currentSnackbar } from "../redux/reducers/snackbar";
import RefreshCart from "./RefreshCart";

const SpecialRequestPopup = ({
	type,
	noteId,
	handleClose,
	RefreshCart
}) => {

    const { t } = useTranslation();
		const dispatch = useDispatch();
    let cartData = useSelector(currentCart);
		const snackbar = useSelector(currentSnackbar);
    let [notes, setNotes] = useState('');
    let [noteError, setNoteError] = useState('');

		const handleUpdateNote = async () => {
			const response = await putRequest(API_ROUTES.CART.UPDATE_SPECIAL_REQUEST(noteId), {
				specialRequest: notes,
				type: type,
			});
			if (response.ack === 1) {
				dispatch(
          changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "success",
          })
        );
				RefreshCart();
			} else {
				dispatch(
          changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "error",
          })
        );
			}

		}

		useEffect(() => {
			if (type === 'order') {
				notes = cartData?.specialRequest;
				setNotes(notes);
			} else if (type === 'item') {
				notes = cartData?.cart_items.find(item => item.id === noteId)?.specialRequest;
				setNotes(notes);
			}
		}, [type, noteId]);

    return (
        <Dialog
          open={true}
          onClose={handleClose}
          className="main-modal maxwidth-500"
        >
          <DialogContent>
            <div className="top-header-modal">
            <img
              src={modalClose}
              alt="close"
              onClick={handleClose}
              className="modal-close"
            />
              <div className="relative">
							<h4 className="h4">Order note</h4>
              </div>
            </div>
            <div className="conent-scroll-modal">
              <div className="flex-center-between mb-4">
                <h4 className="h4">{t('storefront_AnySpecialRequest')}</h4>
              </div>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={2}
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                  setNoteError('');
                }}
              />
              <span className="redspan">{noteError}</span>
            </div>
            <div className="footer-modal">
							<Button size="large" fullWidth  variant="contained" onClick={handleUpdateNote}> Add Note</Button>
            </div>
          </DialogContent>
        </Dialog>
      );
};

export default SpecialRequestPopup;