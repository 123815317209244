/**
 * @file UpdatePassword.js
 * @description Update Password Dialog Box
 * @exports UpdatePassword - Dialog Box
 * @exports handleClose - Function to close the dialog box
 * @exports loginOpen - Function to open the login dialog box
 * @imports code - OTP sent to the user
 * @imports userId - User Id of the user
 */

import { useState } from 'react';
import {
  Grid, Dialog, DialogContent, Button, InputLabel,
  InputAdornment, FormControl, IconButton, OutlinedInput
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import modalClose from "../assets/images/structure/close-black.svg"
import { postRequest } from '../Helpers/Axios/Services'
import { API_ROUTES } from '../Helpers/Axios/ApiRoutes'
import { useDispatch } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { useTranslation } from "react-i18next";
import { validatePassword } from '../Helpers/HelperFunctions'

const UpdatePassword = ({ code, userId, handleClose, loginOpen }) => {

  const { t } = useTranslation();
  let [password, setPassword] = useState('');
  let [passwordError, setPasswordError] = useState('');
  let [showPassword, setShowPassword] = useState(true);
  let [passwordConfirm, setPasswordConfirm] = useState('');
  let [passwordConfirmError, setPasswordConfirmError] = useState('');
  let [showPasswordConfirm, setShowPasswordConfirm] = useState(true);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(changeLoader(true));
    let isValid = true;
    if (password === "") {
      setPasswordError(t('errorPassword')); isValid = false;
    } else if (password.length < 8) {
      setPasswordError(t('errorPasswordLength'));
      isValid = false;
    } else if (!validatePassword(password)) {
      setPasswordError(t('cpPasswordShould'));
      isValid = false;
    }
    if (passwordConfirm === '') {
      setPasswordConfirmError(t('requirePasswordConfirm'));
      isValid = false;
    } else if (passwordConfirm !== password) {
      setPasswordConfirmError(t('errorPasswordConfirm'));
      isValid = false;
    }
    if (isValid) {
      const data = {
        user_id: userId,
        code: code,
        password: password
      }
      const response = await postRequest(API_ROUTES.AUTHENTICATION.Setpassword, data);
      dispatch(changeLoader(false));
      if (response.ack === 1) {
        loginOpen(response);
      } else {
        if (response?.msg && response?.msg !== '') {
          setPasswordConfirmError(response?.msg);
        } else if (response?.errMsg) {
          response?.errMsg.forEach(element => {
            for (const [key, value] of Object.entries(element)) {
              setPasswordConfirmError(value);
            }
          });
        }
      }
    } else {
      dispatch(changeLoader(false));
    }
  }

  return (
    <Dialog open={true} onClose={handleClose} className="main-modal">
      <img src={modalClose} alt="close" onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h2 className="h3 text-center">{t("updatePassword")}</h2>
        <p className="p3 text-center">
          {t("mustIncludeAtLeast8CharactersShouldNotHaveAnyPartOfYourName")}
        </p>
        <form onSubmit={handleSubmit} action="#" method="post">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" className="large-error">
                <InputLabel htmlFor="outlined-adornment-password">{t("password") + "*"}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "password" : "text"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("password") + "*"}
                />
                <span className="errorspan">{passwordError}</span>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {t("confirmPassword") + "*"}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPasswordConfirm ? "password" : "text"}
                  onChange={(e) => {
                    setPasswordConfirm(e.target.value);
                  }}
                  value={passwordConfirm}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                        edge="end"
                      >
                        {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("confirmPassword") + "*"}
                />
                <span className="errorspan">{passwordConfirmError}</span>
              </FormControl>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ width: 1, marginTop: "16px" }}
              >
                {t("update")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePassword;