/**
 * @file OrderBox.js
 * @description This component is used to show Order status GIFs.
 * @exports OrderBox component
 * @param {string} status - status of order
 */

import orderPlacedGif from "../../assets/images/gifs/gif_order_placed.gif";
import processingGif from "../../assets/images/gifs/gif_processing.gif";
import completeGif from "../../assets/images/gifs/gif_completed.gif";
import shippingGif from "../../assets/images/gifs/gif_shipping.gif";
import rejectedGif from "../../assets/images/gifs/gif_processing.gif";
import cancelledGif from "../../assets/images/gifs/gif_processing.gif";

const OrderBox = ({ status, tracking_link = '' }) => {

  return (
    <>
      {status === 'pending' ? <img src={orderPlacedGif} alt="..." className="talkingImg" /> : null}
      {status === 'placed' ? <img src={orderPlacedGif} alt="..." className="talkingImg" /> : null}
      {status === 'processing' ? <img src={processingGif} alt="..." className="talkingImg" /> : null}
      {status === 'readytopick' ? <img src={processingGif} alt="..." className="talkingImg" /> : null}
      {status === 'shipped' ? (tracking_link !== '' && tracking_link !== null)?
        <iframe src={tracking_link} title={tracking_link} className="talkingImg" frameBorder="0" height={'300px'} width={'590px'} />:
        <img src={shippingGif} alt="..." className="talkingImg" />
      : null}
      {status === 'completed' ? <img src={completeGif} alt="..." className="talkingImg" /> : null}
      {status === 'rejected' ? <img src={rejectedGif} alt="..." className="talkingImg" /> : null}
      {status === 'cancelled' ? <img src={cancelledGif} alt="..." className="talkingImg" /> : null}
    </>
  );
}

export default OrderBox;