import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Sidebar.scss"
import dashboardIcon from "../../../assets/images/structure/dashboard_icon.svg"
import locationIcon from "../../../assets/images/structure/location-black.svg"
import walletIcon from "../../../assets/images/structure/wallet-icon.svg"
import cardIcon from "../../../assets/images/structure/card-icon.svg"

import sidebarArrowIcon from "../../../assets/images/structure/sidebar-arrow-icon.webp";
import { useTranslation } from "react-i18next";

const Header2 = () => {
	const { t } = useTranslation();
	let history = useLocation();
	let [activeOption, SetActiveOption] = useState('orders')
	
	const [isActive, setIsActive] = useState(false);
	const toggleClass = () => {
		setIsActive(!isActive);
	};

	const checkActiveUrl = () => {
		switch (history.pathname) {
			case '/my-profile':
				SetActiveOption('profile');
				break;
			case '/order-history':
				SetActiveOption('orders');
				break;
			case '/manage-cards':
				SetActiveOption('cards');
				break;
			case '/manage-address':
				SetActiveOption('address');
				break;
			case '/wallet':
				SetActiveOption('wallet');
				break;
			case '/add-money':
				SetActiveOption('wallet');
				break;
			default:
				break;
		}
	}

	useEffect(() => {
		checkActiveUrl();
		setInterval(() => { checkActiveUrl(); }, 500);
	}, [])

	return (
    <>
      <div className={isActive ? "sidebar-wrapper show" : "sidebar-wrapper"}>
        <div className="show-arrow" onClick={toggleClass}>
          <img src={sidebarArrowIcon} alt="show-arrow" className="show-arrow-icon" />
        </div>
        <div className="sidebar-block">
          <Link
            to={"/order-history"}
            className={activeOption === "orders" ? "sidebar-links active" : "sidebar-links"}
          >
            <img src={dashboardIcon} className="sidebar-icon" alt="orders" />
            <span className="p2">{t("sidebarMyOrders")}</span>
          </Link>
          <Link to={'/manage-cards'} className={activeOption === 'cards' ? 'sidebar-links active' : 'sidebar-links'}>
		  	<img src={cardIcon} className="sidebar-icon" alt="cards" />
		  	<span className='p2'>{t('sidebarManageCards')}</span>
		  </Link>
          <Link
            to={"/manage-address"}
            className={activeOption === "address" ? "sidebar-links active" : "sidebar-links"}
          >
            <img src={locationIcon} className="sidebar-icon" alt="address" />
            <span className="p2">{t("sidebarManageAddress")}</span>
          </Link>
          <Link
            to={"/wallet"}
            className={activeOption === "wallet" ? "sidebar-links active" : "sidebar-links"}
          >
            <img src={walletIcon} className="sidebar-icon" alt="wallet" />
            <span className="p2">{t("sidebarWallet")}</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header2;