import "./home.scss";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

import { Button, TextField, Grid, Select, MenuItem } from "@mui/material";
import { getRequest } from "../../Helpers/Axios/Services";
import { API_ROUTES } from "../../Helpers/Axios/ApiRoutes";
import { CategoryTypeSkeleton } from '../../global-modules/Skeletons/AllSkeletons';
import Footer from "../layout/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import searchWhite from "../../assets/images/structure/search-white.svg";
import category1 from "../../assets/images/products/category1.jpg";
import sec3Img from "../../assets/images/structure/sec3-img.webp";
import revenueGreen from "../../assets/images/structure/revenue-green.svg";
import deliveryGreen from "../../assets/images/structure/delivery-green.svg";
import sec4Img from "../../assets/images/structure/sec4-img.webp";
import playStore from "../../assets/images/structure/play-store.svg";
import appleStore from "../../assets/images/structure/apple-store.svg";
import homeSliderImage from "../../assets/images/structure/home-banner.jpg";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { axiosConstant } from '../../Helpers/Axios/Constants';
import { changeActiveLink } from "../../redux/reducers/activeLink";
import { setLocalStorage, LOCAL_STORAGE_KEYS, getLocalStorage } from '../../Helpers/crypto/LocalStorage';
import Image from "../../global-modules/Image/Image";
import { currentAddress } from '../../redux/reducers/address';
import { ampli } from "../../../src/ampli";
import jwt_decode from "jwt-decode";
import {currentBaladiData} from '../../redux/reducers/baladiData';

const Home = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  let [type, setTypes] = useState('grocery');
  let [businessTypes, setBusinessTypes] = useState([]);
  let [search, setSearch] = useState('');
  let [isLoading, setIsLoading] = useState(true);
  let [banners, setBanners] = useState([]);
  const userAddress = useSelector(currentAddress)
  const baladiData = useSelector(currentBaladiData)

  const handleChange = (event) => {
    setTypes(event.target.value);
  };

  const getBannerData = async () => {
    let url = API_ROUTES.COMMON.GET_BANNER(10, 1);
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const response = await getRequest(url);
    banners = (response?.data?.rows?.length > 0) ? response?.data?.rows : [];
    setBanners(banners);
  }

  const gotoBrandStore = async (banner) => {
    
    const response = await getRequest(API_ROUTES.COMMON.GET_NEAREST_STORE_BY_BRAND(banner?.brandId, userAddress.latitude,userAddress.longitude))
    let storeSlug = response.stores[0].slug;
    let redirectUrl = (banner?.storeId !== baladiData.BALADI_STORE_Id) ?
    `/store/${storeSlug}${banner?.header_banner_locales[0].url.trim() !== '' ?
      `?s=${banner?.header_banner_locales[0].url}` : ''}` :
    `/category/${storeSlug}${banner?.header_banner_locales[0].url.trim() !== '' ?
      `?s=${banner?.header_banner_locales[0].url}` : ''}`
    navigate(redirectUrl)
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    let scategory = businessTypes.find(btype => btype?.slug === type);
    let url = (search !== '') ?
      `/storefront/${scategory?.slug}?s=${search}` : `/storefront/${scategory?.slug}`
    if (scategory?.slug === 'grocery') {
      url = (search !== '') ?
        `/category/${scategory?.slug}?s=${search}` : `/category/${scategory?.slug}`
    }
    //ampli====
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    ampli.track({
      event_type: 'SearchMade',
      event_properties: {
        category: 'Docs',
        name: 'SDK Library',
        description: 'SDK Library Description',
        user_id: userID ? userID : 0,
        business_type_slug: businessTypes,
        search: search,
      },
    })
    navigate(url)
  }

  const getAllBusinessTypes = async () => {
    const response = await getRequest(API_ROUTES.COMMON.GET_BUSINESS_CATEGORIES);
    businessTypes = response.businesstypes.map((category) => {
      let item = category;
      let offers = category?.discounts_and_offer_relations?.map((offer) => offer.discounts_and_offer);
      offers = [...new Map(offers?.map(item => [item["id"], item])).values()]
      item.offers = offers;
      return item;
    });
    setBusinessTypes(businessTypes);
    setIsLoading(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    isLoading = true;
    setIsLoading(isLoading);
    getAllBusinessTypes();
    // getBannerData();
  }, []);

  useEffect(() => {
    getBannerData();
  }, [userAddress])


  var HomeSlider = {
    dots: false,
    arrow: true,
    fade: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    //ampli===
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    ampli.track({
      event_type: 'HomepageViewed',
      event_properties: {
        category: 'Docs',
        name: 'SDK Library',
        description: 'SDK Library Description',
        is_guest_user: true,
        userId: userID ? userID : 0,
      }
    })
  }, [])
  return (
    <div>
      <div className="home-wrapper">
        <section className="home-sec1">
          <Slider {...HomeSlider} className="home-slider-parent">
            {banners?.length > 0 ?
              banners.map((banner, index) => {
                let redirectUrl = '';
                let isBrand = false;
                if (banner?.productId !== 0) {
                  redirectUrl = `/product-detail/${banner?.store?.slug || banner?.storeId}/${banner?.product?.slug || banner?.productId}`
                } else if (banner?.categoryId !== 0) {
                  redirectUrl = (banner?.storeId !== baladiData.BALADI_STORE_Id) ?
                    `/store/${banner?.store?.slug || banner?.storeId}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}&cat=${banner?.categoryId}` : `?cat=${banner?.categoryId}`}` :
                    `/store/${banner?.store?.slug || banner?.storeId}/${banner?.category?.slug}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}&cat=${banner?.categoryId}` : ''}`
                } else if (banner?.storeId !== 0) {
                  redirectUrl = (banner?.storeId !== baladiData.BALADI_STORE_Id) ?
                    `/store/${banner?.store?.slug || banner?.storeId}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}` : ''}` :
                    `/category/${banner?.business_type?.slug}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}` : ''}`
                } else if (banner?.brandId !== 0) {
                  isBrand = true;
                } else {
                  redirectUrl = (banner?.businessId != 2) ?
                    `/storefront/${banner?.business_type?.slug || banner?.businessId}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}` : ''}` :
                    `/category/${banner?.business_type?.slug}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}` : ''}`
                }
                return (
                  isBrand ? 
                  <div aria-label="banner" key={`link-${index}`} >
                  < img
                    src={axiosConstant.IMAGE_BASE_URL(banner?.image)}
                    key={`banner-${index}`}
                    alt="banner" className="home-slider"
                    style={{ cursor: 'pointer' }}
                    onClick={() => { gotoBrandStore(banner); }}
                    onError={(e) => { e.target.onerror = null; e.target.src = homeSliderImage; }}
                  />
                </div>:
                <Link aria-label="banner" to={redirectUrl} key={`link-${index}`}>
                  < img
                    src={axiosConstant.IMAGE_BASE_URL(banner?.image)}
                    key={`banner-${index}`}
                    alt="banner" className="home-slider"
                    onError={(e) => { e.target.onerror = null; e.target.src = homeSliderImage; }}
                  />
                </Link>
                )
              }) : <img src={homeSliderImage} alt="banner" className="home-slider" />}
          </Slider>
          <div className="content-block">
            <div className="search-box">
              <form onSubmit={handleSearchSubmit} action="#" method="post">
                <Select
                  className="search-drop"
                  value={type}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{ disableScrollLock: false }}
                >
                  {businessTypes?.map((category, index) => (
                    <MenuItem key={`category-${index}`} value={category?.slug}>
                      {category?.business_type_locales[0].name}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  id="outlined-basic"
                  className="home-search-input"
                  variant="outlined"
                  value={search}
                  type={"search"}
                  onChange={(e) => {
                    search = e.target.value;
                    setSearch(search);

                  }}
                  placeholder={t("searchForProductsOrVendors")}
                  sx={{ width: "100%", backgroundColor: "#fff" }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  className="home-search-btn"
                >
                  <img src={searchWhite} className="home-search-icon" alt="search" />
                  <span>{t("search")}</span>
                </Button>
              </form>
            </div>
          </div>
        </section>
        <div className="container-body">
          <section className="home-sec2">
            <div className="">
              <h2 className="h2">{t("categories")}</h2>
              <p className="p1">{t("youCanEasilySearchForWhatCategoryOfItemYouWantToOrder")}</p>
            </div>
            {isLoading ? <CategoryTypeSkeleton skeletons={4} /> :
              <Slider
                centerMode={false}
                dots={false}
                infinite={false}
                arrows={true}
                speed={500}
                slidesToShow={4}
                slidesToScroll={1}
                responsive={[
                  { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3, }, },
                  { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 1, }, },
                ]}
              >
                {businessTypes?.map((category, index) => (
                  <div key={`category-slide-${index}`} className="card-block">
                    <div className="chips-row">
                      {category?.offers?.filter((offerF, indexF) => indexF < 3).map((offer, index) =>
                        <span key={`offer-${index}`} className="chips">{offer?.name}</span>
                      )}
                      {category?.offers?.length > 3 ? <span className="chips"> +{category?.offers?.length - 3} {t("More")}</span> : null}
                    </div>
                    <Link
                      aria-label="category-link"
                      onClick={() => {
                        setLocalStorage(LOCAL_STORAGE_KEYS.ACTIVE_LINK, category?.id);
                        dispatch(changeActiveLink());
                      }}
                      to={category?.slug !== "grocery" ? `/storefront/${category?.slug}` : `/category/${category?.slug}`}
                    >
                      <Image
                        src={category?.image === "" ? category1 : axiosConstant.IMAGE_BASE_URL(category?.image)}
                        className="card-img"
                        alt={category?.business_type_locales[0].name}
                      />
                    </Link>
                    <div className="card-content-box">
                      <h3 className="h3">{category?.business_type_locales[0].name}</h3>
                      <p className="p1">{category?.business_type_locales[0].description}</p>
                      <Link
                        aria-label="category-link"
                        onClick={() => {
                          setLocalStorage(LOCAL_STORAGE_KEYS.ACTIVE_LINK, category?.id);
                          dispatch(changeActiveLink());
                        }}
                        to={category?.slug !== "grocery" ? `/storefront/${category?.slug}` : `/category/${category?.slug}`}
                        className="explore"
                        style={{ textTransform: "capitalize" }}
                      >
                        {
                          category?.business_type_locales[0]?.redirect_description && category?.business_type_locales[0]?.redirect_description !== '' ?
                            category?.business_type_locales[0]?.redirect_description :
                            t("explore")
                        }
                      </Link>
                    </div>
                  </div>
                ))}
              </Slider>}
          </section>
          <section className="home-sec3">
            <Grid container columnSpacing={0}>
              <Grid item xs={12} sm={12} md={5} className="home-sec3-col4">
                <div className="">
                  <h2 className="h2">{t("whyShouldYouPartnerWithBaladi")}</h2>
                  <Boxed mb={"32px"}>
                    <a href={axiosConstant.VENDOR_URL} target="_blank" className="no-decoration white-color" >
                      <Button type="button" variant="contained" size="large" className="">{t("becomeABaladiPartner")}</Button>
                    </a>
                  </Boxed>
                  <p className="p1-sec3">{t("withBaladiYouCanReachMoreCustomersAndAchieveGrowth")}</p>
                  <Boxed display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                    <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                      <img src={revenueGreen} alt="revenue" />
                      <span className="p1">{t("additionalRevenue")} {t("stream")}</span>
                    </Boxed>
                    <Boxed
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      margin={"0px 10px"}
                    >
                      <img src={deliveryGreen} alt="delivery" />
                      <span className="p1">{t("deliveryDone")}</span>
                    </Boxed>
                  </Boxed>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={7} className="home-sec3-col8">
                <img src={sec3Img} className="sec3img" alt="section3" />
              </Grid>
            </Grid>
          </section>
          <section className="home-sec4">
            <Grid container columnSpacing={0} style={{ position: "relative", zIndex: "1" }}>
              <Grid item xs={12} sm={12} md={6}>
                <img src={sec4Img} className="sec4img" alt="section4" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} pt={"45px"}>
                <h2 className="h2">{t("makeOrdersWithOurApplication")}</h2>
                <p className="p1">{t("getItNowAndGetSpecialOffers")}</p>
                <Boxed
                  className="sec4-store-btns"
                  pl={"110px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <a className="stores-img" href="https://play.google.com/store/apps/details?id=baladi.customer.app.baladi_customer_app" target="_blank">
                    <img src={playStore} alt="playStore" />
                  </a>
                  <a className="stores-img" href="https://apps.apple.com/qa/app/baladiexpress/id1635029670" target="_blank">
                    <img src={appleStore} alt="appleStore" />
                  </a>
                </Boxed>
              </Grid>
            </Grid>
          </section>
        </div>
      </div>
      <Footer />

    </div>
  );
};

export default Home;
