/** @format */
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import { Boxed } from "../../../Styles-Elements/Box";
import "./CheckoutHeader.scss";
import loginWhiteIcon from "../../../assets/images/structure/user-white.svg";
import headerLogo from "../../../assets/images/logo/header-logo.svg";
import loginRedIcon from "../../../assets/images/structure/login-red-icon.svg";
import ProfileIcon from "../../../assets/images/structure/profile-icon.svg";
import CardIcon from "../../../assets/images/structure/card-icon.svg";
import LockIcon from "../../../assets/images/structure/lock-icon.svg";
import LogoutIcon from "../../../assets/images/structure/logout-icon.svg";
import { useTranslation } from "react-i18next";
import { setLocalStorage, getLocalStorage, LOCAL_STORAGE_KEYS } from "../../../Helpers/crypto/LocalStorage";
import { getRequest, postRequest } from "../../../Helpers/Axios/Services";
import { API_ROUTES } from "../../../Helpers/Axios/ApiRoutes";
import { changeSnackbar, currentSnackbar } from "../../../redux/reducers/snackbar";
import Login from "../../../DialogBox/Login";
import Forgot from "../../../DialogBox/Forgot";
import OTPVerificationForgot from "../../../DialogBox/OTPVerificationForgot";
import Logout from "../../../DialogBox/Logout";
import CheckCart from "../../../DialogBox/CheckCart";
import UpdatePassword from "../../../DialogBox/UpdatePassword";
import ChangePassword from "../../../DialogBox/ChangePassword";
import { changeActiveLink, currentActiveLink } from "../../../redux/reducers/activeLink";
import { changeCart, currentCartCount } from '../../../redux/reducers/cart';
import jwt_decode from "jwt-decode";
import { currentAddress } from '../../../redux/reducers/address';

const CheckoutHeader = () => {

  const userAddress = useSelector(currentAddress)
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentActive = useSelector(currentActiveLink)
  const cartCount = useSelector(currentCartCount)

  let [userToken, setUserToken] = useState(null);
  let [code, setCode] = useState("");
  let [userId, setuserId] = useState("");
  let [forgotUname, setForgotUname] = useState("");
  let [identity, setIdentity] = useState("");
  let [countryCode, setCountryCode] = useState("974");
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openCheckCart, setOpenCheckCart] = useState(false);
  let [activeType, setActiveType] = useState("cart");
  const [openForgot, setOpenForgot] = useState(false);
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
  const [openOTPVerification, setOpenOTPVerification] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);

  const getWishlistData = async () => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const { ack, data, discountDataOfAll } = await getRequest(API_ROUTES.WISHLIST.GET_WISHLIST_DATA);
      if (ack === 1) {
        const productOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'product');
        const categoryOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'category');
        const storeOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'store');
        const businessOffers = discountDataOfAll?.filter((item) => item?.apply_type === "business_type");
        const products = data.map((item) => {
          let offers = productOffers?.filter((offer) =>
            offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id).length > 0) ?
            productOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id)) :
            productOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId));
          if (offers?.length === 0) {
            offers = categoryOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId).length > 0) ?
              categoryOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId)) :
              categoryOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id));
          }

          if (offers?.length === 0) {
            offers = storeOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id).length > 0) ?
              storeOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id)) :
              storeOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId));
          }
          if (offers?.length === 0) {
            offers = businessOffers?.filter((offer) => offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId))
          }
          item.offers = offers;
          return item;
        });
        setLocalStorage(LOCAL_STORAGE_KEYS.WISHLIST, JSON.stringify(products))
        dispatch(changeWishlist());
      }
    }
  }

  const checkCartData = async () => {
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    const response = await postRequest(API_ROUTES.CART.CHECK_CART_DATA_ON_LOGIN, { deviceId: deviceId });
    const status = response.ack
    if (status === 0) {
      setOpenCheckCart(true);
    } else {
      getCartData();
    }
  }

  const refreshAuthToken = async () => {
    if (
      location.href.split('/').includes('checkout')
    ) {
      activeType = "checkout";
    } else if (location.href.split('/').includes('cart')) {
      activeType = "cart";
    }
    setActiveType(activeType);
  }

  const updateCartData = async (type) => {
    setOpenCheckCart(false);
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    const response = await postRequest(API_ROUTES.CART.MOVE_CART_DATA_ON_LOGIN, {
      deviceId: deviceId,
      action: type
    });
    const status = response.ack
    if (status === 0) {
      setOpenCheckCart(true);
    } else {
      getCartData();
    }
  }

  const getCartData = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`;
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId
    });
    setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
    dispatch(changeCart());
  }

  const getUserToken = async () => {
    const UserToken = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (UserToken) {
      userToken = UserToken;
      setUserToken(userToken);
    } else {
      userToken = null;
      setUserToken(userToken);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getUserToken();
    refreshAuthToken();
    setInterval(() => {
      refreshAuthToken();
      getUserToken();
    }, 500);
  }, []);

  return (
    <>
      <div className="header-checkout">
        <div className="container-body">
          <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Link to={"/"}>
              <img src={headerLogo} className="header-logo" alt="baladi-logo" />
            </Link>
            <div className="card-payment-tab">
              <div className="option active">
                <span className="count">1</span>
                {t("Cart")}
              </div>
              <div className={`option ${activeType === 'checkout' ? 'active' : ''}`}>
                <span className="count">2</span>
                {t("Payment")}
              </div>
            </div>
            <div className="right-wrap">
              {userToken ? (<>
                <img
                  src={loginRedIcon}
                  className="header-btn-icon"
                  alt="loginRedIcon"
                  onClick={handleClick}
                />
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  transformOrigin={{ horizontal: "left", vertical: "top" }}
                  anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                  disableScrollLock={true}
                >
                  <Link className="header-drop" to={"/my-profile"}>
                    <MenuItem className="header-drop">
                      <img src={ProfileIcon} alt=".." />
                      {t('headerMyProfile')}
                    </MenuItem>
                  </Link>
                  <Link className="header-drop" to={"/order-history"}>
                    <MenuItem className="header-drop">
                      <img src={CardIcon} alt=".." />
                      {t('headerMmyOrders')}
                    </MenuItem>
                  </Link>
                  <MenuItem
                    className="header-drop"
                    onClick={() => {
                      setOpenChangePassword(true);
                      handleClose();
                    }}
                  >
                    <img src={LockIcon} alt=".." />
                    {t('headerChangePassword')}
                  </MenuItem>
                  <MenuItem
                    className="header-drop"
                    onClick={() => {
                      setOpenLogout(true);
                      handleClose();
                    }}
                  >
                    <img src={LogoutIcon} alt=".." />
                    {t('headerLogout')}
                  </MenuItem>
                </Menu>
              </>
              ) : (

                <Button
                  id="headlogin"
                  onClick={() => setOpenLogin(true)}
                  variant="contained"
                  size="large"
                  className="header-login-btn"
                  style={{ width: "fit-content" }}
                >
                  <img src={loginWhiteIcon} className="" alt="loginWhiteIcon" />
                  <span className="mx-2">{t("headerLogin")}</span>
                </Button>
              )}
            </div>
          </Boxed>
        </div>
      </div>
      {openLogin ? <Login
        handleClose={() => setOpenLogin(false)}
        forgotDialog={(forgotidentity, country_code) => {
          identity = forgotidentity;
          setIdentity(identity);
          countryCode = country_code;
          setCountryCode(countryCode);
          setOpenLogin(false);
          setOpenForgot(true);
        }}
        loginSuccess={() => {
          setOpenLogin(false);
          getUserToken();
          checkCartData();
        }}
      /> : null}
      {openForgot ? <Forgot
        identity={identity}
        countryCode={countryCode}
        handleClose={() => {
          setOpenForgot(false);
          setIdentity("");
        }}
        openVerifyForgot={(res) => {
          forgotUname = res.uname;
          setForgotUname(forgotUname);
          countryCode = res.country_code;
          setCountryCode(countryCode);
          setOpenForgot(false);
          setOpenOTPVerification(true);
          setIdentity("");
        }}
      /> : null}
      {openOTPVerification ? <OTPVerificationForgot
        forgotUname={forgotUname}
        countryCode={countryCode}
        handleClose={() => setOpenOTPVerification(false)}
        openPasswordUpdate={(res) => {
          setCode(res.code);
          setuserId(res.user_id);
          setOpenOTPVerification(false);
          setOpenUpdatePassword(true);
        }}
      /> : null}
      {openUpdatePassword ? <UpdatePassword
        code={code}
        userId={userId}
        handleClose={() => setOpenUpdatePassword(false)}
        loginOpen={(response) => {
          setOpenUpdatePassword(false);
          setOpenLogin(true);
          dispatch(
            changeSnackbar({
              ...snackbar,
              isOpen: true,
              message: response.msg,
              state: "success",
            })
          );
        }}
      /> : null}
      {openLogout ? <Logout
        CloseDialog={() => {
          setOpenLogout(false);
        }}
        logOutSuccess={() => {
          setOpenLogout(false);
          getUserToken();
          getCartData();
          navigate('/');
        }}
      /> : null}
      {openChangePassword ? <ChangePassword
        handleClose={() => {
          setOpenChangePassword(false);
        }}
        successChange={() => {
          setOpenChangePassword(false);
        }}
      /> : null}

      {openCheckCart ? <CheckCart
        DiscardGuest={() => updateCartData('discard_guest')}
        DiscardUser={() => updateCartData('discard_user')}
      /> : null}
      <Outlet />
    </>
  );
};

export default CheckoutHeader;
