/**
 * @file Ratings.js
 * @description This component is used to show ratings.
 * @exports Ratings component
 * @param {number} rating - rating number
 * @should show rating stars based on rating number and half star if rating is float number
 */

import starYellow from "../../assets/images/structure/star-yellow.svg"
import starGrey from "../../assets/images/structure/star-gray.svg"
import starHalf from "../../assets/images/structure/half-star.svg"


const Ratings = ({ rating, withDiv = true }) => {
  const stars = []
  let allrating = rating > 0 ? rating : 5
  for (let i = 1; i <= 5; i++) {
    if (i <= allrating) {
      stars.push(<img key={`rat-${i}`} src={starYellow} className="star-icon" alt="star" />)
    } else {

      if ((i - 1) < allrating && i > allrating) {
        stars.push(<img key={`rat-${i}`} src={starHalf} className="star-icon" alt="star" />)
        continue
      } else {
        stars.push(<img key={`rat-${i}`} src={starGrey} className="star-icon" alt="star" />)
      }
    }
  }
  return withDiv ? <div>{stars}</div> : stars
}

export default Ratings