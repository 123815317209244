import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../Helpers/Axios/Services";
import { API_ROUTES } from "../../Helpers/Axios/ApiRoutes";
import { LOCAL_STORAGE_KEYS } from "../../Helpers/crypto/LocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { changeAddress } from "../../redux/reducers/address";
import { changeWishlist } from "../../redux/reducers/wishlist";
import { changeCart } from "../../redux/reducers/cart";
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import CircularProgress from '@mui/material/CircularProgress';

const DeleteAccount = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
	const dispatch = useDispatch();
	const snackbar = useSelector(currentSnackbar);

  const deleteAccount = async () => {
    await getRequest(API_ROUTES.AUTHENTICATION.DELETE_ACCOUNT);
    const lang = localStorage.getItem('i18nextLng')
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVE_LINK);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.DEFAULT_ADDRESS);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOTAL_ADDRESS);
    localStorage.setItem('i18nextLng', lang)
    dispatch(changeAddress());
    dispatch(changeWishlist());
    dispatch(changeCart());
    dispatch(
      changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: t('userAccountDeletedSuccessfully'),
        state: "success",
      })
    );
		navigate('/');
  };

	useEffect(() => {
		
		deleteAccount();
	}, []);
  
  return (
    <div className="thankyou-content">
      <CircularProgress size={100} />
      <h3 className="h3">{t('headerWaitWhileWeDeleteYourAccount')}</h3>
    </div>
  );
};

export default DeleteAccount;
