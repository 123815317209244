/**
 * @format
 * @file Login.js
 * @description Login Dialog Box
 * @exports Login - Dialog Box
 * @exports handleClose - Function to close the dialog box
 * @exports forgotDialog - Function to open forgot password dialog box
 * @exports loginSuccess - Function to open login success dialog box
 */

import { useState, forwardRef } from "react";
import { Link } from "react-router-dom";
import {
  Grid, Dialog, DialogContent, Button, TextField,
  InputLabel, InputAdornment, FormControl, IconButton, OutlinedInput
} from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import { Phone, Visibility, VisibilityOff } from "@mui/icons-material";
import "../pages/layout/header/header.scss";
import { Boxed } from "../Styles-Elements/Box";
import { postRequest, getRequest } from "../Helpers/Axios/Services";
import { API_ROUTES } from "../Helpers/Axios/ApiRoutes";
import { axiosConstant } from "../Helpers/Axios/Constants";
import { setLocalStorage, LOCAL_STORAGE_KEYS } from "../Helpers/crypto/LocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar, currentSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import { useTranslation } from "react-i18next";
import { changeAddress } from "../redux/reducers/address";
import { PhoneMask, otpMask } from "../global-modules/MaskInputs/MaskedInputs";
import { validateEmail } from "../Helpers/HelperFunctions";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ampli, DefaultConfiguration, EventWithOptionalProperties } from "../../src/ampli";

import moengage from '@moengage/web-sdk';
import jwtDecode from "jwt-decode";
const allowedCountries = ['qa', 'ae', 'sa', 'om', 'kw', 'bh', 'ye', 'eg', 'jo', 'tr', 'in'];

const Login = ({ handleClose, forgotDialog, loginSuccess }) => {
  const { t } = useTranslation();
  let [showPassword, setShowPassword] = useState(true);
  let [error, setError] = useState("");
  let [identity, setIdentity] = useState("");
  let [identityError, setIdentityError] = useState("");
  let [countryCode, setCountryCode] = useState("974");
  let [password, setPassword] = useState("");
  let [passwordError, setPasswordError] = useState("");
  let [otp, setOtp] = useState("");
  let [otpError, setOtpError] = useState("");
  let [timeLeft, setTimeLeft] = useState(0);
  const [loginVia, setLoginVia] = useState("password");
  const [isTimer, setIsTimer] = useState(true);
  const [isPhone, setPhone] = useState(false);
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);

  const handleLoginViaOTP = async () => {
    dispatch(changeLoader(true));
    const data = {
      uname: identity.slice(countryCode.length),
      country_code: countryCode,
      role: axiosConstant.ROLE,
    };
    const response = await postRequest(API_ROUTES.AUTHENTICATION.GetSigninOtp, data);
    dispatch(changeLoader(false));
    if (response.ack === 1) {
      setLoginVia("otp");
      timeLeft = 31;
      setTimeLeft(timeLeft);
      setIsTimer(true);
      updateTimer();
    } else {
      setIdentityError(response.msg);
    }
  };

  const getAddresses = async () => {
    const response = await getRequest(API_ROUTES.ADDRESS.GET_ADDRESS);
    if (response.ack === 1) {
      const defaultAddress = response.addresses.find((address) => address.default_address === true);
      setLocalStorage(LOCAL_STORAGE_KEYS.TOTAL_ADDRESS, response.addresses.length);
      setLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_ADDRESS, JSON.stringify(defaultAddress));
      dispatch(changeAddress());
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(changeLoader(true));
    let isValid = true;
    if (identity === "") {
      setIdentityError(t("emailOrMobileNoRequired"));
      setIdentityError(t("phoneInvalid"));
      isValid = false;
    }
    if (loginVia === "password") {
      if (password === "") {
        setPasswordError(t("passwordRequired"));
        isValid = false;
      }
    } else {
      if (otp === "") {
        setOtpError(t("enterOtpToContinue"));
        isValid = false;
      }
    }
    if (isValid) {
      let data = {
        uname: identity.slice(countryCode.length),
        country_code: countryCode,
        role: axiosConstant.ROLE,
        otp: otp
      };
      let url = API_ROUTES.AUTHENTICATION.SigninViaOtp;
      if (loginVia === "password") {
        data = {
          uname: identity.slice(countryCode.length),
          country_code: countryCode,
          role: axiosConstant.ROLE,
          password: password
        };
        url = API_ROUTES.AUTHENTICATION.SigninViaPassword;
      }
      const response = await postRequest(url, data);
      dispatch(changeLoader(false));
      if (response.ack === 1) {
        const decoded = await jwtDecode(response.token);
        moengage?.add_unique_user_id(decoded?.id);
        setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response.token);
        await getAddresses();
        dispatch(
          changeSnackbar({ ...snackbar, isOpen: true, message: response.msg, state: "success" })
        );
        loginSuccess();
      } else {
        if (response?.msg && response?.msg !== "") {
          setError(response?.msg);
        } else if (response?.errMsg) {
          response?.errMsg.forEach((element) => {
            for (const [key, value] of Object.entries(element)) {
              setError(value);
            }
          });
        }
      }
    } else {
      dispatch(changeLoader(false));
    }
  };

  const checkChangeIdentity = async (e) => {
    setIdentity(e.target.value);
    setIdentityError("");
    if (e.target.value.length > 3 && !isNaN(e.target.value)) {
      setPhone(true);
      document.getElementById("identity").focus();
    } else {
      setPhone(false);
      setTimeout(() => {
        document.getElementById("identity").focus();
      }, 100);
    }
  };

  const updateTimer = () => {
    if (timeLeft > 1) {
      timeLeft--;
      setTimeLeft(timeLeft);
      setTimeout(() => {
        updateTimer();
      }, 1000);
    } else {
      setIsTimer(false);
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} className="main-modal">
      <img src={modalClose} alt="close" onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h2 className="h3 text-center">{t("welcome")}</h2>
        <p className="p3 text-center">{t("pleaseEnterYourDetails")}</p>
        <form onSubmit={handleSubmit} action="#" method="post">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl variant="outlined">
                <PhoneInput
                  className="phone-input"
                  country={'qa'}
                  value={identity}
                  enableLongNumbers={true}
                  onlyCountries={allowedCountries}
                  onKeyDown={(e) => {
                    if ((identity.length - countryCode.length) >= 16 && e.keyCode !== 8) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(value, data) => {
                    setIdentity(value);
                    setIdentityError("");
                    setCountryCode(data?.dialCode);
                  }}
                />
                {/* <TextField
                  id="identity"
                  label={t("emailOrMobileNo") + "*"}
                  value={identity}
                  onChange={(e) => {
                    checkChangeIdentity(e);
                    setError("");
                  }}
                  InputProps={
                    isPhone
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              +{axiosConstant.COUNTRY_CODE}
                            </InputAdornment>
                          ),
                          inputComponent: PhoneMask,
                        }
                      : null
                  }
                /> */}
                <span className="errorspan">{identityError}</span>
              </FormControl>
            </Grid>
            {loginVia === "password" ? (
              <>
                {/* <Grid item xs={12}>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      {t("password") + "*"}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "password" : "text"}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError("");
                        setError("");
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label={t("password") + "*"}
                    />
                    <span className="errorspan">{passwordError}</span>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
                  {/* {identity !== "" ? (
                    <span className="login-otp" onClick={handleLoginViaOTP}>
                      {t("loginViaOTP")}
                    </span>
                  ) : (
                    <span className="login-otp-no-click">{t("loginViaOTP")}</span>
                  )} */}
                  <span className="forgot-password" onClick={() => forgotDialog(identity, countryCode)}>
                    {t("forgotPassword")}?
                  </span>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <FormControl variant="outlined">
                    <TextField
                      label={t("otp")}
                      placeholder={t("enterOTP")}
                      value={otp}
                      onChange={(e) => {
                        setOtp(e.target.value);
                        setOtpError("");
                        setError("");
                      }}
                      id="outlined-start-adornment"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {isTimer
                              ? timeLeft < 10
                                ? `00:0${timeLeft}`
                                : `00:${timeLeft}`
                              : null}
                          </InputAdornment>
                        ),
                        inputComponent: otpMask,
                      }}
                      mask="0000"
                    />
                    <span className="errorspan">{otpError}</span>
                  </FormControl>
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
                  {/* <span className="login-otp" onClick={() => setLoginVia("password")}>
                    {t("loginViaPassword")}
                  </span> */}
                  {!isTimer ? (
                    <Boxed display={"flex"} alignItems={"center"}>
                      <span className="dont-account">{t("didntReceivedOTP")}</span>
                      <span onClick={() => handleLoginViaOTP()} className="sign-up">
                        {t("resend")}
                      </span>
                    </Boxed>
                  ) : null}
                </Grid>
              </>
            )}
            <Grid item xs={12} textAlign="center" style={{ position: "relative" }}>
              <span className="errorspan login-btn-error">{error}</span>
              <Button
              className={identity !== "" ? "login-otp-no-click" : "login-otp"}
              type="submit"
              variant="contained"
              size="large"
              sx={{ width: 1, marginTop: "10px" }}
              onClick={() => {
                if (identity !== "" && identity.length > 5) {
                  if (loginVia === 'otp') {
                    ampli.track({
                      event_type: 'LoggedIn',
                      event_properties: {
                        category: 'Docs',
                        name: 'SDK Library',
                        description: 'SDK Library Description',
                        user_id: 0,
                        country_code: countryCode,
                        contact_number: identity,
                      },
                    });
                  } else {
                    handleLoginViaOTP();
                  }
                }
                // Optionally, add additional logic or leave empty if no other action needed on click
              }}
            >
             {loginVia === 'otp' ? t("logIn") : t("loginViaOTP")}  
            </Button>

            </Grid>
            <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <span className="dont-account">{t("dontHaveAnAccountYet")}</span>{" "}
              <Link to={"/signup"} className="no-decoration" onClick={handleClose}>
                <span className="sign-up">{t("signUp")}</span>
              </Link>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Login;
