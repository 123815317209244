/** @format */
import "./CardCheckout.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Grid, Divider, Button, TextField, Checkbox, InputLabel,
  Radio, RadioGroup, FormControlLabel, FormControl, MenuItem, Select
} from "@mui/material";
import { useTranslation } from "react-i18next";
import youSavedIcon from "../../assets/images/structure/you-saved-icon.webp";
import { Boxed } from "../../Styles-Elements/Box";
import AddressBox from "./AddressBox";
import StareDetailBox from "./StareDetailBox";
import Footer from "../layout/footer/Footer";
import jwt_decode from "jwt-decode";
import deliverNowIcon from "../../assets/images/structure/deliver-now.svg";
import scheduleIcon from "../../assets/images/structure/schedule.svg";
import RefreshCart from "../../DialogBox/RefreshCart";
import { getRequest, postRequest, EstimateDeliveryFee } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeCart, currentCart, currentOrderSummery, currentCartStore, currentCartCount, currentCartProductIds, currentCartUomIds } from '../../redux/reducers/cart';
import { changeLoader } from "../../redux/reducers/loader";
import { axiosConstant } from '../../Helpers/Axios/Constants';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import { setSessionStorage, getSessionStorage, SESSION_STORAGE_KEYS } from '../../Helpers/crypto/SessionStorage';
import { getHoursBetween15, convertTimeTo12Hour, getLastFourDigitsOfCardNumber, getHoursBetweenFrom15 } from '../../Helpers/HelperFunctions';
import { cardMask, cardExpiryMask, cardCvvMask } from '../../global-modules/MaskInputs/MaskedInputs';
import { displayPrice, DAY } from '../../Helpers/HelperFunctions';
import { currentAddress } from '../../redux/reducers/address';
import { Calendar } from "primereact/calendar";
import { ampli } from "../../ampli";
import { currentBaladiData } from "../../redux/reducers/baladiData";

const CheckoutPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const baladiData = useSelector(currentBaladiData);
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  let cartData = useSelector(currentCart);
  const cartCount = useSelector(currentCartCount)
  let cartSummery = useSelector(currentOrderSummery);
  let cartStore = useSelector(currentCartStore);
  let addressDefault = useSelector(currentAddress);
  let [userData, setUserData] = useState(null);
  let [laterTime, setLaterTime] = useState(null);
  let [timeError, setTimeError] = useState('');
  let [dateError, setDateError] = useState('');
  let [userToken, setUserToken] = useState(null);
  let [deliver, setDeliver] = useState('now');
  let [paymentMethod, setPaymentMethod] = useState('cash_on_delivery');
  let [workingHours, setWorkingHours] = useState([]);
  let [availableDays, setAvailableDays] = useState([]);
  let [availableHours, setAvailableHours] = useState([]);
  let [unavailableDates, setUnavailableDates] = useState([]);
  let [deliveryCharge, setDeliveryCharge] = useState(0);
  let [credit_card, setCreditCard] = useState('');
  let [credit_card_error, setCreditCardError] = useState('');
  let [expiry_date, setExpiryDate] = useState('');
  let [expiry_date_error, setExpiryDateError] = useState('');
  let [cvv, setCvv] = useState('');
  let [cvv_error, setCvvError] = useState('');
  let [cardHolderName, setCardHolderName] = useState('');
  let [cardHolderName_error, setCardHolderNameError] = useState('');
  let [wallet, setWallet] = useState(0);
  let [saveCard, setSaveCard] = useState(false);
  let [useWallet, setUseWallet] = useState(false);
  let [useFromWallet, setUseFromWallet] = useState(0);
  let [storeIsOnline, setStoreIsOnline] = useState(true);
  let [cardList, setCardList] = useState([]);
  let [openRefreshCart, setOpenRefreshCart] = useState(false);
  const [date, setDate] = useState(null);
  let cartProductIds = useSelector(currentCartProductIds);


  const dateTemplateObject = (date) => {
    const currentDate = `${date.year}-${date.month + 1}-${date.day}`;
    if (unavailableDates?.includes(currentDate) || !availableDays?.includes(DAY[new Date(currentDate).getDay()])) {
      return <strong style={{ textDecoration: "line-through", pointerEvents: 'none' }}>{date.day}</strong>;
    }
    return date.day;
  };

  const getCardList = async () => {
    const response = await getRequest(API_ROUTES.USER_CARDS.GET_USER_CARDS);
    if (response.ack === 1 && response?.body?.count > 0) {
      cardList = response?.body?.cardTokens;
      setCardList(cardList);
    } else {
      cardList = [];
      setCardList(cardList);
    }
  }

  const goToCheckOut = () => {
    navigate(`/cart`);
  }

  const getCartDate = async () => {
    getStoreDetails(cartData?.storeId);
    handleDeliveryCharge();
  }

  const getCartData = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url += `?latitude=${addressDefault?.latitude}&longitude=${addressDefault?.longitude}`;
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId
    });
    setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
    dispatch(changeCart());
    if (cartResponse?.itemCount === 0) { navigate(`/cart`); }
    getCartDate();
    //ampli==
    ampli.track({
      event_type: 'PaymentInitiated',
      event_properties: {
        category: 'Docs',
        name: 'SDK Library',
        description: 'SDK Library Description',
        user_id: userID,
        store_id: cartData?.storeId,
        cart_id: cartData.id,
        product_ids: cartData.cart_items.map(item => item.productId),
        total_amount: cartSummery.totalAmount,
      },
    })
  }

  const handleDeliveryCharge = async () => {
    const deliveryResponse = await getRequest(API_ROUTES.CART.GET_DELIVERY_CHARGES(cartStore.id));
    if (deliveryResponse?.ack === 1) {
      if (deliveryResponse?.delivery?.getFromTookan === 'yes') {
        getFairEstimate();
      } else {
        deliveryCharge = 0;
        if (deliveryResponse?.delivery?.minimum_order_value <= 0) {
          deliveryCharge = deliveryResponse?.delivery?.delivery_charges
        } else if (deliveryResponse?.delivery?.minimum_order_value > cartSummery?.totalAmount) {
          deliveryCharge = deliveryResponse?.delivery?.delivery_charges
        }
        setDeliveryCharge(deliveryCharge)
      }
    }
  }

  const getFairEstimate = async () => {
    deliveryCharge = 0
    if (cartStore?.market_place === 0 || cartStore?.market_place === '0') {
      const coordinateData = {
        store_latitude: cartStore?.latitude,
        store_longitude: cartStore?.longitude,
        user_latitude: addressDefault?.latitude,
        user_longitude: addressDefault?.longitude,
      }
      const deliveryFee = await EstimateDeliveryFee(coordinateData);
      deliveryCharge = deliveryFee?.data?.total_fields['2'];
    }
    setDeliveryCharge(deliveryCharge)
  }

  const handleDateChange = (newValue) => {
    const date = new Date(`${newValue.value}`);
    const day = axiosConstant.DAYS[date.getDay()];
    const today = new Date();
    const dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    if (availableDays?.includes(day) && !unavailableDates?.includes(dateString)) {
      setValue(newValue);
      setDate(newValue.value);
      setDateError('');
      availableHours = [];
      const dayHours = workingHours?.find(wh => wh.day === day);
      if ((today.toDateString() === date.toDateString())) {
        const hour = today.getHours();
        const minutes = today.getMinutes();
        let currentTime = `${hour}:${minutes}`;
        const nearest30Minutes = Math.ceil(minutes / 30) * 30;
        currentTime = (nearest30Minutes === 60) ? `${hour + 1}:00` : `${hour}:${nearest30Minutes}`;
        if (dayHours?.timejson && dayHours?.timejson?.length > 0) {
          dayHours?.timejson?.forEach(element => {
            const hours = getHoursBetweenFrom15(element?.starttime, element?.endtime, currentTime)
              .filter(hour => parseInt(hour.split(':').join('')) <= parseInt(element?.endtime.split(':').join('')));
            if (hours?.length > 0) { availableHours = [...availableHours, ...hours] }
          });
        } else {
          availableHours = getHoursBetweenFrom15(dayHours?.starttime, dayHours?.endtime, currentTime)
            .filter(hour => parseInt(hour.split(':').join('')) <= parseInt(dayHours?.endtime.split(':').join('')));
        }
      } else {
        if (dayHours?.timejson && dayHours?.timejson?.length > 0) {
          dayHours?.timejson?.forEach(element => {
            let hours = getHoursBetween15(element?.starttime, element?.endtime)
              .filter(hour => parseInt(hour.split(':').join('')) <= parseInt(element?.endtime.split(':').join('')));
            if (hours?.length > 0) { availableHours = [...availableHours, ...hours] }
          });
        } else {
          availableHours = getHoursBetween15(dayHours?.starttime, dayHours?.endtime)
            .filter(hour => parseInt(hour.split(':').join('')) <= parseInt(dayHours?.endtime.split(':').join('')));
        }
      }
      setAvailableHours(availableHours);
    } else {
      setValue(null);
      setDateError(t('cart_Thisdayisnotavailable'));
    }
  }

  const getStoreDetails = async (storeslug) => {
    dispatch(changeLoader(true));
    const response = await getRequest(API_ROUTES.STOREFRONT.GET_WORKING_HOURS(storeslug));
    const status = response?.ack;
    const data = response?.result;
    workingHours = [];
    if (status === 1) {
      workingHours = data?.filter(wh => wh.open === true);
      setWorkingHours(workingHours);
      availableDays = workingHours?.map(wh => wh.day);
      setAvailableDays(availableDays);
      const response2 = await getRequest(API_ROUTES.STOREFRONT.GET_STORE_HOLIDAYS(storeslug));
      const status2 = response2?.ack;
      unavailableDates = [];
      if (status2 === 1) {
        const data2 = response2?.result;
        unavailableDates = data2?.map(h => {
          const date = new Date(h.date)
          return date.getFullYear() + '-' + (date.getMonth()) + '-' + date.getDate();
        });
      }
      setUnavailableDates(unavailableDates);
      dispatch(changeLoader(false));
    } else {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
    }
  }

  const handleAddToWallet = async () => {
    setSessionStorage(SESSION_STORAGE_KEYS.RETURN_TO_CHECKOUT, true);
    navigate('/add-money');
  }

  const getDate = (givendate) => {
    const date = new Date(`${givendate}`);
    const day = (date.getDate() > 9) ? date.getDate() : `0${date.getDate()}`;
    const month = (date.getMonth() + 1) > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    return `${date.getFullYear()}-${month}-${day}`;
  }

  const getUserToken = async () => {
    const UserToken = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (UserToken) {
      userToken = UserToken;
      setUserToken(userToken);
      let decoded = jwt_decode(userToken);
      userData = decoded;
      setUserData(userData);
    } else {
      userToken = null;
      setUserToken(userToken);
    }
  };

  const createOrderData = async () => {
    return {
      addressId: addressDefault.id,
      deliver: deliver,
      delivery_date: value ? getDate(value.value) : '',
      delivery_time: value ? laterTime : '',
      storeId: cartData?.storeId,
      payment_method: paymentMethod,
      payment_status: 'pending',
      delivery_charges: deliveryCharge,
      use_wallet: useWallet ? 'yes' : 'no',
      device_type: 'web'
    };
  }

  const handlePlaceOrderApiCall = async (data) => {
    let placeOrderUrl = API_ROUTES.CART.PLACE_ORDER;
    placeOrderUrl += `?latitude=${addressDefault?.latitude}&longitude=${addressDefault?.longitude}`;
    const response = await postRequest(placeOrderUrl, data);
    const status = response?.ack;
    const order_id = response?.order_id;
    if (status === 1) {
      if (paymentMethod === 'cash_on_delivery' || paymentMethod === 'wallet') {
        dispatch(changeLoader(false));
        navigate(`/thankyou/${order_id}`)
      } else if (paymentMethod === 'creditcard') {
        const transaction_data = response?.transaction_data;
        const checkout_link = transaction_data?.checkout_link;
        dispatch(changeLoader(false));
        window.location.href = checkout_link;
      } else if (paymentMethod === 'naps') {
        const transaction_data = response?.transaction_data;
        const checkout_link = transaction_data?.checkout_link;
        dispatch(changeLoader(false));
        window.location.href = checkout_link;
      } else if (paymentMethod === 'mpgs') {
        const transaction_data = response?.transaction_data;
        const checkout_link = transaction_data?.checkout_link;
        dispatch(changeLoader(false));
        window.location.href = checkout_link;
      } else {
        dispatch(changeLoader(false));
        if (response?.transaction_data) {
          const transaction_data = response?.transaction_data;
          const checkout_link = transaction_data?.redirectUrl;
          dispatch(changeLoader(false));
          window.location.href = checkout_link;
        }
      }
    } else {
      dispatch(changeLoader(false));
      if (response?.errMsg?.length > 0) {
        response.errMsg.forEach(element => {
          for (const [key, value] of Object.entries(element)) {
            if (key === 'stock' || key === 'store') {
              dispatch(changeSnackbar({
                ...snackbar,
                isOpen: true,
                message: value,
                state: "error",
              }));
            } else {
              dispatch(changeSnackbar({
                ...snackbar,
                isOpen: true,
                message: value,
                state: "error",
              }));
            }
          }
        });
      } else {
        if (typeof response.msg === 'object') {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response?.msg?.failureMessage,
            state: "error",
          }));
        } else {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "error",
          }));
        }
      }
    }
  }

  const handlePlaceOrderCOD = async () => {
    const data = await createOrderData();
    handlePlaceOrderApiCall(data);
  };

  const handleWalletPayment = async (e) => {
    useWallet = e.target.checked;
    setUseWallet(useWallet);
    if (useWallet) {
      if (wallet !== 0) {
        if ((cartSummery?.totalAmount + deliveryCharge) > wallet) {
          useFromWallet = wallet;
          setUseFromWallet(useFromWallet);
        } else {
          useFromWallet = wallet - (wallet - (cartSummery?.totalAmount + deliveryCharge));
          setUseFromWallet(useFromWallet);
        }

      }
    } else {
      useFromWallet = 0;
      setUseFromWallet(useFromWallet);
      setPaymentMethod('cash_on_delivery');
    }
    if (displayPrice(cartSummery?.totalAmount + deliveryCharge) - displayPrice(useFromWallet) === 0) {
      setPaymentMethod('wallet');
    }
  }

  const handlePlaceOrderNaps = async () => {
    const data = await createOrderData();
    data.payment_sequence = 'oneoff';
    data.redirectUrl = `${window.location.origin}/redirect`;
    handlePlaceOrderApiCall(data);
  }

  const handlePlaceOrderMPGS = async () => {
    const data = await createOrderData();
    data.payment_sequence = 'oneoff';
    data.redirectUrl = `${window.location.origin}/redirect`;
    handlePlaceOrderApiCall(data);
  }

  const handlePlaceOrderSavedCard = async (card_id, card_number) => {
    const data = await createOrderData();
    data.cardToken = card_id;
    data.payment_method = 'creditcard';
    data.payment_sequence = 'recurring';
    data.redirectUrl = `${window.location.origin}/redirect`;
    data.card_number = card_number;
    handlePlaceOrderApiCall(data);
  }

  const handlePlaceOrderCreditcard = async () => {
    const data = await createOrderData();
    data.cardToken = getSessionStorage(SESSION_STORAGE_KEYS.CARD_TOKEN);
    data.payment_sequence = saveCard ? 'recurring' : 'oneoff';
    data.redirectUrl = `${window.location.origin}/redirect`;
    data.card_number = getSessionStorage(SESSION_STORAGE_KEYS.CARD_NUMBER);
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.CARD_TOKEN);
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.CARD_NUMBER);
    handlePlaceOrderApiCall(data);
  }

  const handlePlaceOrderCardToken = async () => {
    let validCard = true;
    if (credit_card === '') {
      validCard = false;
      setCreditCardError(t('cart_Cardnumberisrequired'));
    }
    if (expiry_date === '') {
      validCard = false;
      setExpiryDateError(t('cart_Expirydateisrequired'));
    }
    if (cvv === '') {
      validCard = false;
      setCvvError(t('cart_CVVisrequired'));
    }
    if (cardHolderName === '') {
      validCard = false;
      setCardHolderNameError(t('cart_Cardholdernameisrequired'));
    }
    if (validCard) {
      const cardExpiryMonth = expiry_date.split('/')[0];
      const cardExpiryYear = expiry_date.split('/')[1];
      const cardNumber = credit_card.split('-').join('');
      const cardData = {
        cardNumber: cardNumber,
        cardExpiryMonth: cardExpiryMonth,
        cardExpiryYear: cardExpiryYear,
        cardCVC: cvv,
        cardHolder: cardHolderName
      }
      const { ack, data, msg } = await postRequest(API_ROUTES.CART.GET_CARD_TOKEN, cardData);
      if (ack === 1) {
        if (data.cardToken) {
          setSessionStorage(SESSION_STORAGE_KEYS.CARD_TOKEN, data.cardToken);
          setSessionStorage(SESSION_STORAGE_KEYS.CARD_NUMBER, getLastFourDigitsOfCardNumber(cardNumber));
          handlePlaceOrderCreditcard();
        } else {
          let message = '';
          if (data.details) {
            message = data?.details;
          } else {
            message = data?.failure.failureMessage;
          }
          if (typeof msg === Object && msg?.failureMessage) {
            message = msg?.failureMessage
          }
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: message,
            state: "error",
          }));
          dispatch(changeLoader(false));
        }
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t('cart_invalid_card_details'),
          state: "error",
        }));
        dispatch(changeLoader(false));
      }
    } else {
      dispatch(changeLoader(false));
    }
  }

  const handlePlaceOrderCard = async () => {

    handlePlaceOrderCardToken();
  }

  const handlePlaceOrder = async () => {
    dispatch(changeLoader(true));
    let isValid = true;
    if (deliver === 'later') {
      if (!value) {
        isValid = false;
        setDateError(t('cart_dateisrequired'));
      }
      if (!laterTime) {
        isValid = false;
        setTimeError(t('cart_timeisrequired'));
      }
    }
    if (!isValid) {
      dispatch(changeLoader(false));
      return false;
    }
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url += `?latitude=${addressDefault?.latitude}&longitude=${addressDefault?.longitude}`;
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId
    });
    setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
    dispatch(changeCart());
   
    if (!cartResponse?.data) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: t('cart_cartIsEmpty'),
        state: "error",
      }));
      return false;
    }
    let stockAvailable = true;
    let unAvailableProducts = [];
    let unAvailableAddons = [];
    let changedAddons = [];
    let inactiveProducts = [];
    let minimumQuantity = [];
    let maximumQuantity = [];
    let unavailableStockQuantity = false
    let unavailableProds = [];
    let invalidCode = false;
    let isProductInactive = false;
    let unavailablesName = [];
    // let categoryProductUnavailable = [];
    if (cartResponse?.couponResult?.invalidCode) {
      invalidCode = true;
    }

    cartResponse?.data?.cart_items.forEach(element => {
      // if (element.category && element.category[0].status === 'inactive') {
      //   categoryProductUnavailable.push(element.product.products_locales[0].title);
      // }
      if (element?.product && element?.product?.status === 'active') {
        const selected_uom = element?.product?.uoms?.find(uom => uom.id === element?.uomId);
        stockAvailable = element?.product.brandId !== baladiData.BALADI_BRAND_Id ?
          selected_uom?.stock === 1 :
          element?.product?.stockQuantity > 0;
        if (!stockAvailable) {
          unAvailableProducts?.push(element?.product?.products_locales[0]?.title);
        }
        if (
          element?.product?.availability_start_time && element?.product?.availability_end_time &&
          element?.product?.availability_start_time !== "" && element?.product?.availability_end_time !== "") {
          let today = new Date();
          const hours = today.getHours() < 10 ? `0${today.getHours()}` : today.getHours();
          const minutes = today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();
          const time = (deliver === 'now') ? hours + ":" + minutes : laterTime;
          const available = parseInt(element?.product?.availability_start_time.split(':').join('')) <= parseInt(time.split(':').join('')) &&
            parseInt(element?.product?.availability_end_time.split(':').join('')) >= parseInt(time.split(':').join(''))
          if (!available) {
            unavailableProds?.push(element?.product?.products_locales[0]?.title);
          }
        }
        let addedQuantity = 0;
        const baseUom = element.product.uoms.find(uom => uom.isDefault === 1)
        cartResponse?.data?.cart_items.forEach(newItems => {
          if (element.productId === newItems.productId) {
            const uom = element.product.uoms.find(uom => uom.id === newItems.uomId)
            addedQuantity += newItems.quantity * uom.relationWithBase
          }
        });
        if (element?.product.brandId === baladiData.BALADI_BRAND_Id && (element?.product?.stockQuantity - addedQuantity < 0)) {
          unavailableStockQuantity = true;
        }
        // if (element?.quantity > element?.product?.stockQuantity) {
        //   unavailableStockQuantity = true
        // }
        let selectedAddons = [];
        if (element?.addonIds && element?.addonIds !== "") {
          if (selected_uom?.addons?.length > 0) {
            selectedAddons = element?.addonIds?.split(',');
            selected_uom?.addons.forEach(addon => {
              if (addon?.addon_items?.length > 0) {
                addon?.addon_items?.forEach(addon_item => {
                  if (selectedAddons?.includes(addon_item?.id.toString())) {
                    const removeIndex = selectedAddons.indexOf(addon_item?.id.toString());
                    selectedAddons.splice(removeIndex, 1);
                  }
                });
              }
            });
            unAvailableAddons = selectedAddons;
          } else {
            unAvailableAddons = element?.addonIds?.split(',');
          }
        }
        const hasStore = element?.product?.manage_store_products?.filter(store => store?.storeId === cartResponse?.data?.storeId);
        if (hasStore?.length > 0) {
          isProductInactive = true;
          unavailablesName.push(element?.product.products_locales[0].title);
        }
        let getUniqueAddonId = element?.addonitems?.map(addon => addon.addonId).filter((value, index, self) => self.indexOf(value) === index);
        selected_uom?.addons?.map(addon => {
          if (getUniqueAddonId?.includes(addon.id)) {
            const selectedAddons = element?.addonitems?.filter(addonItem => addonItem.addonId === addon.id);
            if (addon?.minimum_item > selectedAddons.length) {
              minimumQuantity.push(addon?.addons_locales[0]?.name)
            }
            if (addon?.maximum_item !== 0 && addon?.maximum_item < selectedAddons.length) {
              maximumQuantity.push(addon?.addons_locales[0]?.name)
            }
          }
        });
        let addOns = element?.addonitems;
        if (addOns?.length > 0) {
          addOns?.map(addon => {
            const oldAddon = element?.itemLogs?.addonsItemData.find(addonItem => addonItem.id === addon.id);
            addon.change = oldAddon?.price !== addon.price;
            addon.oldPrice = oldAddon?.price;
            return addon;
          });
          const changedItems = addOns?.filter(addon => addon.change);
          if (changedItems?.length > 0) {
            changedAddons = [...changedAddons, ...changedItems];
          }
          const logUom = element?.itemLogs?.uomdata;
          if (selected_uom?.price !== logUom?.price || selected_uom.salePrice !== logUom.salePrice) {
            changedAddons?.push({
              ProductId: logUom?.productId, addon_items_locales: logUom?.uomName, change: true, id: logUom?.id,
              oldPrice: logUom?.price, price: logUom?.price, oldSalePrice: logUom?.salePrice, salePrice: logUom?.salePrice,
            });
          }
        }
      } else {
        inactiveProducts.push(element?.product ? element.product.products_locales[0].title : '');
      }
    });
    // return false
    if (
      stockAvailable &&
      unAvailableAddons?.length === 0 &&
      changedAddons?.length === 0 &&
      inactiveProducts?.length === 0 &&
      minimumQuantity?.length === 0 &&
      maximumQuantity?.length === 0 &&
      !unavailableStockQuantity &&
      unavailableProds?.length === 0 &&
      // categoryProductUnavailable.length === 0 &&
      !invalidCode &&
      !isProductInactive
    ) {
      
      if (deliver === 'later') {
        if (!value) {
          isValid = false;
          setDateError(t('cart_dateisrequired'));
        }
        if (!laterTime) {
          isValid = false;
          setTimeError(t('cart_timeisrequired'));
        }
      } else {
        let url = API_ROUTES.STOREFRONT.GET_STORE_DETAILS(cartData?.storeId)
        url += `?latitude=${addressDefault?.latitude}&longitude=${addressDefault?.longitude}`;
        const { ack, store } = await getRequest(url);
        if (ack === 1) {
          if (store.online_status === 0) {
            dispatch(changeSnackbar({
              ...snackbar,
              isOpen: true,
              message: t('cart_StoreisOffline'),
              state: "error",
            }));
            dispatch(changeLoader(false));
            return;
          }
        } else {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: t('cart_Storeisnotavailable'),
            state: "error",
          }));
          dispatch(changeLoader(false));
          return;
        }
      }
      if (isValid) {
        switch (paymentMethod) {
          case 'creditcard':
            handlePlaceOrderCard();
            break;
          case 'cash_on_delivery':
            handlePlaceOrderCOD();
            break;
          case 'naps':
            handlePlaceOrderNaps();
            break;
          case 'wallet':
            handlePlaceOrderCOD();
            break;
          case 'mpgs':
            handlePlaceOrderMPGS();
          default:
            let selectedCard = cardList.find(card => card.id === paymentMethod);
            if (selectedCard) {
              handlePlaceOrderSavedCard(selectedCard?.id, selectedCard?.cardLast4);
            } else {
              dispatch(changeSnackbar({
                ...snackbar,
                isOpen: true,
                message: t('cart_PaymentmethodisInvalid'),
                state: "error",
              }));
              dispatch(changeLoader(false));
            }
            break;
        }
      } else {
        dispatch(changeLoader(false));
      }
    } else {
      if (!stockAvailable) {
        let message = t('cart_Outofstock') + " " + unAvailableProducts.join(", ");
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: message,
          state: "error",
        }));
        dispatch(changeLoader(false));
      } else if(isProductInactive) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t('cart_ProductsNotAvailable') + " " + unavailablesName.join(", "),
          state: "error",
        }));
        dispatch(changeLoader(false));
      } else if (unAvailableAddons?.length > 0) {
        let unavailablesName = [];
        cartResponse?.data?.cart_items.forEach(element => {
          const unavailables = element?.addonitems?.filter(addon => {
            if (unAvailableAddons?.includes(addon.id.toString())) {
              return addon?.addon_items_locales[0]?.name;
            }
          })?.map(addon => addon?.addon_items_locales[0]?.name);
          unavailablesName = [...unavailablesName, ...unavailables];
        })

        let message = t('cart_AddonsNotAvailable') + " " + unavailablesName.join(", ");
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: message,
          state: "error",
        }));
        dispatch(changeLoader(false));
      } else if (changedAddons?.length > 0) {
        setOpenRefreshCart(true);
        dispatch(changeLoader(false));
      } else if (inactiveProducts?.length > 0) {
        let message = t('cart_ProductsNotAvailable') + " " + inactiveProducts.join(", ");
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: message,
          state: "error",
        }));
        dispatch(changeLoader(false));
      } else if (minimumQuantity?.length > 0 || maximumQuantity?.length > 0) {
        let message = '';
        if (minimumQuantity?.length > 0) {
          message = t('cart_MinimumQuantity') + " " + minimumQuantity.join(", ");
        }
        if (maximumQuantity?.length > 0) {
          message = message + " " + t('cart_MaximumQuantity') + " " + maximumQuantity.join(", ");
        }
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: message,
          state: "error",
        }));
        dispatch(changeLoader(false));
      } else if (unavailableStockQuantity) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t('cart_QuantityNotAvailable'),
          state: "error",
        }));
        dispatch(changeLoader(false));
        navigate('/cart');
      } else if (unavailableProds?.length > 0) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t('cart_productNotAvailable', {
            product_name: unavailableProds.join(', ')
          }),
          state: "error",
        }));
        dispatch(changeLoader(false));
        navigate('/cart');
      } else if (invalidCode) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t('cart_CouponCodeNotValid'),
          state: "error",
        }));
        dispatch(changeLoader(false));
        navigate('/cart');
      }
      // else if (categoryProductUnavailable.length > 0) {
      //   let message = t('cart_ProductsNotAvailable') + " " + categoryProductUnavailable.join(", ");
      //   dispatch(changeSnackbar({
      //     ...snackbar,
      //     isOpen: true,
      //     message: message,
      //     state: "error",
      //   }));
      //   dispatch(changeLoader(false));
      //   navigate('/cart');
      // }
    }
  }

  const getWalletAmount = async () => {
    const url = API_ROUTES.WALLET.GET_WALLET_AMOUNT(userData.id);
    const { ack, transactions } = await getRequest(url);
    if (ack === 1) {
      wallet = transactions?.amount;
      setWallet(wallet);
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: 'Error in getting wallet amount',
        state: "error",
      }));
    }
  }

  const getStore = async () => {
    let url = API_ROUTES.STOREFRONT.GET_STORE_DETAILS(cartData?.storeId)
    url += `?latitude=${addressDefault?.latitude}&longitude=${addressDefault?.longitude}`;
    const { ack, store } = await getRequest(url);
    if (ack === 1) {
      storeIsOnline = store.online_status === 1;
      setStoreIsOnline(storeIsOnline);
      if (storeIsOnline === false) {
        deliver = 'later';
        setDeliver(deliver);
      }
    }
  }

  useEffect(() => {
    if (userData) {
      getWalletAmount();
    }
  }, [userData])

  useEffect(() => {
    if (cartStore?.supportingCash !== 'yes') {
      paymentMethod = 'creditcard';
      setPaymentMethod(paymentMethod);
    }
  }, [])

  useEffect(() => {
    getCartData();
    getUserToken();
    getStore();
    getCardList();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, [])

  // useEffect(() => {
  //   ampli.track({
  //     event_type: 'PaymentInitiated',
  //     event_properties: {
  //       category: 'Docs',
  //       name: 'SDK Library',
  //       description: 'SDK Library Description',
  //       user_id:0,
  //       store_id:0,
  //       cart_id:0,
  //       product_ids:0,
  //       total_amount:0,        
  //     },
  //   })
  // }, [])

  return (
    <div>
      <div className="card-wrapper">
        <div className="container-body">
          <Grid container columnSpacing={"30px"}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <AddressBox />
              <div className="border-line-box">
                <h4 className="h4">{t("cart_DeliveryOptions")}</h4>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-Delivery-label"
                    name="Delivery"
                    value={deliver}
                    onChange={(e) => setDeliver(e.target.value)}
                  >
                    <div
                      className={`radio-label ${storeIsOnline ? '' : 'no-event'}`}
                      style={storeIsOnline?{}:{
                        pointerEvents: 'none',
                        opacity: '0.4'
                      }}  
                    >
                      <FormControlLabel value="now" control={<Radio />} />
                      <div className="custom-label">
                        <p>
                          <img src={deliverNowIcon} alt="plusIcon" />
                          {t("cart_DeliverNow")}
                        </p>
                        {/* <span>{t("cart_ifyouwanttodeliveryourorderinstantly")}</span> */}
                      </div>
                    </div>
                    <Divider />
                    <div className="radio-label">
                      <FormControlLabel value="later" control={<Radio />} />
                      <div className="custom-label">
                        <p>
                          <img src={scheduleIcon} alt="plusIcon" />
                          {t("cart_DeliverLater")}
                        </p>
                        {/* <span>{t("cart_Scheduleanorderfortodayorforanupcomingtime")}</span> */}
                      </div>
                    </div>
                    {deliver === "later" ? <Grid
                      container
                      xs={12} sm={12} md={12} lg={11} xl={11} spacing={"12px"}
                      className="after-checked-div"
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <span className="p-float-label">
                          <Calendar
                            className="prime-height58"
                            placeholder={t("cart_SelectDate")}
                            minDate={new Date()}
                            value={date}
                            onChange={(newValue) => { handleDateChange(newValue); }}
                            dateTemplate={dateTemplateObject}
                            id="CalenderID"
                          />
                          <label htmlFor="CalenderID">{t("cart_SelectDate")}</label>
                          <span className="errorspan">{dateError}</span>
                        </span>
                      </Grid>
                      {value ? <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControl fullWidth size="large">
                          <InputLabel id="demo-simple-select-label">
                            {t("cart_SelectTime")}
                          </InputLabel>
                          <Select
                            value={laterTime}
                            id="demo-select-small"
                            label={t("cart_SelectTime")}
                            onChange={(e) => {
                              setLaterTime(e.target.value);
                              setTimeError("");
                            }}
                          >
                            {availableHours?.map((hour, index) => (<MenuItem
                              key={`hour-${index}`} className="border-bottom" value={hour}
                            >{convertTimeTo12Hour(hour)}</MenuItem>
                            ))}
                          </Select>
                          <span className="errorspan">{timeError}</span>
                        </FormControl>
                      </Grid> : null}
                    </Grid> : null}
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="border-line-box">
                <h4 className="h4">{t("cart_PaymentOptions")}</h4>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    <div className="radio-label checkbox-type">
                      <FormControlLabel
                        className={wallet > 0 ? "" : "no-event"}
                        value="wallet"
                        control={
                          <Checkbox
                            checked={useWallet ? true : false}
                            onChange={handleWalletPayment}
                          />
                        }
                      />
                      <div className="wallet-row">
                        <div className="custom-label">
                          <p>{t("cart_Wallet")}</p>
                          <span>
                            {t("cart_AvailableBalance")} :
                            <b> {t("QAR")} {wallet ? displayPrice(wallet) : displayPrice(0)} </b>
                          </span>
                        </div>
                        <Button
                          type="button"
                          variant="contained"
                          size="medium"
                          onClick={handleAddToWallet}
                        >{t("cart_addToWallet")}</Button>
                      </div>
                    </div>
                    <Divider />
                    {cardList.length > 0 ? cardList?.map((card, index) => <span key={`card-${index}`}>
                      <div className={`radio-label ${paymentMethod === "wallet" ? "no-event disabled-for-wallet" : ""}`}>
                        <FormControlLabel value={card?.id} control={<Radio />} />
                        <div className="custom-label">
                          <p>{card?.cardHolder} ({card?.cardScheme})</p>
                          <span> **** **** **** {card?.cardLast4} </span>
                        </div>
                      </div>
                      <Divider />
                    </span>) : null}
                    <div className={`radio-label ${paymentMethod === "wallet" ? "no-event disabled-for-wallet" : ""}`}>
                      <FormControlLabel value="naps" control={<Radio />} />
                      <div className="custom-label">{t("cart_debitCard")}</div>
                    </div>

                    {/**
                    <Divider />
                    <div className={`radio-label ${paymentMethod === "wallet" ? "no-event disabled-for-wallet" : ""}`}>
                      <FormControlLabel value="mpgs" control={<Radio />} />
                      <div className="custom-label">MPGS (Testing only)</div>
                    </div>
                    **/}

                    <Divider />
                    <div className={`radio-label ${paymentMethod === "wallet" ? "no-event disabled-for-wallet" : ""}`}>
                      <FormControlLabel value="creditcard" control={<Radio />} />
                      <div className="custom-label">{t("cart_CreditCard")}</div>
                    </div>
                    {paymentMethod === "creditcard" ? <Grid
                      container
                      xs={12} sm={12} md={12} lg={11} xl={11} spacing={"12px"}
                      className="after-checked-div"
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group">
                          <TextField
                            label={t("cart_CardNumber") + "*"}
                            placeholder={t("cart_CardNumber") + "*"}
                            value={credit_card}
                            onChange={(e) => {
                              setCreditCard(e.target.value);
                              setCreditCardError("");
                            }}
                            InputProps={{ inputComponent: cardMask }}
                            mask="0000-0000-0000-0000"
                          />
                          <span className="errorspan">{credit_card_error}</span>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group">
                          <TextField
                            label={t("cart_CardHolderName") + "*"}
                            placeholder={t("cart_CardHolderName") + "*"}
                            value={cardHolderName}
                            onChange={(e) => {
                              setCardHolderName(e.target.value);
                              setCardHolderNameError("");
                            }}
                            inputProps={{ maxLength: 30 }}
                          />
                          <span className="errorspan">{cardHolderName_error}</span>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group">
                          <TextField
                            label={t("cart_ExpiryDate") + "*"}
                            placeholder={t("cart_ExpiryDate") + "*"}
                            value={expiry_date}
                            onChange={(e) => {
                              setExpiryDate(e.target.value);
                              setExpiryDateError("");
                            }}
                            InputProps={{ inputComponent: cardExpiryMask }}
                            mask="00/00"
                          />
                          <span className="errorspan">{expiry_date_error}</span>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group">
                          <TextField
                            label={t("cart_CVV") + "*"}
                            placeholder={t("cart_CVV") + "*"}
                            type={"password"}
                            value={cvv}
                            onChange={(e) => {
                              setCvv(e.target.value);
                              setCvvError("");
                            }}
                            InputProps={{ inputComponent: cardCvvMask }}
                          />
                          <span className="errorspan">{cvv_error}</span>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="medium"
                              checked={saveCard}
                              onChange={(e) => {
                                saveCard = e.target.checked;
                                setSaveCard(saveCard);
                              }}
                              name="saveCard"
                            />
                          }
                          label={t("cart_save_card")}
                        />
                      </Grid>
                    </Grid> : null}

                    {cartStore?.supportingCash === 'yes'?
                      <>
                      <Divider />
                      <div className={`radio-label ${paymentMethod === "wallet" ? "no-event disabled-for-wallet" : ""}`}>
                        <FormControlLabel value="cash_on_delivery" control={<Radio />} />
                        <div className="custom-label">{t("cart_CashonDelivery")}</div>
                      </div>
                    </>: null}
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="checkout-box">
                <h6 className="h6">{t("cart_OrderSummary")}</h6>
                <div className="d-flex">
                  <p className="p2">{t("cart_OrderTotal")}</p>
                  <p className="p2">{t("QAR")} {displayPrice(cartSummery?.subtotal)}</p>
                </div>
                {/* {cartSummery?.addonsTotal > 0?<div className="d-flex">
                    <p className="p2">{t("cart_addonsTotal")}</p>
                    <p className="p2 latter-spacing1">
                      {t("QAR")} {displayPrice(cartSummery?.addonsTotal)}{" "}
                    </p>
                  </div>: null} */}
                {cartSummery?.tax > 0 ?
                  <div className="d-flex ">
                    <p className="p2">{t("cart_Tax")}</p>
                    <p className="p2">{t("QAR")} {displayPrice(cartSummery?.tax)}</p>
                  </div> : null}
                {deliveryCharge !== 0 ?
                  <div className="d-flex mb-24">
                    <p className="p2">{t("cart_Deliverycharges")}</p>
                    <p className="p2">{t("QAR")} {displayPrice(deliveryCharge)}</p>
                  </div> : null}
                {cartSummery?.discount && cartSummery?.discount > 0 ? <div className="d-flex">
                  <p className="p2">{t("cart_CouponDiscount")}</p>
                  <p className="p2 latter-spacing1">
                    - {t("QAR")} {displayPrice(cartSummery?.discount)}
                  </p>
                </div> : null}
                {cartSummery?.discountedAmount && cartSummery?.discountedAmount > 0 ? <div className="d-flex">
                  <p className="p2">{t("cart_DiscountOffer")}</p>
                  <p className="p2 latter-spacing1">
                    - {t("QAR")} {displayPrice(cartSummery?.discountedAmount)}
                  </p>
                </div> : null}
                <Divider style={{ marginBottom: "24px" }} />
                <div className="d-flex mb-16">
                  <div className="relative"><p className="p2">{t("cart_TotalAmount")}</p></div>
                  <p className="p2 font20">{t("QAR")} {displayPrice(cartSummery?.totalAmount + deliveryCharge)}</p>
                </div>
                {useFromWallet !== 0 ? <div className="d-flex mb-16">
                  <p className="p2">{t("cart_wallet")}</p>
                  <p className="p2">
                    - {t("QAR")} {displayPrice(useFromWallet)}
                  </p>
                </div> : null}
                <div className="d-flex">
                  <div className="relative">
                    <p className="p2 bold">{t("cart_NetAmountToPay")}</p>
                  </div>
                  <p className="p2 bold font20">
                    {t("QAR")}{" "}
                    {useFromWallet === 0
                      ? displayPrice(cartSummery?.totalAmount + deliveryCharge)
                      : displayPrice(cartSummery?.totalAmount + deliveryCharge - useFromWallet)}
                  </p>
                </div>
                {/* {cartSummery?.discountedAmount > 0 ? <div className="bg-white-round-border mt-6">
                  <Boxed display={"flex"} alignItems={"start"}>
                    <div className="logo-white-box">
                      <img
                        src={youSavedIcon}
                        className="str-logo" alt="store-logo" />
                    </div>
                    <div className="relative" style={{ margin: '0px 12px' }}>
                      <p className="p2">{t("cart_Congratulations")}</p>
                      <p className="p3">{t("cart_CashbackOf")}<span className="bold">{" "} QAR {displayPrice(cartSummery?.discountedAmount)}</span> {t("cart_willBeCreditedToYourWalletOnceTheOrderIsSuccessfullyCompleted")}</p>
                    </div>
                  </Boxed>
                </div> : null} */}
              </div>
              {cartStore ? <StareDetailBox cartStore={cartStore} /> : null}
              <Grid item xs={12}>
                {paymentMethod === "cash_on_delivery" ? <Button
                  type="button"
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{ marginTop: "10px" }}
                  onClick={() => { handlePlaceOrder(); }}
                >
                  {`${t("cart_PlaceOrder")} (${t("QAR")} ${displayPrice(cartSummery?.totalAmount + deliveryCharge - useFromWallet)})`}
                </Button> : <Button
                  onClick={() => { handlePlaceOrder(); }}
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{ marginTop: "10px" }}
                >
                  {cartSummery?.totalAmount + deliveryCharge - useFromWallet > 0 ?
                    `${t("cart_PayNow")} (${t("QAR")} ${displayPrice(cartSummery?.totalAmount + deliveryCharge - useFromWallet)})` :
                    t("cart_PlaceOrder")}
                </Button>}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {openRefreshCart ? <RefreshCart
        userData={userData}
        closePopup={() => setOpenRefreshCart(false)}
        goToCheckOut={() => {
          setOpenRefreshCart(false);
          goToCheckOut();
        }}
      /> : null}
      <Footer />
    </div>
  );
};

export default CheckoutPage;
