import { useState, useEffect } from "react";
import { Dialog, DialogContent, Button } from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import { useTranslation } from "react-i18next";
import { changeCart, currentCartProductIds, currentCartUomIds, currentCart } from '../redux/reducers/cart';
import { useDispatch, useSelector } from "react-redux";
import { getDataBasedOnLanguage } from "../Helpers/HelperFunctions";

const ReapetLastUseCostumise = ({ handleClose, repeatLast, productName, openCustomization, cartId }) => {
  
  const { t } = useTranslation();
  let cartData = useSelector(currentCart);

  let [items, setItems] = useState('');

  useEffect(() => {
    const selectedItem = cartData?.cart_items?.find((item) => item.id === cartId);
    if (selectedItem?.addonitems !== '') {
      items = selectedItem?.addonitems?.map((item) => getDataBasedOnLanguage(item?.addon_items_locales)).join(", ");
      setItems(items);
    }
  }, [cartId, cartData]);

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        className="main-modal maxwidth-400"
      >
        <img
          src={modalClose}
          alt="close"
          onClick={handleClose}
          className="modal-close"
        />
        <DialogContent>
          <h4 className="h4" style={{ marginBottom: "24px" }}>
            {t("Repeat last used customization?")}
          </h4>
          <div className="relative">
            <h5 className="h5">{productName}</h5>
            <p className="p4">{items}</p>
          </div>
          <div className="footer-two-full-btn">
            <Button
              type="button"
              size="large"
              variant="outlined"
              color="primary"
              onClick={openCustomization}
            >
              {t("Add New")}
            </Button>
            <Button
              type="button"
              size="large"
              variant="contained"
              color="error"
              onClick={repeatLast}
            >
              {t("Repeat Last")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReapetLastUseCostumise;
