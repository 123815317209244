/**
 * @file wishlist.js
 * @description This file contains the wishlist store configuration
 */

import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';


const getWishlist = () => {
  if (getLocalStorage(LOCAL_STORAGE_KEYS.WISHLIST)) {
    return JSON.parse(getLocalStorage(LOCAL_STORAGE_KEYS.WISHLIST));
  } else {
    return [];
  }
}

const getWishlistData = () => {
  const wishlist = getWishlist();
  return wishlist;
}

const localWishProductIds = () => {
  let wishlist = getWishlist();
  return wishlist ? wishlist?.map(prod => {
    return { productId: prod?.product?.id, storeId: prod?.storeId}
  }) : [];
}

export const wishlist = createSlice({
  name: 'wishlist',
  initialState: {
    wishlistData: getWishlistData(),
    wishCount: getWishlistData().length,
    wishProductIds: localWishProductIds()
  },
  reducers: {
    changeWishlist: (state, action) => {
      state.wishlistData = getWishlistData()
      state.wishCount = getWishlistData().length
      state.wishProductIds = localWishProductIds()
    }
  },
})

export const { changeWishlist } = wishlist.actions;
export const currentWishlist = (state) => state.wishlist?.wishlistData
export const currentWishProductIds = (state) => state.wishlist?.wishProductIds
export const currentWishCount = (state) => state.wishlist?.wishCount

export default wishlist.reducer;
