/**
 * @file ApiRoutes.js
 * @description This file contains all the API routes
 * @param {JSON object} API_ROUTES - This is the main object which contains all the API routes
 * @param {JSON object} API_ROUTES.THIRD_PARTY_API - This is the object which contains all the third party API routes
 * @param {JSON object} API_ROUTES.COMMON - This is the object which contains all the common API routes
 * @param {JSON object} API_ROUTES.AUTHENTICATION - This is the object which contains all the authentication API routes
 * @param {JSON object} API_ROUTES.ADDRESS - This is the object which contains all the address API routes
 * @param {JSON object} API_ROUTES.STOREFRONT - This is the object which contains all the storefront API routes
 * @param {JSON object} API_ROUTES.CART - This is the object which contains all the cart API routes
 * @param {JSON object} API_ROUTES.ORDER - This is the object which contains all the order API routes
 * @param {JSON object} API_ROUTES.WISHLIST - This is the object which contains all the wishlist API routes
 * @param {JSON object} API_ROUTES.WALLET - This is the object which contains all the wallet API routes
 * @param {JSON object} API_ROUTES.STATIC_PAGES - This is the object which contains all the static page API routes
 */

export const API_ROUTES = {
  THIRD_PARTY_API: {
    GET_X_Y_FROM_DETAILS: (ZoneNumber, StreetNumber, BuildingNumber) => `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS1/MapServer/0/query?f=json&where=ZONE_NO=${ZoneNumber} and STREET_NO=${StreetNumber} and BUILDING_NO=${BuildingNumber}&returnGeometry=true&spatialRel=esriSpatialRelIntersects&outFields=ZONE_NO,STREET_NO,BUILDING_NO,QARS,ELECTRICITY_NO,WATER_NO,QTEL_ID`,
    GET_LAT_LNG_FROM_X_Y: (x, y) => `https://epsg.io/trans?x=${x}&y=${y}&s_srs=2932&t_srs=4326`
  },
  COMMON: {
    GET_BUSINESS_CATEGORIES: '/business-type/get-all-list',
    GET_BANNER: (limit, page) => `/site-setting/get-banners?sort_by=id&order_by=desc&type=web`,
    BALADI_DATA_GET: '/auth/get-baladi-info',
    GET_NEAREST_STORE_BY_BRAND: (brandId, lat, lng) => `/stores/get-store-slug?brand_id=${brandId}&latitude=${lat}&longitude=${lng}`,
  },
  AUTHENTICATION: {
    REFRESH_TOKEN: '/auth/refresh_token?platform=customer-web-abdul',
    GetSignUpOTP: '/auth/get-signup-otp',
    VerifySignUpOtp: '/auth/verify-signup-otp',
    SignUpUser: '/auth/signup-user',
    Setpassword: '/auth/set-password',
    changePassword: '/users/change-password',
    GetSigninOtp: '/auth/get-signin-otp',
    SigninViaOtp: '/auth/signin-otp',
    SigninViaPassword: '/auth/signin',
    ForgotPasswordSendOtp: '/auth/forgot-password',
    ForgotPasswordVerifyOtp: '/auth/verify-forgot-password',
    RefreshToken: '/auth/refresh_token',
    UPDATE_PROFILE: '/users/update-user-proofile',
    SEND_API_FOR_OLD_MOBILE: '/auth/send-otp-for-verify-old-mobile',
    SEND_API_FOR_NEW_MOBILE: '/auth/send-otp-for-verify-new-mobile',
    SEND_API_FOR_OLD_EMAIL: '/auth/send-otp-for-verify-old-email',
    SEND_API_FOR_NEW_EMAIL: '/auth/send-otp-for-verify-new-email',
    VERIFY_API_FOR_OLD_MOBILE: '/auth/verify-new-mobile-otp',
    VERIFY_API_FOR_EMAIL: '/auth/verify-new-email-otp',
    CHANGE_LANGUAGE: '/auth/change-language',
    DELETE_ACCOUNT: '/users/delete-account',
  },
  ADDRESS: {
    GET_ADDRESS: '/address/get-all-my-address',
    GET_SPECIFIC_ADDRESS: (id) => `/address/specific/${id}`,
    ADD_ADDRESS: '/address/add',
    EDIT_ADDRESS: (id) => `/address/edit/${id}`,
    REMOVE_ADDRESS: (id) => `/address/delete/${id}`,
    CHECK_ADDRESS: `/address/check-address-for-delivery`
  },
  STOREFRONT: {
    GET_STORES: (slug) => `/stores/all-new/${slug}`,
    GET_RECOMMENDED_STORES: (slug) => `/stores/get-recommended/${slug}`,
    GET_PRODUCTS_BY_STORE: (slug) => `/products/getbystore/${slug}`,
    GET_STORE_DETAILS: (slug) => `/stores/detail/${slug}`,
    // GET_PRODUCTS_BY_STORE_CATEGORY: (slug) => `/products/all/${slug}`,
    GET_PRODUCTS_BY_STORE_CATEGORY: (slug) => `/products/all-new/${slug}`,
    GET_PRODUCT_DETAILS: (slug, storeSlug) => `/products/detail/${storeSlug}/${slug}`,
    GET_WORKING_HOURS: (slug) => `/working-hour/get-working-hour/${slug}`,
    GET_STORE_HOLIDAYS: (slug) => `/working-hour/get-working-holiday/${slug}?sort_by=date&order_by=asc`,
    GET_STORE_REVIEWS: (slug) => `/stores/reviews/${slug}`,
    GET_STORE_CATEGORY_BY_PARENT: (categoryId, storeId) => `/category/get-categories-by-parent-cat/${categoryId}/${storeId}`,
  },
  CART: {
    GET_CART_DATA: '/cart/get-my-cart',
    ADD_CART_DATA: '/cart/add-to-cart',
    UPDATE_CART_DATA: (item_id) => `/cart/update-cart/${item_id}`,
    DELETE_CART_DATA: (item_id) => `/cart/delete-item/${item_id}`,
    CHECK_CART_DATA_ON_LOGIN: '/cart/check-cart-for-login-user',
    MOVE_CART_DATA_ON_LOGIN: '/cart/move-cart-item',
    EMPTY_CART: '/cart/empty-cart',
    PLACE_ORDER: '/cart/place-order',
    MOVE_CART_TO_WISHLIST: `/wishlist/cart-to-wishlist/`,
    GET_CARD_TOKEN: '/payment/create-card-token',
    CONFIRM_PAYMENT: '/cart/complete-order',
    UPDATE_ADDON_IN_CART: (item_id) => `/cart/update-addon-in-cart-item/${item_id}`,
    CART_REFRESH: '/cart/refresh-cart',
    UPDATE_SPECIAL_REQUEST: (noteId) => `/cart/update-special-note-in-cart-item/${noteId}`,
    APPLY_COUPON: '/cart/apply-coupon-on-cart',
    REMOVE_COUPON: '/cart/remove-coupon',
    GET_DELIVERY_CHARGES: (storeId) => `/cart/get-fixed-delivery-charges/${storeId}`,
    CANCEL_PAYMENT_ORDER: `/cart/reject-order-payment-failed`,
  },
  ORDER: {
    GET_ALL_ORDERS: '/orders/get-my-orders',
    GET_DETAILS: (order_id) => `/orders/get-detail/${order_id}`,
    CANCEL_ORDER: (order_id) => `/orders/cancle-order/${order_id}`,
    RATING_REVIEW: (orderId) => `/orders/rating-review/${orderId}`,
    GET_ORDER_INVOICE: (orderId) => `/orders/invoice/${orderId}`
  },
  WISHLIST: {
    GET_WISHLIST_DATA: '/wishlist/get-all',
    ADD_WISHLIST_DATA: (item_id, store_id) => `/wishlist/add/${item_id}/${store_id}`,
    DELETE_WISHLIST_DATA: (item_id, store_id) => `/wishlist/delete-item/${item_id}/${store_id}`,
    MOVE_WISHLIST_TO_CART: `/cart/wishlist-to-cart/`,
    GET_NEAREST_STORE: (storeId, lat, lng) => `/stores/get-store-slug?slug=${storeId}&latitude=${lat}&longitude=${lng}`,
  },
  WALLET: {
    GET_WALLET_AMOUNT: (userId) => `/wallet/get-user-wallet-amount/${userId}`,
    GET_WALLET_DATA: '/wallet/get-my-all-wallet-transaction',
    ADD_WALLET_AMOUNT: '/wallet/add-money',
    CHECK_PAYMENT_STATUS: '/wallet/check-payment-status',
  },
  STATIC_PAGES: {
    GET_STATIC_PAGES: (slug) => `/cms/get-one/${slug}?role=user`,
    GET_FAQS: '/faq/get-all?role=user',
    SUBMIT_CONTACT_US: '/auth/contactUs',
    GET_CONTACT_US: '/auth/adminContactDetail',
  },
  USER_CARDS: {
    GET_USER_CARDS: '/cart/get-saved-cards',
    DELETE_USER_CARD: `/cart/delete-saved-cards`,
  },
  ADDONS: {
    GET_ALL_ADDONS: (uomId, storeId) => `/products/get-addon-by-uom-id/${uomId}/${storeId}`,
  },
  GOOGLE_MAPS_API: {
    ADDRESS_AUTOCOMPLETE: (input) => `/map/autocomplete?input=${input}`,
    ADDRESS_FROM_PALCE_ID: (placeId) => `/map/place_api?placeId=${placeId}`,
    GET_LAT_LNG_FROM_ADDRESS: (lat, long) => `/map/fetch_address?lat=${lat}&long=${long}`
  }
}