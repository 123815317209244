import "./ManageCards.scss"
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import NoDataFound from '../../global-modules/EmptyPages/NoDataFound';
import {
  Button, Grid, TextField, Dialog, DialogContent, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import Sidebar from '../layout/sidebar/Sidebar'
import { Boxed } from "../../Styles-Elements/Box"
import deleteRed from "../../assets/images/structure/delete-red.svg"
import modalClose from "../../assets/images/structure/close-black.svg"
import { useTranslation } from "react-i18next";
import { getRequest, postRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";

const ManageCards = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const [openAddNewCard, setOpenAddNewCard] = useState(false);
  const [openRemoveCard, setOpenRemoveCard] = useState(false);
  let [cardList, setCardList] = useState([]);
  let [deleteData, setDeleteData] = useState(null);
  
  const OpenAddNewCard = () => {
    setOpenAddNewCard(true);
  };
  const OpenRemoveCard = (id) => {
    deleteData = id;
    setDeleteData(deleteData);
    setOpenRemoveCard(true);
  };
  const handleClose = () => {
    setOpenAddNewCard(false);
    setOpenRemoveCard(false);
  };

  const handleDeleteCard = async () => {
    setOpenRemoveCard(false);
    dispatch(changeLoader(true));
    const response = await postRequest(API_ROUTES.USER_CARDS.DELETE_USER_CARD, { cart_tokan_id: deleteData });
    if (response?.ack === 1){
      dispatch(changeLoader(false));
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: t('manage_card_Cardremovedsuccessfully'),
        state: "success",
      }));
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: t('manage_card_Cardremovalfailed'),
        state: "success",
      }));
    }
    getCardList()
  }

  const getCardList = async () => {
    dispatch(changeLoader(true));
    const response = await getRequest(API_ROUTES.USER_CARDS.GET_USER_CARDS);
    if (response.ack === 1 && response?.body?.count > 0) {
      cardList = response?.body?.cardTokens;
      setCardList(cardList);
      dispatch(changeLoader(false));
    } else {
      cardList = [];
      setCardList(cardList);
      dispatch(changeLoader(false));
    }
  }

  useEffect(() => {
    getCardList();
  }, []);

  return (
    <div>
      <div className="main-wrapper full-bg">
        <Sidebar />
        <div className="main-mid-wrapper">
          <div className="manage-card-wrapper">
            <Boxed
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={"20px"}
            >
              <div className="store-detail-head" style={{ marginBottom: "0px" }}>
                <h3 className="h3">{t("manageCards")}</h3>
              </div>
              {/* <Boxed display={"flex"} alignItems={"center"} className="">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: "150px" }}
                  onClick={OpenAddNewCard}
                >
                  {t("addNewCard")}
                </Button>
              </Boxed> */}
            </Boxed>

            {cardList?.length > 0?<div className="">
              <div className="tabs-wrap">
                <div className="table-block">
                  <div className="table-wrapper">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("cardNumber")}</TableCell>
                            <TableCell>{t("cardHolderName")}</TableCell>
                            <TableCell>{t("expiryDate")}</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {cardList?.length > 0?cardList.map((card, index) =>
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={`card-${index}`}>
                            <TableCell component="th" scope="row">
                              <Boxed>
                                <span className="p1">xxxx xxxx xxxx {card?.cardLast4}</span>
                              </Boxed>
                            </TableCell>
                            <TableCell> {card?.cardHolder}</TableCell>
                            <TableCell>{card?.expiry}</TableCell>
                            <TableCell>
                              <img
                                src={deleteRed}
                                style={{ margin: "0 12px", cursor: "pointer" }}
                                alt="deleteRed"
                                onClick={() => OpenRemoveCard(card?.id)}
                              />
                            </TableCell>
                          </TableRow>):null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>: <NoDataFound text={'No cards added yet'} />}
          </div>
        </div>
      </div>
      <Dialog open={openAddNewCard} onClose={handleClose} className="main-modal">
        <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
        <DialogContent>
          <h2 className="h3" style={{ marginBottom: "16px" }}>
            {t("addNewCardDetails")}
          </h2>
          {/* <h2 className='h3' style={{marginBottom: '16px'}}>{t('editCardDetails')}</h2> */}
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField required label={t("cardNumber")} placeholder={"xxxx xxxx xxxx 1301 "} />
              </Grid>
              <Grid item xs={6}>
                <TextField required label={t("cardHolderName")} />
              </Grid>
              <Grid item xs={6}>
                <TextField required label={t("expiryDate")} placeholder={"MM/YY"} />
              </Grid>
              <Grid item xs={6}>
                <TextField required label={t("cvv")} />
              </Grid>
              <Grid item xs={12} textAlign="right">
                <Button variant="contained" size="large" sx={{ width: "150px" }}>
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={openRemoveCard} onClose={handleClose} className="main-modal theme-dialog">
        <DialogContent>
          <h2 className="h3" style={{ marginBottom: "10px" }}>
            {t("manage_card_RemoveCard")}
          </h2>
          <p className="p3" style={{ marginBottom: "12px", fontSize: "14px" }}>
            {t("manage_card_Areyousureyouwanttoremovethiscard")}
          </p>
          <Grid item xs={12} textAlign="center" display={"flex"} justifyContent={"flex-end"}>
            <Button
              type="button"
              size="medium"
              variant="text"
              color="primary"
              onClick={handleDeleteCard}
            >
              {t("manage_card_Yes")}
            </Button>
            <Button type="button" size="medium" variant="text" color="error" onClick={handleClose}>
              {t("manage_card_No")}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ManageCards;
