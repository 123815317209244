import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
// @mui
import { Menu, Badge, MenuItem, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
// images
import headerLogo from "../../../assets/images/logo/header-logo.svg";
import wishlistBlack from "../../../assets/images/structure/wishlist-black.svg";
import bagBlack from "../../../assets/images/structure/bag-black.svg";
import loginRedIcon from "../../../assets/images/structure/login-red-icon.svg";
import loginWhiteIcon from "../../../assets/images/structure/user-white.svg";
import ProfileIcon from "../../../assets/images/structure/profile-icon.svg";
import CardIcon from "../../../assets/images/structure/card-icon.svg";
import CrossIcon from "../../../assets/images/structure/cancel-red.svg";
import LockIcon from "../../../assets/images/structure/lock-icon.svg";
import LogoutIcon from "../../../assets/images/structure/logout-icon.svg";
import locationBlack from "../../../assets/images/structure/location-black.svg";
import "./header.scss";
import { setLocalStorage, getLocalStorage, LOCAL_STORAGE_KEYS } from "../../../Helpers/crypto/LocalStorage";
import { getRequest, postRequest } from "../../../Helpers/Axios/Services";
import { API_ROUTES } from "../../../Helpers/Axios/ApiRoutes";
import { changeSnackbar, currentSnackbar } from "../../../redux/reducers/snackbar";
import TopToolbar from "./top-toolbar/TopToolbar";
import { Boxed } from "../../../Styles-Elements/Box";
import Login from "../../../DialogBox/Login";
import Forgot from "../../../DialogBox/Forgot";
import OTPVerificationForgot from "../../../DialogBox/OTPVerificationForgot";
import Logout from "../../../DialogBox/Logout";
import CheckCart from "../../../DialogBox/CheckCart";
import UpdatePassword from "../../../DialogBox/UpdatePassword";
import ChangePassword from "../../../DialogBox/ChangePassword";
import AddressList from '../../../DialogBox/Address/AddressList';
import AddAddress from '../../../DialogBox/Address/AddAddress';
import ConfirmDialog from "../../../DialogBox/ConfirmDialog";
import { changeActiveLink, currentActiveLink } from "../../../redux/reducers/activeLink";
import { changeCart, currentCartCount } from '../../../redux/reducers/cart';
import { currentWishCount, changeWishlist } from '../../../redux/reducers/wishlist';
import { sortCategories, } from '../../../Helpers/HelperFunctions';
import jwt_decode from "jwt-decode";
import { currentAddress } from '../../../redux/reducers/address';
import { ampli } from "../../../ampli";
import Meta from "./Meta";

const Header = () => {
  useEffect(() => {
    // Add GTM script to the head
    const script = document.createElement("script");
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-K8FL99LQ');`;
    document.head.appendChild(script);

  //  let metaTags = [
  //     { property: "og:title", content: "Premium Grocery Delivery" },
  //     { property: "og:description", content: "Shop at BaladiExpress, Qatar's premier online platform. Enjoy 24/7 delivery of fresh groceries from the comfort of your home. Trusted since 1979." },
  //     { property: "og:title", content: "Shop Fresh Groceries Online - BaladiExpress" },
  //     { property: "og:description", content: "BaladiExpress offers convenient online shopping with 24/7 delivery across Qatar. Fresh groceries delivered in premium condition with the lowest delivery fees." },
  //     { property: "og:title", content: "Your Source for Fresh Ingredients - BaladiExpress" },
  //     { property: "og:description", content: "Experience top-quality grocery delivery with BaladiExpress. Qatar's trusted online shopping platform with 24/7 delivery." },
  //     { property: "og:description", content: "BaladiExpress is your go-to online store for fresh, quality ingredients delivered to your door. Lowest fees, 100% Qatari app." },{ property: "og:url", content: "https://baladiexpress.com/" },
  //  ];
    
//   let metaTags = [
//     // { property: "og:url", content: "https://baladiexpress.com/" },
//     { property: "og:type", content: "website" },
//     // { property: "og:title", content: "Baladi Express" },
//     { property: "og:description", content: "BaladiExpress offers convenient online shopping with 24/7 delivery across Qatar. Fresh groceries delivered in premium condition with the lowest delivery fees." },
//     // { property: "og:image", content: "https://baladiexpress.com/static/media/header-logo.b1ba458a0017c9ed63ffe5d32288c62b.svg" }
// ];

      // Meta tags
  // const metaTags2 = [
  //   {  title: "Premium Grocery Delivery", description: "Shop at BaladiExpress, Qatar's premier online platform. Enjoy 24/7 delivery of fresh groceries from the comfort of your home. Trusted since 1979." },
  //   {  title: "Shop Fresh Groceries Online - BaladiExpress", description: "BaladiExpress offers convenient online shopping with 24/7 delivery across Qatar. Fresh groceries delivered in premium condition with the lowest delivery fees." },
  //   {   title: "Your Source for Fresh Ingredients - BaladiExpress", description: "Experience top-quality grocery delivery with BaladiExpress. Qatar's trusted online shopping platform with 24/7 delivery." },
  //   {   title: "BaladiExpress - Grocery Shopping Made Easy", description: "BaladiExpress brings the best of Souq Al Baladi to your doorstep. Enjoy hassle-free online shopping with 24/7 delivery across Qatar." },
     
  // ];
  //   const metaTags3 = [
  //     { name: "google-site-verification", content:"WM10Yt6kuDw7JaM9gca91hQh5EAzSZz3eABOWOOeKy0" }
  //   ]
  // Append meta tags to the head
  // metaTags.forEach(tag => {
  //   const metaTag = document.createElement("meta");
  //   // metaTag.setAttribute("name", tag.name);
  //   metaTag.setAttribute("property", tag.property);
  //   metaTag.setAttribute("content", tag.content);
  //   document.head.appendChild(metaTag);
  // });
  // metaTags2.forEach(tag => {
  //   const metaTag = document.createElement("meta");
   
  //   metaTag.setAttribute("name", tag.title);
  //   metaTag.setAttribute("content", tag.description);
  //   document.head.appendChild(metaTag);
  // });
    
  // metaTags3.forEach(tag => {
  //   const metaTag = document.createElement("meta");
   
  //   metaTag.setAttribute("name", tag.name);
  //   metaTag.setAttribute("content", tag.content);
  //   document.head.appendChild(metaTag);
  // });
  // Cleanup function to remove script and meta tags when component unmounts
  return () => {
    document.head.removeChild(script);
    // metaTags.forEach(tag => {
    //   const existingTag = document.querySelector(`meta[property="${tag.property}"][content="${tag.content}"]`);
    //   if (existingTag) {
    //     document.head.removeChild(existingTag);
    //   }
    // });
    // metaTags2.forEach(tag => {
    //   const existingTag = document.querySelector(`meta[content="${tag.description}"][name="${tag.title}"]`);
    //   if (existingTag) {
    //     document.head.removeChild(existingTag);
    //   }
    // });
    // metaTags3.forEach(tag => {
    //   const existingTag = document.querySelector(`meta[content="${tag.content}"][name="${tag.name}"]`);
    //   if (existingTag) {
    //     document.head.removeChild(existingTag);
    //   }
    // });
  };
    // Cleanup function to remove script when component unmounts
    // return () => {
    //   document.head.removeChild(script);
    // };
  }, []);
  const userAddress = useSelector(currentAddress)
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentActive = useSelector(currentActiveLink)
  const cartCount = useSelector(currentCartCount)
  const wishCount = useSelector(currentWishCount)
  let [userToken, setUserToken] = useState(null);
  let [code, setCode] = useState("");
  let [userId, setuserId] = useState("");
  let [forgotUname, setForgotUname] = useState("");
  let [businessTypes, setBusinessTypes] = useState([]);
  let [identity, setIdentity] = useState("");
  let [countryCode, setCountryCode] = useState("974");
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openCheckCart, setOpenCheckCart] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
  const [openOTPVerification, setOpenOTPVerification] = useState(false);
  const [openAddressList, setOpenAddressList] = useState(false);
  const [openAddressAdd, setOpenAddressAdd] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);

  const getCartData = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url = url + `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`;
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId
    });
    setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
    dispatch(changeCart());
  }

  const getUserToken = async () => {
    const UserToken = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (UserToken) {
      userToken = UserToken;
      setUserToken(userToken);
    } else {
      userToken = null;
      setUserToken(userToken);
    }
  };

  const updateCartData = async (type) => {
    setOpenCheckCart(false);
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    const response = await postRequest(API_ROUTES.CART.MOVE_CART_DATA_ON_LOGIN, {
      deviceId: deviceId,
      action: type
    });
    const status = response.ack
    if (status === 0) {
      setOpenCheckCart(true);
    } else {
      getCartData();
    }
  }

  const getWishlistData = async () => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const { ack, data, discountDataOfAll } = await getRequest(API_ROUTES.WISHLIST.GET_WISHLIST_DATA);
      if (ack === 1) {
        const productOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'product');
        const categoryOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'category');
        const storeOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'store');
        const businessOffers = discountDataOfAll?.filter((item) => item?.apply_type === "business_type");
        const products = data.map((item) => {
          let offers = productOffers?.filter((offer) =>
            offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id).length > 0) ?
            productOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id)) :
            productOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId));
          if (offers?.length === 0) {
            offers = categoryOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId).length > 0) ?
              categoryOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId)) :
              categoryOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id));
          }

          if (offers?.length === 0) {
            offers = storeOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id).length > 0) ?
              storeOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id)) :
              storeOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId));
          }
          if (offers?.length === 0) {
            offers = businessOffers?.filter((offer) => offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId))
          }
          item.offers = offers;
          return item;
        });
        setLocalStorage(LOCAL_STORAGE_KEYS.WISHLIST, JSON.stringify(products))
        dispatch(changeWishlist());
      }
    }
  }

  const checkCartData = async () => {
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    const response = await postRequest(API_ROUTES.CART.CHECK_CART_DATA_ON_LOGIN, { deviceId: deviceId });
    const status = response.ack
    if (status === 0) {
      setOpenCheckCart(true);
    } else {
      getCartData();
    }
  }

  const getAllBusinessTypes = async () => {
    const response = await getRequest(API_ROUTES.COMMON.GET_BUSINESS_CATEGORIES);
    let data = response.businesstypes;
    let status = response.ack
    businessTypes = status === 1 ? data : [];
    setBusinessTypes(businessTypes);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getUserToken();
    getAllBusinessTypes();
    getWishlistData();
    setInterval(() => {
      getUserToken();
    }, 500);
    setInterval(() => {
      if (sessionStorage.getItem('signupSuccess')) {
        checkCartData();
        sessionStorage.removeItem('signupSuccess');
      }
    }, 1000);
  }, []);


  const [click, setClick] = React.useState(false);
  const handleClickMenu = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [metaData, setMetaData] = useState({
    title: 'Baladi Express',
    description: 'Default Description',
    url: 'https://baladiexpress.com',
    image: 'http://baladiexpress.com/Balad_logo.png'
  });
 
 
  return (
    <>
      <div className="header-wrapper">
    
      <noscript> 
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K8FL99LQ"
          height="0" width="0" style={{ display: "none", visibility: "hidden" }}>
        </iframe> 
        </noscript>
         
        <TopToolbar userToken={userToken} />
        <div className="container-body">
          {userAddress ? (
            <div className="left-wrap only-mobile-show">
              <Button
                onClick={() => userToken ? setOpenAddressList(true) : setOpenAddressAdd(true)}
              >
                <img src={locationBlack} alt="location" />
                <span className="p2">
                  {userAddress ?
                    userAddress.business_address && userAddress.business_address !== ""
                      ? ` ${userAddress?.apartment_number}  ${userAddress?.building_number} ${userAddress?.street_number} ${userAddress.zone_number} ${userAddress?.business_address}` :
                      `${userAddress.zone_number}, ${userAddress.building_number}, ${userAddress.street_number}, ${userAddress.country}`
                    : null}
                </span>
              </Button>
            </div>
          ) : null}
          <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Link to={"/"}>
              <img src={headerLogo} className="header-logo" alt="baladi-logo" />
            </Link>
            <div className={click ? "mid-wrap active" : "mid-wrap"} >
              <TopToolbar />
              {businessTypes.map((businessType, index) =>
                businessType?.slug === "grocery" ? (
                  <Link onClick={handleClickMenu}
                    className={`a-link ${currentActive == businessType?.slug ? "active" : ""}`}
                    key={"businessType-" + index}
                    to={`/category/${businessType?.slug}`}
                  >
                    {businessType.business_type_locales[0].name}
                  </Link>
                ) : (
                  <Link onClick={handleClickMenu}
                    className={`a-link ${currentActive == businessType?.slug ? "active" : ""}`}
                    key={"businessType-" + index}
                    to={`/storefront/${businessType?.slug}`}
                  >
                    {businessType.business_type_locales[0].name}
                  </Link>
                )
              )}
            </div>

            <div className="right-wrap" onClick={closeMobileMenu}>
              {userToken ? (
                <Link to={"/wishlist"} className="header-icon">

                  <Badge badgeContent={wishCount} color="primary">
                    <img src={wishlistBlack} alt="wishlist" />
                  </Badge>
                </Link>
              ) : null}

              <Link to={"/cart"} className="header-icon">
                <Badge badgeContent={cartCount} color="primary">
                  <img src={bagBlack} alt="cart" />
                </Badge>
              </Link>
              {userToken ? (
                <>
                  <img
                    src={loginRedIcon}
                    className="header-btn-icon"
                    alt="loginRedIcon"
                    onClick={handleClick}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    disableScrollLock={true}
                  >
                    <Link className="header-drop" to={"/my-profile"}>
                      <MenuItem className="header-drop">
                        <img src={ProfileIcon} alt=".." />
                        {t("headerMyProfile")}
                      </MenuItem>
                    </Link>
                    <Link className="header-drop" to={"/order-history"}>
                      <MenuItem className="header-drop">
                        <img src={CardIcon} alt=".." />
                        {t("headerMmyOrders")}
                      </MenuItem>
                    </Link>
                    {/* <MenuItem
                      className="header-drop"
                      onClick={() => setOpenConfirmDialog(true)}  
                    >
                      <img src={CrossIcon} alt=".." />
                      {t("headerDeleteAccount")}
                    </MenuItem> */}
                    <MenuItem
                      className="header-drop"
                      onClick={() => {
                        setOpenChangePassword(true);
                        handleClose();
                      }}
                    >
                      <img src={LockIcon} alt=".." />
                      {t("headerChangePassword")}
                    </MenuItem>
                    <MenuItem
                      className="header-drop"
                      onClick={() => {
                        setOpenLogout(true);
                        handleClose();
                      }}
                    >
                      <img src={LogoutIcon} alt=".." />
                      {t("headerLogout")}
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Button
                  onClick={() => setOpenLogin(true)}
                  variant="contained"
                  size="large"
                  className="header-login-btn"
                  style={{ width: "fit-content" }}
                >
                  <img src={loginWhiteIcon} className="" alt="loginWhiteIcon" />
                  <span className="mx-2">{t("headerLogin")}</span>
                </Button>
              )}
            </div>
            <div className="menu-nav-icon" onClick={handleClickMenu}>
              {click ? <CloseIcon /> : <MenuIcon />}
            </div>
          </Boxed>
        </div>
      </div>
      {openLogin ? (
        <Login
          handleClose={() => setOpenLogin(false)}
          forgotDialog={(forgotidentity, country_code) => {
            identity = forgotidentity;
            setIdentity(identity);
            countryCode = country_code;
            setCountryCode(countryCode)
            setOpenLogin(false);
            setOpenForgot(true);
          }}
          loginSuccess={() => {
            setOpenLogin(false);
            getUserToken();
            checkCartData();
          }}
        />
      ) : null}
      {openForgot ? (
        <Forgot
          identity={identity}
          countryCode={countryCode}
          handleClose={() => {
            setOpenForgot(false);
            setIdentity("");
          }}
          openVerifyForgot={(res) => {
            forgotUname = res.uname;
            setForgotUname(forgotUname);
            countryCode = res.country_code;
            setCountryCode(countryCode);
            setOpenForgot(false);
            setOpenOTPVerification(true);
            setIdentity("");
          }}
        />
      ) : null}
      {openOTPVerification ? (
        <OTPVerificationForgot
          forgotUname={forgotUname}
          countryCode={countryCode}
          handleClose={() => setOpenOTPVerification(false)}
          openPasswordUpdate={(res) => {
            setCode(res.code);
            setuserId(res.user_id);
            setOpenOTPVerification(false);
            setOpenUpdatePassword(true);
          }}
        />
      ) : null}
      {openUpdatePassword ? (
        <UpdatePassword
          code={code}
          userId={userId}
          handleClose={() => setOpenUpdatePassword(false)}
          loginOpen={(response) => {
            setOpenUpdatePassword(false);
            setOpenLogin(true);
            dispatch(
              changeSnackbar({
                ...snackbar,
                isOpen: true,
                message: response.msg,
                state: "success",
              })
            );
          }}
        />
      ) : null}
      {openLogout ? (
        <Logout
          CloseDialog={() => {
            setOpenLogout(false);
          }}
          logOutSuccess={() => {
            setOpenLogout(false);
            getUserToken();
            getCartData();
            navigate("/");
          }}
        />
      ) : null}
      {openChangePassword ? (
        <ChangePassword
          handleClose={() => {
            setOpenChangePassword(false);
          }}
          successChange={() => {
            setOpenChangePassword(false);
          }}
        />
      ) : null}
      {openCheckCart ? (
        <CheckCart
          DiscardGuest={() => updateCartData("discard_guest")}
          DiscardUser={() => updateCartData("discard_user")}
        />
      ) : null}

      {openAddressList ? <AddressList
        handleClose={() => setOpenAddressList(false)}
        openAddAddress={() => {
          setOpenAddressList(false)
          setOpenAddressAdd(true)
        }}
      /> : null}
      {openAddressAdd ? <AddAddress
        handleClose={() => setOpenAddressAdd(false)}
        addressSaved={() => {
          setOpenAddressAdd(false)
        }}
      /> : null}
      {openConfirmDialog?<ConfirmDialog
        CloseDialog={() => setOpenConfirmDialog(false)}
        topHeading={t('headerDeleteAccount')}
        infoDialogMessage={t('headerDeleteAccountConfirmation')}
        infoDialogMessageLine2={t('headerDeleteAccountConfirmation2')}
        handleYes={() => {
          setOpenConfirmDialog(false);
          navigate("/delete-account");
        }}
      />: null}
      <Outlet />
    </>
  );
};

export default Header;
