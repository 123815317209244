/**
 * @file address.js
 * @description This file contains the address store configuration
 * @exports {Object} addressStore
 * @exports {Object} currentAddress
 * @exports {Object} changeAddress
 */

import { createSlice } from '@reduxjs/toolkit'
import { getLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';

const getDefaultAddress = () => {
  let address = getLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_ADDRESS);
  if (address) {
    return JSON.parse(address);
  } else {
    return {
      id: 0,
      zone_number: "",
      street_number: "",
      building_number: "",
      apartment_number: "",
      business_address: "860 Television Roundabout, Doha, Qatar",
      latitude: 25.31810373877699,
      longitude: 51.50027361022315,
      country: "Qatar",
    };
  }
}

const getTotalAddress = () => {
  let addressTotal = getLocalStorage(LOCAL_STORAGE_KEYS.TOTAL_ADDRESS);
  if (addressTotal) {
    return addressTotal;
  } else {
    return 0;
  }
}

export const addressStore = createSlice({
  name: 'addressStore',
  initialState: {
    value: getDefaultAddress(),
    total_address: 0
  },
  reducers: {
    changeAddress: (state, action) => {
      state.value = getDefaultAddress()
      state.total_address = getTotalAddress()
    }
  },
})

export const { changeAddress } = addressStore.actions;
export const currentAddress = (state) => state.addressStore.value
export const totalAddress = (state) => state.addressStore.total_address
export default addressStore.reducer;
