import "./RatingReview.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Boxed } from "../../Styles-Elements/Box";
import { Grid, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { getDateWithMonthNameAndSpace } from "../../Helpers/HelperFunctions";
import Footer from "../layout/footer/Footer";
import Breadcrumb from "../../shared/Breadcrumb";
import StoreTopBox from "../ProductDetail/StoreTopBox";
import ClipLoader from "react-spinners/ClipLoader";
import NoDataFound from '../../global-modules/EmptyPages/NoDataFound';
import Ratings from '../../global-modules/Rating/Ratings';
import useIntersection from '../../Helpers/useIntersection';
import { currentAddress } from '../../redux/reducers/address';

const RatingReview = () => {

  const ref = useRef(null);
  const { slug } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const inViewport = useIntersection(ref, '0px');
  let addressDefault = useSelector(currentAddress);
  let [storeCanLoad, setStoreCanLoad] = useState(false);
  let [loadmore, setLoadmore] = useState(false);
  let [storeDetails, setStoreDetails] = useState(null);
  let [reviews, setReviews] = useState([]);
  let [page, setPage] = useState(1);
  let [limit, setLimit] = useState(12);

  const getStoreDetails = async () => {
    dispatch(changeLoader(true));
    let url = API_ROUTES.STOREFRONT.GET_STORE_DETAILS(slug)
    url += `?latitude=${addressDefault?.latitude}&longitude=${addressDefault?.longitude}`;
    const response = await getRequest(url);
    const status = response.ack;
    if (status === 1) {
      storeDetails = response.store;
      setStoreDetails(storeDetails);
      dispatch(changeLoader(false));
    } else {
      dispatch(changeLoader(false));
      setStoreDetails(null)
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
    }
  }

  const getReviews = async () => {
    dispatch(changeLoader(true));
    page = 1;
    setPage(page);
    const response = await getRequest(API_ROUTES.STOREFRONT.GET_STORE_REVIEWS(slug) + `?page=${page}&limit=${limit}`);
    const status = response.ack;
    if (status === 1) {
      reviews = response.reviews;
      setReviews(reviews);
      dispatch(changeLoader(false));
      setTimeout(() => {
        setStoreCanLoad(true)
      }, 1000);
    } else {
      dispatch(changeLoader(false));
      setReviews([])
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
    }
  }

  const loadMoreReviews = async () => {
    setStoreCanLoad(false)
    setLoadmore(true)
    page++;
    setPage(page);
    const response = await getRequest(API_ROUTES.STOREFRONT.GET_STORE_REVIEWS(slug) + `?page=${page}&limit=${limit}`);
    const status = response.ack;
    if (status === 1) {
      reviews.push(...response.reviews);
      setReviews(reviews);
      setLoadmore(false)
      if (response.reviews?.length > 0) {
        setTimeout(() => {
          setStoreCanLoad(true)
        }, 2000);
      } else {
        setStoreCanLoad(false)
      }
    } else {
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
      setLoadmore(false)
    }

  }

  if (!loadmore && storeCanLoad && inViewport) {
    loadMoreReviews();
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    getStoreDetails();
    getReviews();
  }, [slug]);

  return (
    <div>
      <div className="business-category-wrapper">
        <div className="container-body">
          <Breadcrumb
            breadcrums={[{
              title: t('storefront_homepage'),
              haveLink: true,
              link: '/'
            }, {
              title: storeDetails?.business_type?.business_type_locales[0].name,
              haveLink: true,
              link: `/storefront/${storeDetails?.business_type?.slug}`
            }, {
              title: storeDetails?.stores_locales[0].business_name,
              haveLink: true,
              link: `/store/${storeDetails?.slug && storeDetails?.slug !== ''? storeDetails?.slug: storeDetails?.id}`
            }, {
              title: t("review_RatingsandReview"),
              haveLink: false
            }]}
          />
          <StoreTopBox storeDetails={storeDetails} />
          <div className="rating-review-wrap">
            <Grid container columnSpacing={"0px"}>
              <Grid item xs={9}>
                <h3 className="h3">{t("review_RatingsandReview")}</h3>
                {reviews?.length > 0 ?
                  reviews?.map((review, index) =>
                    <span key={`review-${index}`}>
                      <div className="shipping-blocks">
                        <Boxed
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          style={{ marginBottom: "8px" }}
                        >
                          <h6 className="h6">{review?.user?.full_name}</h6>
                          <span className="p3 always-ltr">{getDateWithMonthNameAndSpace(review?.createdAt)}</span>
                        </Boxed>
                        <Boxed display={"flex"} alignItems={"center"} className="rating-show-block">
                          <Ratings rating={review?.rating} withDiv={false} />
                        </Boxed>
                        <p className="p2">{review?.review}</p>
                      </div>
                      <Divider />
                    </span>)
                  : <NoDataFound text={t('review_NoReviewFound')} />}
              </Grid>
            </Grid>
          </div>
          <div className='loader-wrap' ref={ref} >
            <ClipLoader color={"#EF4930"} loading={loadmore} speedMultiplier={0.5} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RatingReview;
