/**
 * @file SignUp.js
 * @description This file contains the sign up page.
 */

import "./SignUp.scss"
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
	Grid, Button, InputLabel, FormControl, TextField, Checkbox,
	IconButton, OutlinedInput, InputAdornment
} from '@mui/material';
import Footer from '../../layout/footer/Footer';
import ThankYou from '../../../DialogBox/ThankYou'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Boxed } from "../../../Styles-Elements/Box"
import { postRequest } from '../../../Helpers/Axios/Services'
import { API_ROUTES } from '../../../Helpers/Axios/ApiRoutes'
import { axiosConstant } from '../../../Helpers/Axios/Constants';
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar, currentSnackbar } from "../../../redux/reducers/snackbar";
import { changeLoader } from "../../../redux/reducers/loader";
import { useTranslation } from "react-i18next";
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../../Helpers/crypto/LocalStorage';
import { validatePassword, validateEmail } from '../../../Helpers/HelperFunctions';
import { otpMask } from '../../../global-modules/MaskInputs/MaskedInputs';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ampli, DefaultConfiguration } from "../../../ampli";
const { REACT_APP_AMPLITUDE_API_KEY = "" } = process.env;
ampli.load({
	client: {
		apiKey: REACT_APP_AMPLITUDE_API_KEY,
		configuration: { ...DefaultConfiguration, logLevel: 3 },
	},
}); import moengage from '@moengage/web-sdk';
import jwtDecode from "jwt-decode";

const SignUp = () => {

	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isDialog, setIsDialog] = useState(false);
	let [fullName, setFullName] = useState('');
	let [fullNameError, setFullNameError] = useState('');
	let [email, setEmail] = useState('');
	let [emailError, setEmailError] = useState('');
	let [emailOtp, setEmailOtp] = useState('');
	let [isEmailOtp, setIsEmailOtp] = useState(false);
	let [isEmailValid, setIsEmailOtpValid] = useState(false);
	let [countryCode, setCountryCode] = useState(axiosConstant.COUNTRY_CODE);
	let [phone, setPhone] = useState('');
	let [phoneError, setPhoneError] = useState('');
	let [phoneOtp, setPhoneOtp] = useState('');
	let [isPhoneOtp, setisPhoneOtp] = useState(false);
	let [isPhoneValid, setisPhoneOtpValid] = useState(false);
	let [password, setPassword] = useState('');
	let [passwordError, setPasswordError] = useState('');
	let [showPassword, setShowPassword] = useState(true);
	let [passwordConfirm, setPasswordConfirm] = useState('');
	let [passwordConfirmError, setPasswordConfirmError] = useState('');
	let [showPasswordConfirm, setShowPasswordConfirm] = useState(true);
	let [timeLeft, setTimeLeft] = useState(31);
	const [isTimer, setIsTimer] = useState(false);
	let [timeLeftPhone, setTimeLeftPhone] = useState(31);
	const [isTimerPhone, setIsTimerPhone] = useState(false);
	const [isAgree, setIsAgree] = useState(false);
	let [isAgreeError, setIsAgreeError] = useState('');
	const [isNotRobot, setIsNotRobot] = useState(false);
	let [isNotRobotError, setIsNotRobotError] = useState('');
	let [EmailOtpText, setEmailOtpText] = useState(t('signupGetOTP'));
	let [PhoneOtpText, setPhoneOtpText] = useState(t('signupGetOTP'));
	const dispatch = useDispatch();
	const snackbar = useSelector(currentSnackbar);

	const onChangeReCAPTCHA = (value) => {
		if (value !== '') {
			setIsNotRobot(true);
			setIsNotRobotError('');
		}
	}

	const onExpiredReCAPTCHA = () => {
		setIsNotRobot(false);
		setIsNotRobotError('');
	};

	const updateTimer = () => {
		if (timeLeft > 1) {
			timeLeft--;
			setTimeLeft(timeLeft)
			setTimeout(() => { updateTimer(); }, 1000);
		} else {
			setIsTimer(false);
		}
	}

	const updateTimerPhone = () => {
		if (timeLeftPhone > 1) {
			timeLeftPhone--;
			setTimeLeftPhone(timeLeftPhone)
			setTimeout(() => { updateTimerPhone(); }, 1000);
		} else {
			setIsTimerPhone(false);
		}
	}

	const getEmailOtp = async (type) => {
		dispatch(changeLoader(true));
		let isValid = true;
		let uname = '';
		if (type === 'email') {
			setEmailError('');
			if (!validateEmail(email)) {
				setEmailError(t('signupEmailInvalid'))
				isValid = false;
			}
			uname = email;
		}
		if (type === 'phone') {
			setPhoneError('');
			if (phone === '') {
				setPhoneError(t('signupPhoneInvalid'))
				isValid = false;
			}
			uname = phone;
		}
		if (isValid) {
			const response = await postRequest(API_ROUTES.AUTHENTICATION.GetSignUpOTP, {
				country_code: countryCode,
				uname: uname.slice(countryCode.length),
				role: axiosConstant.ROLE
			});
			dispatch(changeLoader(false));
			if (response.ack === 1) {
				dispatch(
					changeSnackbar({
						...snackbar,
						isOpen: true,
						message: response.msg,
						state: "success",
					})
				);
				if (type === 'email') {
					setEmailError('');
					setEmailOtp('');
					setEmailOtpText(t('signupResendOTP'))
					timeLeft = 31;
					setTimeLeft(timeLeft);
					setIsTimer(true);
					setIsEmailOtp(true);
					updateTimer();
				} else {
					setPhoneOtp('');
					setPhoneError('');
					setPhoneOtpText(t('signupResendOTP'))
					timeLeftPhone = 31;
					setTimeLeftPhone(timeLeftPhone);
					setIsTimerPhone(true);
					setisPhoneOtp(true);
					updateTimerPhone();

				}
			} else {
				if (response?.msg && response?.msg !== '') {
					dispatch(
						changeSnackbar({
							...snackbar,
							isOpen: true,
							message: response?.msg,
							state: "error",
						})
					);
				} else if (response?.errMsg) {
					response?.errMsg.forEach(element => {
						for (const [key, value] of Object.entries(element)) {
							dispatch(
								changeSnackbar({
									...snackbar,
									isOpen: true,
									message: value,
									state: "error",
								})
							);
						}
					});
				}
			}
		} else {
			dispatch(changeLoader(false));
		}
	}

	const handleVerifyOtp = async (type) => {
		dispatch(changeLoader(true));
		let isValid = true;
		let uname = '';
		let otp = '';
		if (type === 'email') {
			setEmailError('');
			if (!validateEmail(email)) {
				setEmailError(t('signupEmailInvalid'))
				isValid = false;
			}
			if (emailOtp === '' || emailOtp.length !== 4) {
				setEmailError(t('signupOtpIsRequired'))
				isValid = false;
			}
			if (isValid) {
				uname = email;
				otp = emailOtp;
			}
		} else {
			if (type === 'phone') {
				setPhoneError('');
				if (phone === '') {
					setPhoneError(t('signupPhoneInvalid'))
					isValid = false;
				}
				uname = phone;
				otp = phoneOtp;
			}
		}
		if (isValid) {
			let response = await postRequest(API_ROUTES.AUTHENTICATION.VerifySignUpOtp, {
				uname: uname.slice(countryCode.length),
				country_code: countryCode,
				role: axiosConstant.ROLE,
				otp: otp
			})
			dispatch(changeLoader(false));
			if (response.ack === 1) {
				dispatch(
					changeSnackbar({
						...snackbar,
						isOpen: true,
						message: response.msg,
						state: "success",
					})
				);
				if (type === 'email') {
					setEmailError('');
					setTimeLeft(0);
					setIsTimer(false);
					setIsEmailOtp(false);
					setIsEmailOtpValid(true);
					setEmailOtp('');
				} else {
					setPhoneError('');
					setTimeLeftPhone(0);
					setIsTimerPhone(false);
					setisPhoneOtp(false);
					setisPhoneOtpValid(true);
					setPhoneOtp('');
				}
			} else {
				if (response?.msg && response?.msg !== '') {
					dispatch(
						changeSnackbar({
							...snackbar,
							isOpen: true,
							message: response?.msg,
							state: "error",
						})
					);
				} else if (response?.errMsg) {
					response?.errMsg.forEach(element => {
						for (const [key, value] of Object.entries(element)) {
							dispatch(
								changeSnackbar({
									...snackbar,
									isOpen: true,
									message: value,
									state: "error",
								})
							);
						}
					});
				}
			}
		} else {
			dispatch(changeLoader(false));
		}
	}

	const resetErrors = async () => {
		setFullNameError('');
		setEmailError('');
		setPhoneError('');
		setPasswordError('');
		setPasswordConfirmError('');
	}

	const resetInputs = async () => {
		setFullName(''); setEmail(''); setPhone(''); setPassword(''); setPasswordConfirm('');
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		dispatch(changeLoader(true));
		await resetErrors();
		let isValid = true;
		if (fullName === '') {
			setFullNameError(t('signupFullNameRequired'))
			isValid = false;
		}

		if (email !== '' && !isEmailValid) {
			emailError = t('signupValidEmail');
			setEmailError(emailError);
			isValid = false;
		}

		if (phone === '' || !isPhoneValid) {
			setPhoneError(t('signupValidPhone'))
			isValid = false;
		}
		if (password === "") {
			setPasswordError(t('signupErrorPassword')); isValid = false;
		} else if (password.length < 8) {
			setPasswordError(t('signupErrorPasswordLength'));
			isValid = false;
		} else if (!validatePassword(password)) {
			setPasswordError(t('signupPasswordshould'));
			isValid = false;
		}
		if (passwordConfirm === '') {
			setPasswordConfirmError(t('signupRequirePasswordConfirm'));
			isValid = false;
		} else if (passwordConfirm !== password) {
			setPasswordConfirmError(t('signupErrorPasswordConfirm'));
			isValid = false;
		}
		if (!isAgree) {
			setIsAgreeError(t('signupAccetTerms'))
			isValid = false;
		}
		if (!isNotRobot) {
			setIsNotRobotError(t('signupIsNotRobot'))
			isValid = false;
		}
		if (isValid) {
			const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
			let data = {
				full_name: fullName,
				email: email,
				password: password,
				role: axiosConstant.ROLE,
				deviceId: deviceId
			};
			if (isPhoneValid) {
				data.country_code = countryCode;
				data.contact_number = phone.slice(countryCode.length);
			}
			const response = await postRequest(API_ROUTES.AUTHENTICATION.SignUpUser, data);
			dispatch(changeLoader(false));
			if (response.ack === 1) {
				setIsDialog(true);
				resetInputs();
				if (response?.token) {
					const decoded = await jwtDecode(response.token);
					moengage?.add_unique_user_id(decoded?.id);
					setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response.token);
				}
			} else {
				if (response?.msg && response?.msg !== '') {
					dispatch(
						changeSnackbar({
							...snackbar,
							isOpen: true,
							message: response?.msg,
							state: "error",
						})
					);
				} else if (response?.errMsg) {
					response?.errMsg.forEach(element => {
						for (const [key, value] of Object.entries(element)) {
							if (key === 'full_name') { setFullNameError(value); }
							if (key === 'email') { setEmailError(value); }
							if (key === 'contact_number') { setPhoneError(value); }
							if (key === 'password') { setPasswordError(value); }
						}
					});
				}
			}
		} else {
			dispatch(changeLoader(false));
		}
	}

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	useEffect(() => {
		if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
			navigate(-1);
		}
	}, []);

	return (
		<>
			<form onSubmit={handleSubmit} action="#" method="post">
				<div className="signup-wrapper">
					<div className="container-body">
						<div className="signup-block">
							<Grid container columnSpacing={{ xs: 0, sm: 3, md: 4, xl: 5 }}>
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<div className="signup-left">
										<h1 className="h1">{t("signupLetsGetStarted")}</h1>
										<h3 className="h3">
											{t("signupJoinBaladiExpressTheMiddleEastLargestDeliveryPlatform")}
										</h3>
									</div>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<div className="signup-right">
										<div className="white-box">
											<h3 className="h3">{t("signupJoinBaladiExpressPlatform")}</h3>
											<p className="p3">
												{t("signupYourEmailAddressAndPhoneNumberWillBeUsedForLoginPurpose")}
											</p>
											<Grid container columnSpacing={3}>
												<Grid item xs={12}>
													<div className="form-group">
														<TextField
															label={t("signupFullName") + "*"}
															value={fullName}
															onChange={(e) => {
																setFullName(e.target.value);
																setFullNameError("");
															}}
															inputProps={{ maxLength: 100 }}
														/>
														<span className="errorspan">{fullNameError}</span>
													</div>
												</Grid>
												<Grid item xs={12} sm={12} md={isPhoneOtp ? 6 : 12}>
													<div className="form-group">
														<PhoneInput
															className="phone-input"
															country={'qa'}
															value={phone}
															placeholder="Enter phone number*"
															label={t("signupMobileNumber") + "*"}
															enableLongNumbers={true}
															onKeyDown={(e) => {
																if ((phone.length - countryCode.length) >= 16 && e.keyCode !== 8) {
																	e.preventDefault();
																}
															}}
															onChange={(value, data) => {
																setCountryCode(data.dialCode);
																setPhone(value);
																setPhoneError("");
															}}
															disabled={isPhoneValid}
														/>
														{!isTimerPhone && phone != '' && !isPhoneValid ? (
															<span
																className={isTimerPhone ? "redspan no-event" : "redspan"}
																onClick={() => { getEmailOtp("phone"); }}
															>{PhoneOtpText}</span>
														) : null}
														<span className="errorspan">{phoneError}</span>
													</div>
												</Grid>
												{isPhoneOtp ? (
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<div className="form-group">
															<TextField
																label={t("signupOtp")}
																placeholder={t("signupEnterOTP")}
																id="outlined-start-adornment"
																value={phoneOtp}
																onChange={(e) => {
																	setPhoneOtp(e.target.value);
																}}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			{isTimerPhone
																				? timeLeftPhone < 10
																					? `00:0${timeLeftPhone}`
																					: `00:${timeLeftPhone}`
																				: null}
																		</InputAdornment>
																	),
																	inputComponent: otpMask,
																}}
																mask="0000"
															/>
															{phoneOtp.length === 4 ? (
																<span
																	className={"redspan"}
																	onClick={() => {
																		handleVerifyOtp("phone");
																	}}
																>
																	{t("signupVerifyOTP")}
																</span>
															) : null}
														</div>
													</Grid>
												) : null}
												<Grid item xs={12}>
													<div className="form-group">
														<FormControl variant="outlined">
															<InputLabel htmlFor="outlined-adornment-password">
																{t("signupPassword") + "*"}
															</InputLabel>
															<OutlinedInput
																id="outlined-adornment-password"
																type={showPassword ? "password" : "text"}
																value={password}
																onChange={(e) => {
																	setPassword(e.target.value);
																	setPasswordError("");
																}}
																endAdornment={
																	<InputAdornment position="end">
																		<IconButton
																			aria-label="toggle password visibility"
																			onClick={handleClickShowPassword}
																			onMouseDown={handleMouseDownPassword}
																			edge="end"
																		>
																			{showPassword ? <VisibilityOff /> : <Visibility />}
																		</IconButton>
																	</InputAdornment>
																}
																label={t("signupPassword") + "*"}
															/>
														</FormControl>
														<span className="errorspan">{passwordError}</span>
													</div>
												</Grid>
												<Grid item xs={12}>
													<div className="form-group">
														<FormControl variant="outlined">
															<InputLabel htmlFor="outlined-adornment-password">
																{t("signupConfirmPassword") + "*"}
															</InputLabel>
															<OutlinedInput
																id="outlined-adornment-password"
																type={showPasswordConfirm ? "password" : "text"}
																onChange={(e) => {
																	setPasswordConfirm(e.target.value);
																	setPasswordConfirmError("");
																}}
																value={passwordConfirm}
																endAdornment={
																	<InputAdornment position="end">
																		<IconButton
																			aria-label="toggle password visibility"
																			onClick={() => {
																				setShowPasswordConfirm(!showPasswordConfirm);
																			}}
																			onMouseDown={handleMouseDownPassword}
																			edge="end"
																		>
																			{showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
																		</IconButton>
																	</InputAdornment>
																}
																label={t("signupConfirmPassword") + "*"}
															/>
														</FormControl>
														<span className="errorspan">{passwordConfirmError}</span>
													</div>
												</Grid>
												<Grid item xs={12}>
													<div className="form-group checkbox-block">
														<Checkbox
															onChange={(e) => {
																setIsAgree(!isAgree);
																setIsAgreeError("");
															}}
															checked={isAgree}
														/>
														<Boxed display={"flex"} className="">
															<span className="p2">{t("signupIAgreeTo")}</span>
															<Link
																to={"/terms-conditions"}
																className="no-decoration"
																target="_blank"
															>
																<span className="p2-semibold">{t("signupTermsAndConditions")}</span>
															</Link>
														</Boxed>
														<span className="errorspan bottom-18">{isAgreeError}</span>
													</div>
												</Grid>
												<Grid item xs={12} style={{ position: "relative" }}>
													<ReCAPTCHA
														className="recaptcha"
														sitekey={axiosConstant.GOOGLE_RECAPTCHA_SITE_KEY}
														onChange={onChangeReCAPTCHA}
														onExpired={onExpiredReCAPTCHA}
														onErrored={onExpiredReCAPTCHA}
													/>
													<span className="errorspan bottom-18">{isNotRobotError}</span>
												</Grid>
												<Grid item xs={7} textAlign="center">
													<Button
														type="submit"
														variant="contained"
														size="large"
														sx={{ width: 1, marginTop: "30px" }}
														onClick={() => ampli.track({
															event_type: 'SignedUp',
															event_properties: {
																category: 'Docs',
																name: 'SDK Library',
																description: 'SDK Library Description',
																user_id: 0,
																full_name: full_name,
																email: email,
																country_code: countryCode,
																contact_number: phone,
																deviceId: getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID),
															},
														})}
													>
														{t("signupSubmit")}
													</Button>
												</Grid>
											</Grid>
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</div>
			</form>
			<Footer />
			{isDialog ? (
				<ThankYou
					CloseDialog={() => {
						setIsDialog(false);
						sessionStorage.setItem("signupSuccess", false);
						navigate(-1);
					}}
				/>
			) : null}
		</>
	);
}

export default SignUp;