import moment from 'moment';

/**
 * Month constants
 * @constant
 */
const MONTH = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

/**
 * @description Day constants
 * @constant
 */
export const DAY = [
  'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'
];

/**
 * @description display price with 2 decimal places
 * @param {*} price 
 * @returns float with 2 decimal places
 */
export const displayPrice = (price) => {
  if (price !== undefined) {
    if (price === 0) {
      return '0.00';
    }
    return price?.toFixed(2);
  }
}

/**
 * @description arrange days based on day name
 * @param {*} days 
 * @returns 
 */
export const arrangeDays = (days) => {
  const daysArr = [];
  days.forEach((day) => {
    switch (day.day) {
      case 'sunday':
        daysArr[0] = day;
        break;
      case 'monday':
        daysArr[1] = day;
        break;
      case 'tuesday':
        daysArr[2] = day;
        break;
      case 'wednesday':
        daysArr[3] = day;
        break;
      case 'thursday':
        daysArr[4] = day;
        break;
      case 'friday':
        daysArr[5] = day;
        break;
      case 'saturday':
        daysArr[6] = day;
        break;
      default:
        break;
    }
  });
  return daysArr;
};

/**
 * @description function to get all hours in a day with 30 minutes interval
 * @returns 
 */
export const getHours = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 2; j++) {
      const hour = i < 10 ? `0${i}` : i;
      const minute = j === 0 ? '00' : '30';
      hours.push(`${hour}:${minute}`);
    }
  }
  return hours;
};

/**
 * @description function to get all hours in a day with 30 minutes interval between start and end time
 * @param {*} start 
 * @param {*} end 
 * @returns 
*/
export const getHoursBetween = (start, end) => {
  const hours = [];
  const startHour = parseInt(start.split(':')[0], 10);
  const startMinute = parseInt(start.split(':')[1], 10);
  const endHour = parseInt(end.split(':')[0], 10);
  const endMinute = parseInt(end.split(':')[1], 10);
  for (let i = startHour; i <= endHour; i++) {
    for (let j = 0; j < 2; j++) {
      const hour = i < 10 ? `0${i}` : i;
      const minute = j === 0 ? '00' : '30';
      if (i === startHour && j === 0 && startMinute === 30) {
        continue;
      }
      if (i === endHour && j === 1 && endMinute === 0) {
        continue;
      }
      hours.push(`${hour}:${minute}`);
    }
  }
  return hours;
};


/**
 * @description function to get all hours in a day with 15 minutes interval between start and end time
 * @param {*} start 
 * @param {*} end 
 * @param {*} from 
 * @returns 
 */

export const getHoursBetween15 = (start, end) => {
  const hours = [];
  const startHour = parseInt(start.split(':')[0], 10);
  const startMinute = parseInt(start.split(':')[1], 10);
  const endHour = parseInt(end.split(':')[0], 10);
  const endMinute = parseInt(end.split(':')[1], 10);
  for (let i = startHour; i <= endHour; i++) {
    for (let j = 0; j < 4; j++) {
      const hour = i < 10 ? `0${i}` : i;
      const minute = j === 0 ? '00' : j === 1 ? '15' : j === 2 ? '30' : '45';
      if (i === startHour && j === 0 && startMinute === 30) {
        continue;
      }
      if (i === endHour && j === 3 && endMinute === 0) {
        continue;
      }
      hours.push(`${hour}:${minute}`);
    }
  }
  return hours;
};

/**
 * @description function to get all hours in a day with 15 minutes interval between start and end time from a given time
 * @param {*} start 
 * @param {*} end 
 * @param {*} from 
 * @returns 
 */

export const getHoursBetweenFrom15 = (start, end, from) => {
  const hours = [];
  const startHour = parseInt(start.split(':')[0], 10);
  const startMinute = parseInt(start.split(':')[1], 10);
  const endHour = parseInt(end.split(':')[0], 10);
  const endMinute = parseInt(end.split(':')[1], 10);
  const fromHour = parseInt(from.split(':')[0], 10);
  const fromMinute = parseInt(from.split(':')[1], 10);
  for (let i = fromHour; i <= endHour; i++) {
    for (let j = 0; j < 4; j++) {
      const hour = i < 10 ? `0${i}` : i;
      const minute = j === 0 ? '00' : j === 1 ? '15' : j === 2 ? '30' : '45';
      if (i === startHour && j === 0 && startMinute === 30) {
        continue;
      }
      if (i === endHour && j === 3 && endMinute === 0) {
        continue;
      }
      hours.push(`${hour}:${minute}`);
    }
  }
  return hours;
};

/**
 * @description function to get all hours in a day with 30 minutes interval between start and end time from a given time
 * @param {*} start 
 * @param {*} end 
 * @param {*} from 
 * @returns 
 */
export const getHoursBetweenFrom = (start, end, from) => {
  const hours = [];
  const startHour = parseInt(start.split(':')[0], 10);
  const startMinute = parseInt(start.split(':')[1], 10);
  const endHour = parseInt(end.split(':')[0], 10);
  const endMinute = parseInt(end.split(':')[1], 10);
  const fromHour = parseInt(from.split(':')[0], 10);
  const fromMinute = parseInt(from.split(':')[1], 10);
  for (let i = fromHour; i <= endHour; i++) {
    for (let j = 0; j < 2; j++) {
      const hour = i < 10 ? `0${i}` : i;
      const minute = j === 0 ? '00' : '30';
      if (i === startHour && j === 0 && startMinute === 30) {
        continue;
      }
      if (i === endHour && j === 1 && endMinute === 0) {
        continue;
      }
      hours.push(`${hour}:${minute}`);
    }
  }
  return hours;
};

/**
 * @description convert time to 12 hour format
 * @param {*} time 
 * @returns 
 */
export const convertTimeTo12Hour = (time) => {
  const hours = parseInt(time.split(':')[0], 10);
  const minutes = parseInt(time.split(':')[1], 10);
  const amPm = hours >= 12 ? 'PM' : 'AM';
  let hour = hours % 12;
  hour = hour === 0 ? 12 : hour;
  const hourStr = hour < 10 ? `0${hour}` : hour;
  const minuteStr = minutes < 10 ? `0${minutes}` : minutes;
  return `${hourStr}:${minuteStr} ${amPm}`;
};

/**
 * @description email validation
 * @param {*} email 
 * @returns 
 */
export const validateEmail = (email) => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

/**
 * @description phone number validation
 * @param {*} phoneNumber 
 * @returns 
 */
export const validatePhoneNumber = (phoneNumber) => {
  const phoneNumberRegex = /^\d{10}$/;
  return phoneNumberRegex.test(phoneNumber);
};

/**
 * @description Date With Month Name And Space
 * @param {*} givenDate 
 * @returns 
 */
export const getDateWithMonthNameAndSpace = (givenDate) => {
  const date = new Date(`${givenDate}`);
  const day = (date.getDate() > 9) ? date.getDate() : `0${date.getDate()}`;
  const month = MONTH[date.getMonth()];
  return `${day} ${month} ${date.getFullYear()}`;
}

export const getCurrentDate = () => {
  const date = new Date();
  const day = (date.getDate() > 9) ? date.getDate() : `0${date.getDate()}`;
  const month = MONTH[date.getMonth()];
  return `${date.getFullYear()}/${month}/${day}`;
}

/**
 * @description Date And Time From Timestamp
 * @param {*} timestamp 
 * @returns 
 */
export const getDateAndTimeFromTimestamp = (timestamp) => {
  const dateTime = new Date(timestamp);
  const day = (dateTime.getDate() > 9) ? dateTime.getDate() : `0${dateTime.getDate()}`;
  const month = (dateTime.getMonth() + 1) > 9 ? dateTime.getMonth() + 1 : `0${dateTime.getMonth() + 1}`;
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  let hour = hours;
  let meridian = 'AM';
  if (hours > 12) {
    hour = hours - 12;
    meridian = 'PM';
  }
  return `${day}/${month}/${dateTime.getFullYear()} | ${(hour > 9) ? hour : '0' + hour}:${(minutes > 9) ? minutes : '0' + minutes} ${meridian}`;
}

/**
 * @description sort categories and make grocery category first
 * @param {*} categories
 * @returns sorted categories
 */
export const sortCategories = (categories) => {
  // const groceryCategory = categories?.find((category) => category?.slug === 'grocery');
  // const otherCategories = categories?.filter((category) => category?.slug !== 'grocery');
  return categories;
}

/**
 * @description validate Password based on regex pattern
 * @should contain at least 8 characters and at most 20 
 *  characters and must contain at least one numeric digit and any string of characters and (@, #, $, *, _,)
 * @param {*} password
 * @returns boolean
 */
export const validatePassword = (password) => {
  const passwordRegex = /\d/;
  return passwordRegex.test(password);
}

/**
 * @description get title based on language
 * @param {*} title
 * @param {*} arabicTitle
 * @returns title based on language selected in local storage or english by default if local storage is empty or not set
 * also if arabic title is empty then english title will be returned
 */

export const getTitle = (title, arabicTitle) => {
  const language = localStorage.getItem('i18nextLng') || 'en';
  return language === 'en' ? title : (arabicTitle && arabicTitle !== '' ? arabicTitle : title);
}

/**
 * @description get category name based on language
 * @param {*} local 
 * @returns category name based on language selected in local storage or english by default if local storage is empty or not set
 * also if arabic category name is empty then english category name will be returned
 */

export const getNameBasedOnLanguage = (local) => {
  const language = localStorage.getItem('i18nextLng') || 'en';
  const localName = local.find((item) => item.locale === language);
  return localName?localName?.name: local[0]?.name;
};

export const getDataBasedOnLanguage = (local) => {
  const language = localStorage.getItem('i18nextLng') || 'en';
  const localName = local.find((item) => item.locale === language);
  return localName?.name;
};

/**
 * @description get rating to nearest 0.5
 * @param {*} rating
 * @returns rating to nearest 0.5
 * @example 3.2 => 3.5 3.7 => 4
 */
export const getRating = (rating) => {
  return Math.round(rating * 2) / 2;
}

/**
 * @description get rating to one decimal place
 * @param {*} rating
 * @returns rating to one decimal place
 * @example 3.200 => 3.2 3.70 => 3.7
 */
export const getRatingToDecimal = (rating) => {
  return (Math.round(rating * 10) / 10)?.toFixed(1);
}

/**
 * @description function to get front type image url or image at index 0
 * @param {*} images
 * @returns front type image url or image at index 0
 * @example images = [{type: 'front', url: 'https://example.com/image1.jpg'}, {type: 'back', url: 'https://example.com/image2.jpg'}]
 * getFrontTypeImageUrl(images) => 'https://example.com/image1.jpg'
 * @example images = [{type: 'back', url: 'https://example.com/image2.jpg'}]
 * getFrontTypeImageUrl(images) => 'https://example.com/image2.jpg'
 * @example images = []
 * getFrontTypeImageUrl(images) => ''
 * @example images = undefined
 */

export const getFrontTypeImageUrl = (images) => {
  if (images && images.length > 0) {
    const frontTypeImage = images.find((image) => image.type === 'front');
    return frontTypeImage ? frontTypeImage.image : images[0].image;
  }
  return '';
}

/**
 * @description function to get review count human readable format
 * @param {*} reviewCount
 * @returns review count human readable format 
 */
export const getReviewCount = (reviewCount) => {
  if (reviewCount >= 1000) {
    return `${(reviewCount / 1000)?.toFixed(1)}K +`;
  }
  return reviewCount;
}

/**
 * @description function to calculate distance between two lat long
 * @param {*} lat1
 * @param {*} lon1
 * @param {*} lat2
 * @param {*} lon2
 * @returns distance in km
 * @example getDistanceFromLatLonInKm(24.8607, 67.0011, 24.8607, 67.0011) => 0
 * @example getDistanceFromLatLonInKm(24.8607, 67.0011, 24.8607, 67.0012) => 0.01
 */
const deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);  // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const kms = Math.round(R * c); // Distance in km
  return kms < 1 ? `< 1` : kms;
}

/**
 * @description function to get last 4 digits of card number
 * @param {*} cardNumber
 * @returns last 4 digits of card number
 * @example getLastFourDigitsOfCardNumber('1234567890123456') => '3456'
*/
export const getLastFourDigitsOfCardNumber = (cardNumber) => {
  return cardNumber.slice(-4);
}

/**
 * @description function to set document title for SEO
 * @param {*} title
 * @returns void
 */
export const setDocumentTitle = (title) => {
  const siteName = "Baladi Express"
  document.title = `${title} | ${siteName}` || siteName;
}

/**
 * @description function arrange transaction history based on date
 * @param {*} transactions
 * @returns transactions grouped by date as array 
 * @example transactions = [{id: 1, date: '2021-01-01'}, {id: 2, date: '2021-01-01'}, {id: 3, date: '2021-01-02'}]
*/
export const getTransactionsGroupedByDate = (transactions) => {
  const transactionsGroupedByDate = transactions.reduce((acc, transaction) => {
    const date = moment(transaction.createdAt).format('DD/MM/YYYY');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(transaction);
    return acc;
  }, {});
  return Object.entries(transactionsGroupedByDate);
}

/**
 * @description function to get time from date
 * @param {*} date
 * @returns time in hh:mm AM/PM format
 */
export const getTimeFromDate = (date) => {
  return moment(date).format('hh:mm A');
}

/**
 * @description function to sort images based on type
 * @param {*} images
 */
export const sortImagesBasedOnType = (images) => {
  return images.sort((a, b) => {
    if (a.type === 'front') {
      return -1;
    }
    if (b.type === 'front') {
      return 1;
    }
    return 0;
  });
}
