import "./businessCategory.scss";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TreeView, TreeItem } from '@mui/x-tree-view';
import {
  MenuItem, FormControl, Select, Grid, FormGroup, FormControlLabel, Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import ClipLoader from "react-spinners/ClipLoader";
import StickyBox from "react-sticky-box";

import { Boxed } from '../../Styles-Elements/Box';
import { getRequest, postRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { changeActiveLink } from "../../redux/reducers/activeLink";
import { changeCart } from '../../redux/reducers/cart';
import { changeWishlist } from '../../redux/reducers/wishlist';
import { currentAddress } from '../../redux/reducers/address';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import { getNameBasedOnLanguage, getCurrentDate } from '../../Helpers/HelperFunctions';

import Footer from '../layout/footer/Footer';
import Breadcrumb from "../../shared/Breadcrumb"
import StoreDetails from "./storeDetails/StoreDetails";
import useIntersection from '../../Helpers/useIntersection';
import NoDataFound from '../../global-modules/EmptyPages/NoDataFound';
import ProductBox from '../../global-modules/Product/ProductBox';
import sortbyIcon from "../../assets/images/structure/sortby-icon.svg";
import filterIcon from "../../assets/images/structure/filter.png";
import { ampli } from "../../ampli";

const BusinessCategory = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const userAddress = useSelector(currentAddress)
  const inViewport = useIntersection(ref, '2000px');
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const { storeslug, categorySlug } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [filterRow, setFilterRow] = useState("d-flex")
  const [click, setClick] = useState(false);
  let [selectedCategory, setSelectedCategory] = useState('');
  let [loadmore, setLoadmore] = useState(false);
  let [storeCanLoad, setStoreCanLoad] = useState(false);
  let [page, setPage] = useState(1);
  let [limit, setLimit] = useState(16);
  let [sortBy, setSortBy] = useState('latest');
  let [orderBy, setOrderBy] = useState('desc');
  let [search, setSearch] = useState('');
  let [storeDetails, setStoreDetails] = useState(null);
  let [storeLevel, setStoreLevel] = useState(null);
  let [storeCategories, setStoreCategories] = useState(null);
  let [storeProducts, setStoreProducts] = useState([]);
  let [expanded, setExpanded] = useState([]);
  let [levelOneCategory, setLevelOneCategory] = useState(0);
  let [levelTwoCategory, setLevelTwoCategory] = useState([]);
  let [levelThreeCategory, setLevelThreeCategory] = useState([]);
  let [userToken, setUserToken] = useState(null);
  let [scroll, setScroll] = useState(false);
  let [discountDataOfAll, setDiscountDataOfAll] = useState([])
  let [categoryOffers, setCategoryOffers] = useState([])
  let [categoryOfferApplied, setCategoryOfferApplied] = useState(null)
  let [categorySearch, setCategorySearch] = useState(searchParams.get('cat') ? parseInt(searchParams.get('cat')) : null)
  let [selectedCategories, setSelectedCategories] = useState([]);

  // const getUserToken = () => {
  //   const UserToken = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
  //   userToken = (UserToken) ? UserToken : null;
  //   setUserToken(userToken);
  // }

  const checkExpanded = () => {
    expanded = [levelOneCategory];
    levelTwoCategory?.forEach(element => { expanded?.push(element) });
    setExpanded(expanded);

  }

  const getWishDate = async () => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const { ack, data, msg, discountDataOfAll } = await getRequest(API_ROUTES.WISHLIST.GET_WISHLIST_DATA);
      if (ack === 1) {
        const productOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'product');
        const categoryOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'category');
        const storeOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'store');
        const businessOffers = discountDataOfAll?.filter((item) => item?.apply_type === "business_type");
        const products = data.map((item) => {
          let offers = productOffers?.filter((offer) =>
            offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id).length > 0) ?
            productOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id)) :
            productOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId));
          if (offers?.length === 0) {
            offers = categoryOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId).length > 0) ?
              categoryOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId)) :
              categoryOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id));
          }

          if (offers?.length === 0) {
            offers = storeOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id).length > 0) ?
              storeOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id)) :
              storeOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId));
          }
          if (offers?.length === 0) {
            offers = businessOffers?.filter((offer) => offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId))
          }
          item.offers = offers;
          return item;
        });
        setLocalStorage(LOCAL_STORAGE_KEYS.WISHLIST, JSON.stringify(products))
        dispatch(changeWishlist());
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: msg,
          state: "error",
        }));
      }
    }
  }

  const removeLevelOneCategory = () => {
    levelOneCategory = "";
    setLevelOneCategory(levelOneCategory);
    checkExpanded();
  }

  const getRemainingCategories = async (id) => {
    dispatch(changeLoader(true));
    handleLevelOneCategory(id);
  }

  const handleSortBy = (event) => {
    sortBy = event.target.value;
    setSortBy(sortBy);
    page = 1;
    setPage(page);
    getStoreProductById();
    // ampli==
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }

    ampli.track({
      event_type: 'FiltersApplied',
      event_properties: {
        category: 'Docs',
        name: 'SDK Library',
        description: 'SDK Library Description',
        user_id: userID ? userID : 0,
        filter: sortBy,
      },
    });
  }

  const getSelectedCategories = () => {
    let categories = [];
    if (storeCategories) {
      if (levelThreeCategory?.length > 0) {
        categories = levelThreeCategory;
      } else if (levelTwoCategory?.length > 0) {
        const selectedCategory = storeCategories.find(category => category.id === levelOneCategory);
        if (selectedCategory && selectedCategory?.category?.length > 0) {
          const cat2 = selectedCategory?.category.find(category => category.id === levelTwoCategory[0]);
          if (cat2 && cat2?.category?.length > 0) {
            categories = cat2?.category.map(category => category.id);
          } else {
            categories = levelTwoCategory;
          }
        } else {
          categories = levelTwoCategory;
        }
      } else {
        const selectedCategory = storeCategories.find(category => category.id === levelOneCategory);
        if (selectedCategory && selectedCategory?.category?.length > 0) {
          categories = selectedCategory.category.map(category => category.id);
        } else {
          categories = [levelOneCategory];
        }
      }
    }
    selectedCategories = categories;
    setSelectedCategories(selectedCategories);
  }

  useEffect(() => {
    getSelectedCategories();
  }, [levelOneCategory, levelTwoCategory, levelThreeCategory])

  useEffect(() => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    ampli.track({
      event_type: 'StoreDetailPageViewed',
      event_properties: {
        category: 'Docs',
        name: 'SDK Library',
        description: 'SDK Library Description',
        user_id: userID ? userID : "",
        store_id: storeDetails?.id,
        store_name: storeDetails?.stores_locales[0]?.business_name,
      },
    })
  }, [])

  const handleClickMenu = () => setClick(!click);

  const handleLevelOneCategory = async (id) => {    
    const getSelectedCategory = storeCategories.find(category => category.id === id);
    navigate(`/store/${storeslug}/${getSelectedCategory.slug}`)
    levelOneCategory = id;
    setLevelOneCategory(levelOneCategory);
    const categoryLevel = storeCategories?.find(storeCategory => storeCategory.id === id);
    if (categoryLevel?.category?.length > 0) {
      if (categoryLevel?.category[0]?.category?.length > 0) {
        selectedCategory = getNameBasedOnLanguage(categoryLevel?.category_locales);
        setSelectedCategory(selectedCategory);
        levelTwoCategory = [];
        setLevelTwoCategory(levelTwoCategory);
        handleLevelTwoCategory(id, categoryLevel?.category[0]?.id);
      } else {
        selectedCategory = getNameBasedOnLanguage(categoryLevel?.category_locales);
        setSelectedCategory(selectedCategory);
        getStoreProductById();
      }
    } else {
      selectedCategory = getNameBasedOnLanguage(categoryLevel?.category_locales);
      setSelectedCategory(selectedCategory);
      getStoreProductById();
    }
    getSelectedCategories();
    // window.scrollTo(0, 0);
  }

  const removeLevelTwoCategory = (id) => {
    if (levelTwoCategory?.includes(id)) {
      let index = levelTwoCategory?.indexOf(id);
      levelTwoCategory?.splice(index, 1)
    }
    setLevelTwoCategory(levelTwoCategory);
    checkExpanded()
  }

  const handleLevelTwoCategory = (level1catid, id) => {
    if (levelOneCategory !== level1catid) {
      levelOneCategory = level1catid;
      setLevelOneCategory(levelOneCategory);
      levelTwoCategory = [];
    }
    levelThreeCategory = [];
    setLevelThreeCategory(levelThreeCategory);
    if (levelTwoCategory?.includes(id)) {
      let index = levelTwoCategory?.indexOf(id);
      levelTwoCategory?.splice(index, 1)
    } else {
      levelTwoCategory?.push(id)
    }
    setLevelTwoCategory(levelTwoCategory);
    getStoreProductById();
    getSelectedCategories();
  }

  const handleLevelThreeCategory = (level2catid, id) => {
    if (!levelTwoCategory?.includes(level2catid)) {
      levelTwoCategory = [level2catid];
      setLevelTwoCategory(levelTwoCategory);
      levelThreeCategory = [];
    }
    if (levelThreeCategory?.includes(id)) {
      let index = levelThreeCategory?.indexOf(id);
      levelThreeCategory?.splice(index, 1)
    } else {
      levelThreeCategory.push(id)
    }
    setLevelThreeCategory(levelThreeCategory)
    getStoreProductById();
    getSelectedCategories();
  }

  const setActiveLink = async (typeid) => {
    setLocalStorage(LOCAL_STORAGE_KEYS.ACTIVE_LINK, typeid)
    dispatch(changeActiveLink());
  }

  const getStoreDetails = async () => {
    dispatch(changeLoader(true));
    let url = API_ROUTES.STOREFRONT.GET_STORE_DETAILS(storeslug);
    url += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const response = await getRequest(url);
    const status = response.ack;
    if (status === 1) {
      const data = response.store;
      let offers = data?.discounts_and_offer_relations?.map((offer) => offer?.discounts_and_offer);
      if (offers?.length === 0) {
        let storeOffers = response.discountDataOfAll?.filter(offer => offer?.apply_type === 'store').length > 0 ?
          response.discountDataOfAll?.filter(
            offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(data?.id)
          ).length > 0 ? response.discountDataOfAll?.filter(
            offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(data?.id)
          ) : response.discountDataOfAll?.filter(offer => offer?.apply_type === 'store' && offer?.apply_on === 'all') : [];
        if (storeOffers.length === 0) {
          storeOffers = response.discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type').length > 0 ?
            response.discountDataOfAll?.filter(
              offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(data.businessTypeId)
            ).length > 0 ? response.discountDataOfAll?.filter(
              offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(data.businessTypeId)
            ) : response.discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'all') : [];
        }
        offers = [...storeOffers];
      }
      data.offers = offers;
      storeDetails = data;

      setStoreDetails(storeDetails);
      setActiveLink(data?.business_type?.slug)
      let allCategories = [];
      let storeCategoriesData = response?.categories;

      const hasSearchCategory = searchParams.get('cat') ? true : false;
      let levelCategory = 0;
      storeCategoriesData?.forEach(element => {
        if (hasSearchCategory && element?.id == categorySearch) {
          levelCategory = 1
        }
        if (element?.category?.length > 0) {
          let subCategories = [];
          element?.category.forEach(subElement => {
            if (hasSearchCategory && subElement?.id == categorySearch) {
              levelCategory = 2
            }
            if (subElement?.category?.length > 0) {
              let subSubCategories = [];
              subElement?.category.forEach(subSubElement => {
                if (hasSearchCategory && subElement?.id == categorySearch) {
                  levelCategory = 3
                }
                if (subSubElement?.productCount !== 0) {
                  subSubCategories?.push(subSubElement)
                }
              });
              if (subSubCategories?.length > 0) {
                subCategories.push({ ...subElement, category: subSubCategories })
              }
            } else {
              if (subElement?.productCount !== 0) {
                subCategories.push(subElement)
              }
            }
          });
          if (subCategories?.length > 0) {
            allCategories.push({ ...element, category: subCategories })
          }
        } else {
          if (element?.productCount !== 0) {
            allCategories?.push(element)
          }
        }
      });
      const categories = allCategories.map((category) => {
        let timeArray = category?.timejson === undefined || category?.timejson === '' ? [] : JSON.parse(category?.timejson);
        if (timeArray.length === 0) {
          timeArray = (category?.store_category_relation?.timejson !== '' && category?.store_category_relation?.timejson !== undefined) ?
            JSON.parse(category?.store_category_relation.timejson) : [];
        }
        let categoryAvailable = true;
        if (timeArray.length > 0) {
          categoryAvailable = false;
          const today = getCurrentDate();
          timeArray.forEach(element => {
            const startDateTime = new Date(`${today} ${element?.starttime}`);
            const endDateTime = new Date(`${today} ${element?.endtime}`);
            const currentDateTime = new Date();
            if (currentDateTime >= startDateTime && currentDateTime <= endDateTime) {
              categoryAvailable = true;
            }
          });
        }
        category.categoryAvailable = categoryAvailable;
        return category;
      })?.filter(category => category.categoryAvailable);
      storeCategories = categories;
      storeLevel = storeDetails?.business_type?.category_level
      setStoreLevel(storeLevel)

      setStoreCategories(storeCategories);
      dispatch(changeLoader(false));
      let categoryToSelect = storeCategories[0];
      if (categorySlug) {
        categoryToSelect = storeCategoriesData?.find(category => category?.slug === categorySlug);
      }
      handleLevelOneCategory(categoryToSelect?.id)
      if (hasSearchCategory && levelCategory === 1) {
        handleLevelOneCategory(categorySearch)
      } else if (hasSearchCategory && levelCategory === 2) {
        const selectedCategory = storeCategories.find(category => category.id == categorySearch);
        handleLevelOneCategory(selectedCategory?.parentId)
        setTimeout(() => {
          handleLevelTwoCategory(selectedCategory?.parentId, categorySearch)
        }, 1000);
      } else if (hasSearchCategory && levelCategory === 3) {
        const selectedCategory = storeCategories.find(category => category.id == categorySearch);
        handleLevelOneCategory(selectedCategory?.parentId)
        setTimeout(() => {
          handleLevelTwoCategory(selectedCategory?.parentId, selectedCategory?.parent?.parentId)
        }, 1000);
        setTimeout(() => {
          handleLevelThreeCategory(selectedCategory?.parent?.parentId, categorySearch)
        }, 2000);
      }


    } else {
      dispatch(changeLoader(false));
      setStoreDetails(null)
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
    }
  }

  const getOfferForCategory = () => {
    const offer = categoryOffers?.filter((item) => item?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.categoryId).includes(levelOneCategory))
    categoryOfferApplied = offer
    setCategoryOfferApplied(categoryOfferApplied)
  }

  const handleSearch = (data) => {
    if (storeDetails) {
      search = data;
      setSearch(search)
      if (search !== '') {
        navigate(`/store/${storeslug}?s=${search}`)
      } else {
        navigate(`/store/${storeslug}`)
      }
      getStoreProductById();
    }
  }

  const getStoreProductById = useCallback(async () => {
    setScroll(false)
    dispatch(changeLoader(true));
    page = 1;
    setPage(page);
    let categories = [];
    if (levelThreeCategory?.length > 0) {
      categories = levelThreeCategory;
    } else if (levelTwoCategory?.length > 0) {
      const selectedCategory = storeCategories.find(category => category.id === levelOneCategory);
      if (selectedCategory && selectedCategory?.category?.length > 0) {
        const cat2 = selectedCategory?.category.find(category => category.id === levelTwoCategory[0]);
        if (cat2 && cat2?.category?.length > 0) {
          categories = cat2?.category.map(category => category.id);
        } else {
          categories = levelTwoCategory;
        }
      } else {
        categories = levelTwoCategory;
      }
    } else {
      const selectedCategory = storeCategories.find(category => category.id === levelOneCategory);
      if (selectedCategory && selectedCategory?.category?.length > 0) {
        categories = selectedCategory.category.map(category => category.id);
      } else {
        categories = [levelOneCategory];
      }
    }
    let isValid = true;
    let url = API_ROUTES.STOREFRONT.GET_PRODUCTS_BY_STORE_CATEGORY(storeslug);
    url += `?page=${page}&limit=${limit}`;
    url += search !== '' ? `&search=${search}` : `&sort_by=${sortBy}&order_by=${orderBy}`;
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    if (search === '') {
      isValid = false;
      categories?.forEach(element => {
        if (element !== 0 && element !== undefined && element !== null) {
          isValid = true;
        }
        url += `&category_id=${element}`;
      });
    }
    if (isValid) {
      const response = await getRequest(url);
      const status = response?.ack;

      categoryOffers = response.discountDataOfAll?.filter((item) => item?.apply_type === 'category');
      setCategoryOffers(categoryOffers)
      const products = response?.products?.length > 0 ?
        response?.products.map((product) => {
          let offers = product?.discounts_and_offer_relations?.map((offer) => offer?.discounts_and_offer);
          discountDataOfAll = response.discountDataOfAll;
          setDiscountDataOfAll(discountDataOfAll)
          if (offers?.length === 0) {
            let productOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'product').length > 0 ?
              discountDataOfAll?.filter(
                offer => offer?.apply_type === 'product' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.product_id).includes(product?.id)
              ).length > 0 ? discountDataOfAll?.filter(
                offer => offer?.apply_type === 'product' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.product_id).includes(product?.id)
              ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'product' && offer?.apply_on === 'all') : [];

            // if (productOffers.length === 0) {
            //   const categoriesId = storeCategories.find(category => category.id == product.categoryId)
            //   productOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'category').length > 0 ?
            //     discountDataOfAll?.filter(
            //       offer => offer?.apply_type === 'category' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.categoryId).includes(levelOneCategory)
            //     ).length > 0 ? discountDataOfAll?.filter(
            //       offer => offer?.apply_type === 'category' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.categoryId).includes(levelOneCategory)
            //     ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'category' && offer?.apply_on === 'all') : [];
            // }
            // if (productOffers.length === 0) {
            //   productOffers = discountDataOfAll.filter(offer => offer?.apply_type === 'store').length > 0 ?
            //     discountDataOfAll?.filter(
            //       offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(storeDetails?.id)
            //     ).length > 0 ? discountDataOfAll?.filter(
            //       offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(storeDetails?.id)
            //     ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'store' && offer?.apply_on === 'all') : [];
            // }
            // if (productOffers.length === 0) {
            //   productOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type').length > 0 ?
            //     discountDataOfAll?.filter(
            //       offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(storeDetails.businessTypeId)
            //     ).length > 0 ? discountDataOfAll?.filter(
            //       offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(storeDetails.businessTypeId)
            //     ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'all') : [];
            // }
            offers = [...productOffers];
          }
          product.offers = offers;
          
          product.uoms = product?.uoms;
          return product;
        }) : [];
      if (status === 1) {
        storeProducts = products;
        setStoreProducts(storeProducts);
        dispatch(changeLoader(false));
        setLoadmore(false)
        if (response?.products?.length > 0) {
          setTimeout(() => {
            setStoreCanLoad(true)
          }, 2000);
        } else {
          setStoreCanLoad(false)
        }
        checkExpanded()
      } else {
        storeProducts = [];
        setStoreProducts(storeProducts);
        dispatch(
          changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      }
    } else {
      dispatch(changeLoader(false));
    }
  })

  const loadMoreProduct = async () => {
    setStoreCanLoad(false)
    setLoadmore(true)
    page++;
    setPage(page);
    let categories = [];
    if (levelThreeCategory?.length > 0) {
      categories = levelThreeCategory;
    } else if (levelTwoCategory?.length > 0) {
      const selectedCategory = storeCategories.find(category => category.id === levelOneCategory);
      if (selectedCategory && selectedCategory?.category?.length > 0) {
        const cat2 = selectedCategory?.category.find(category => category.id === levelTwoCategory[0]);
        if (cat2 && cat2?.category?.length > 0) {
          categories = cat2?.category.map(category => category.id);
        } else {
          categories = levelTwoCategory;
        }
      } else {
        categories = levelTwoCategory;
      }
    } else {
      const selectedCategory = storeCategories.find(category => category.id === levelOneCategory);
      if (selectedCategory && selectedCategory?.category?.length > 0) {
        categories = selectedCategory.category.map(category => category.id);
      } else {
        categories = [levelOneCategory];
      }
    }
    let url = API_ROUTES.STOREFRONT.GET_PRODUCTS_BY_STORE_CATEGORY(storeslug);
    url += `?page=${page}&limit=${limit}&sort_by=${sortBy}&order_by=${orderBy}`;
    url += search !== '' ? `&search=${search}` : '';
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    if (search === '') {
      categories?.forEach(element => {
        url += `&category_id=${element}`;
      });
    }
    setTimeout(async () => {
      const response = await getRequest(url);
      const status = response?.ack;
      const products = response?.products.map((product) => {
        let offers = product?.discounts_and_offer_relations?.map((offer) => offer?.discounts_and_offer);
        if (offers?.length === 0) {
          const productOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'product').length > 0 ?
            discountDataOfAll?.filter(
              offer => offer?.apply_type === 'product' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.product_id).includes(product?.id)
            ).length > 0 ? discountDataOfAll?.filter(
              offer => offer?.apply_type === 'product' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.product_id).includes(product?.id)
            ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'product' && offer?.apply_on === 'all') : [];
          offers = [...productOffers];
        }
        product.offers = offers;
        product.uoms = product?.uoms;
        return product;
      });
      if (status === 1) {
        storeProducts?.push(...products);
        setStoreProducts(storeProducts);
        setLoadmore(false)
        if (response?.products?.length > 0) {
          setTimeout(() => {
            setStoreCanLoad(true)
          }, 2000);
        } else {
          setStoreCanLoad(false)
        }
      } else {
        dispatch(
          changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "error",
          })
        );
        setLoadmore(false)
      }
    }, 1000);
  }

  const getCartData = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId
    });
    setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
    dispatch(changeCart());
  }

  if (!loadmore && storeCanLoad && inViewport && scroll) {
    loadMoreProduct();
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);

    search = searchParams.get('s') ?
      searchParams.get('s') : '';
    setSearch(search);
    getStoreDetails();
    window.scrollTo(0, 0);
  }, [storeslug, userAddress])

  useEffect(() => {
    getCartData()
    getWishDate()
    // getUserToken()
    window.addEventListener('scroll', () => {
      setScroll(true)
    });
  }, [])

  const listenScrollEvent = (event) => {
    if (window.scrollY < 400) {
      return setFilterRow("d-flex")
    } else if (window.scrollY > 390) {
      return setFilterRow("d-flex fixed-bottom")
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  useEffect(() => {
    getOfferForCategory();
  }, [selectedCategory])


  return (
    <div>
      <div className="business-category-wrapper">
        <div className="container-body">
          {storeDetails ? (
            <Breadcrumb
              breadcrums={[{
                title: t("storefront_homepage"),
                haveLink: true,
                link: "/",
              }, {
                title:
                  storeDetails?.id === 1 ? t('storefront_AllCategories') :
                    storeDetails?.business_type?.business_type_locales[0].name,
                haveLink: true,
                link: storeDetails?.id === 1 ?
                  `/category/${storeDetails?.slug}` :
                  `/storefront/${storeDetails?.business_type?.slug}`,
              }, {
                title: storeDetails?.stores_locales[0].business_name,
                haveLink: false,
              },]}
            />
          ) : null}
          <StoreDetails
            searchQuery={search}
            storeDetails={storeDetails}
            searchData={(searchdata) => {
              handleSearch(searchdata);
            }}
          />
          <div className="categories-wrapper">
            <Grid container columnSpacing={{ xs: 0, sm: 4, md: 2, lg: 4 }}>
              {search === "" ? (
                <Grid item xs={12} sm={12} md={2.5} lg={2.5} className={click ? "category-div showing" : "category-div"} >
                  <StickyBox offsetTop={30} offsetBottom={30}>
                    {storeCategories?.length > 0 ? (
                      <>
                        <div className="categories-left-main-wrap"
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          <h4 className="h4">{t("storefront_categories")}</h4>
                          {storeCategories?.map((storeCategory, index) =>
                            storeLevel === 1 ? (
                              <div
                                className=""
                                key={`category-store-${index}`}
                                onClick={() => handleLevelOneCategory(storeCategory?.id)}
                              >
                                <span
                                  className={`p1-link ${storeCategory?.id === levelOneCategory ? `active` : ""
                                    }`}
                                >
                                  {getNameBasedOnLanguage(storeCategory?.category_locales)}
                                </span>
                              </div>
                            ) : storeLevel === 2 ? (
                              <div
                                className="collapse-categories-wrap"
                                key={`category-store-${index}`}
                              >
                                <div className="multi-left">
                                  <TreeView
                                    aria-label="file system navigator"
                                    expanded={expanded}
                                  // defaultCollapseIcon={<ExpandMoreIcon />}
                                  // defaultExpandIcon={<ChevronRightIcon />}
                                  >
                                    <TreeItem
                                      nodeId={storeCategory?.id}
                                      label={getNameBasedOnLanguage(storeCategory?.category_locales)}
                                      className="main-tree"
                                      onClick={() => {
                                        if (levelOneCategory !== storeCategory?.id) {
                                          getRemainingCategories(storeCategory?.id);
                                        }
                                      }}
                                    >
                                      {storeCategory?.category?.map((catlevel1) => (
                                        <FormGroup key={catlevel1?.slug}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                onClick={() => {
                                                  handleLevelTwoCategory(
                                                    storeCategory?.id,
                                                    catlevel1?.id
                                                  )
                                                }}
                                                checked={
                                                  levelTwoCategory.includes(catlevel1?.id)
                                                    ? true
                                                    : false
                                                }
                                              />
                                            }
                                            label={getNameBasedOnLanguage(catlevel1?.category_locales)}
                                          />
                                        </FormGroup>
                                      ))}

                                    </TreeItem>
                                  </TreeView>
                                </div>
                              </div>
                            ) : storeLevel === 3 ? (
                              <div
                                className="collapse-categories-wrap"
                                key={`category-store-${index}`}
                              >
                                <div className="multi-left">
                                  <TreeView
                                    aria-label="file system navigator"
                                    expanded={expanded}
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                  >
                                    <TreeItem
                                      nodeId={storeCategory?.id}
                                      label={getNameBasedOnLanguage(storeCategory?.category_locales)}
                                      className="main-tree"
                                      onClick={() => {
                                        if (levelOneCategory !== storeCategory?.id) {
                                          getRemainingCategories(storeCategory?.id);
                                        } else {
                                          removeLevelOneCategory();
                                        }
                                      }}
                                    >
                                      {storeCategory?.category?.map((catlevel1) => (
                                        <TreeItem
                                          nodeId={catlevel1?.id}
                                          label={getNameBasedOnLanguage(catlevel1?.category_locales)}
                                          key={catlevel1?.slug}
                                          onClick={() => {
                                            if (!levelTwoCategory?.includes(catlevel1?.id)) {
                                              levelTwoCategory = [];
                                              handleLevelTwoCategory(
                                                storeCategory?.id,
                                                catlevel1?.id
                                              );
                                            } else {
                                              removeLevelTwoCategory(catlevel1?.id);
                                            }
                                          }}
                                        >
                                          {catlevel1?.category?.map((catlevel2) => (
                                            <FormGroup key={catlevel2?.slug}>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    onClick={() => {
                                                      handleLevelThreeCategory(
                                                        catlevel1?.id,
                                                        catlevel2?.id
                                                      )
                                                    }}
                                                    checked={
                                                      levelThreeCategory?.includes(catlevel2?.id)
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                }
                                                label={getNameBasedOnLanguage(catlevel2?.category_locales)}
                                              />
                                            </FormGroup>
                                          ))}
                                        </TreeItem>
                                      ))}
                                    </TreeItem>
                                  </TreeView>
                                </div>
                              </div>
                            ) : null
                          )}
                        </div>
                        <div className="mobile-black-bg" onClick={handleClickMenu}></div>
                      </>
                    ) : null}
                  </StickyBox>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} md={search === "" ? 9.5 : 10} margin={"auto"}>
                {storeProducts?.length > 0 ? (
                  <div className="categories-right-main-wrap">
                    {search === "" ? (
                      <div className="right-head-box">
                        <h4 className="h4 detail-chips-parent">
                          <span className="ml-1">{selectedCategory}</span>
                          <div className="chips-row">
                            {categoryOfferApplied?.map((offer, index) =>
                              <span className="chips" style={{ fontSize: '12px', padding: '4px 5px' }} key={`offerstoreDetails-${index}`} >{offer.name}</span>)}
                          </div>
                        </h4>
                        <div className={filterRow} >
                          <div className="filter-box" onClick={handleClickMenu}>
                            <img src={filterIcon} className="filter-icon" alt="filter" />
                            filter
                          </div>
                          <Boxed className='sortby-dropdown'>
                            <img src={sortbyIcon} className="sortby-icon" alt="sortby" />
                            <FormControl fullWidth size="small">
                              <Select
                                id="demo-select-small"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                value={sortBy}
                                onChange={handleSortBy}
                              >
                                {/* <MenuItem className="border-bottom" value={''}>{t('storefront_sortBy')}</MenuItem> */}
                                <MenuItem className="border-bottom" value={'latest'}>{t('storefront_Relevance')}</MenuItem>
                                <MenuItem className="border-bottom" value={'atoz'}>{t('storefront_aToz')}</MenuItem>
                                <MenuItem className="border-bottom" value={'ztoa'}>{t('storefront_zToa')}</MenuItem>
                                <MenuItem className="border-bottom" value={'lowtohigh'}>{t('storefront_lowToHigh')}</MenuItem>
                                <MenuItem className="border-bottom" value={'hightolow'}>{t('storefront_highToLow')}</MenuItem>
                              </Select>
                            </FormControl>
                          </Boxed>
                        </div>
                      </div>
                    ) : (
                      <div className="right-head-box">
                        <h4 className="h4">
                          {" "}
                          {t("storefront_searchResultFor")} "{search}"
                        </h4>
                        <div className="d-flex">
                          <div className="filter-box" onClick={handleClickMenu}>
                            <img src={filterIcon} className="filter-icon" alt="filter" />
                            filter
                          </div>
                          <Boxed className='sortby-dropdown'>
                            <img src={sortbyIcon} className="sortby-icon" alt="sortby" />
                            <FormControl fullWidth size="small">
                              <Select
                                id="demo-select-small"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                value={sortBy}
                                onChange={handleSortBy}
                              >
                                {/* <MenuItem className="border-bottom" value={''}>{t('storefront_sortBy')}</MenuItem> */}
                                <MenuItem className="border-bottom" value={'latest'}>{t('storefront_Relevance')}</MenuItem>
                                <MenuItem className="border-bottom" value={'atoz'}>{t('storefront_aToz')}</MenuItem>
                                <MenuItem className="border-bottom" value={'ztoa'}>{t('storefront_zToa')}</MenuItem>
                                <MenuItem className="border-bottom" value={'lowtohigh'}>{t('storefront_lowToHigh')}</MenuItem>
                                <MenuItem className="border-bottom" value={'hightolow'}>{t('storefront_highToLow')}</MenuItem>
                              </Select>
                            </FormControl>
                          </Boxed>
                        </div>
                      </div>
                    )}
                    <div className="products-block">
                      {storeProducts?.map((product, index) => (
                        product?.uoms?.length > 0 ? <ProductBox
                          key={`product-${index}`}
                          storeId={storeDetails?.id}
                          storeSlug={storeDetails?.slug}
                          online={storeDetails?.online_status === 1}
                          product={product}
                          index2={index}
                          category_level={storeDetails?.business_type?.category_level}
                          selectedCategories={selectedCategories}
                        /> : null
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="right-head-box">
                      <div className="d-flex">
                        <div className="filter-box" onClick={handleClickMenu}>
                          <img src={filterIcon} className="filter-icon" alt="filter" />
                          filter
                        </div>
                        <Boxed className='sortby-dropdown'>
                          <img src={sortbyIcon} className="sortby-icon" alt="sortby" />
                          <FormControl fullWidth size="small">
                            <Select
                              id="demo-select-small"
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              value={sortBy}
                              onChange={handleSortBy}
                            >
                              {/* <MenuItem className="border-bottom" value={''}>{t('storefront_sortBy')}</MenuItem> */}
                              <MenuItem className="border-bottom" value={'latest'}>{t('storefront_Relevance')}</MenuItem>
                              <MenuItem className="border-bottom" value={'atoz'}>{t('storefront_aToz')}</MenuItem>
                              <MenuItem className="border-bottom" value={'ztoa'}>{t('storefront_zToa')}</MenuItem>
                              <MenuItem className="border-bottom" value={'lowtohigh'}>{t('storefront_lowToHigh')}</MenuItem>
                              <MenuItem className="border-bottom" value={'hightolow'}>{t('storefront_highToLow')}</MenuItem>
                            </Select>
                          </FormControl>
                        </Boxed>
                      </div>
                    </div>
                    <NoDataFound text={t("storefront_NoProductsFound")} />
                  </>
                )}
                <div className="loader-wrap" ref={ref}>
                  <ClipLoader color={"#EF4930"} loading={loadmore} speedMultiplier={0.5} />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BusinessCategory;
