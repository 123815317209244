/**
 * @file OTPVerificationForgot.js
 * @description OTP Verification Dialog Box
 * @exports OTPVerificationForgot - Dialog Box
 * @exports handleClose - Function to close the dialog box
 * @exports openPasswordUpdate - Function to open the password update dialog box
 * @imports forgotUname - email or phone number OTP was sent to
 */

import { useState, useEffect } from 'react';
import { Grid, Dialog, DialogContent, Button, FormControl } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input'
import modalClose from "../assets/images/structure/close-black.svg"
import { postRequest } from '../Helpers/Axios/Services'
import { API_ROUTES } from '../Helpers/Axios/ApiRoutes'
import { axiosConstant } from '../Helpers/Axios/Constants'
import { useDispatch } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { useTranslation } from "react-i18next";

const OTPVerificationForgot = ({ forgotUname, countryCode, handleClose, openPasswordUpdate }) => {

  const { t } = useTranslation();
  let [timeLeft, setTimeLeft] = useState(31);
  const [isTimer, setIsTimer] = useState(true);
  const [otp, setOtp] = useState('')
  const [otpError, setOtpError] = useState('')
  const [otpSuccess, setOtpSuccess] = useState('')
  let [isEmail, setIsEmail] = useState(true)
  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    setOtp(newValue)
  }

  const updateTimer = () => {
    if (timeLeft > 1) {
      timeLeft--;
      setTimeLeft(timeLeft)
      setTimeout(() => { updateTimer(); }, 1000);
    } else {
      setIsTimer(false);
    }
  }

  const handleResend = async () => {
    dispatch(changeLoader(true));
    setOtp('');
    const response = await postRequest(API_ROUTES.AUTHENTICATION.ForgotPasswordSendOtp, {
      uname: forgotUname,
      country_code: countryCode,
      role: axiosConstant.ROLE
    })
    dispatch(changeLoader(false));
    if (response.ack === 1) {
      timeLeft = 31;
      setTimeLeft(timeLeft);
      setIsTimer(true);
      updateTimer();
      setOtpSuccess(response.msg);
      setOtpError('')
      setTimeout(() => { setOtpSuccess(''); }, 6000);
    } else {
      setOtpError(response.msg)
      setTimeout(() => { setOtpError(''); }, 6000);
    }
  }

  const checkForgotValue = async () => {
    if (isNaN(forgotUname)) {
      isEmail = true;
      setIsEmail(isEmail);
    } else {
      isEmail = false;
      setIsEmail(isEmail);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(changeLoader(true));
    if (otp === '') {
      setOtpError(t('EnterValidOTP'));
      dispatch(changeLoader(false));
    } else {
      const data = {
        uname: forgotUname,
        country_code: countryCode,
        role: axiosConstant.ROLE,
        otp: otp
      };
      const response = await postRequest(API_ROUTES.AUTHENTICATION.ForgotPasswordVerifyOtp, data);
      dispatch(changeLoader(false));
      if (response.ack === 1) {
        openPasswordUpdate(response);
      } else {
        setOtpError(response.msg);
      }
    }
  }

  useEffect(() => {
    updateTimer();
    checkForgotValue()
  }, [])

  
  return (
    <Dialog open={true} onClose={handleClose} className="main-modal">
      <img src={modalClose} alt="close" onClick={handleClose} className='modal-close' />
      <DialogContent>
        <h2 className='h3 text-center'>{t('otpVerification')}</h2>
        {isEmail ?
          <p className='p3 text-center'>
            {t('enterThe4DigitOTPWeHaveSentViaEmailTo')} <span style={{ fontFamily: 'SFPRODISPLAYMEDIUM' }}>{forgotUname}</span></p> :
          <p className='p3 text-center'>
            {t('enterThe4DigitOTPWeHaveSentViaSMSTo')} <span style={{ fontFamily: 'SFPRODISPLAYMEDIUM' }}>+{countryCode} - {forgotUname}</span></p>
        }
        <form onSubmit={handleSubmit} action="#" method='post'>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <FormControl variant="outlined">
                <MuiOtpInput value={otp} onChange={handleChange} />
                <span className="errorspan">{otpError}</span>
                <span className="successspan">{otpSuccess}</span>
              </FormControl>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button type='submit' variant="contained" size="large" sx={{ width: 1, marginTop: '16px' }}>{t('verify')}</Button>
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
              {!isTimer ? <span className=''>{t('didntReceivedOTP')} <span className={isTimer ? 'login-otp no-event' : 'login-otp'} onClick={() => handleResend()}>{t('resend')}</span></span> : null}
              {isTimer ? <span className=''>00:{(timeLeft < 10) ? `0${timeLeft}` : `${timeLeft}`}</span> : null}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
};

export default OTPVerificationForgot;