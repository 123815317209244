import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    RadioGroup, FormControlLabel, Radio, Divider
  } from "@mui/material";
  import { getNameBasedOnLanguage, displayPrice } from '../../Helpers/HelperFunctions';

const RadioComponent = ({
	addon,
	selectedAddons,
	changeValue,
	removeValue
}) => {

    const { t } = useTranslation();
	let [minimumItem, setMinimumItem] = useState(0);
	let [maximumItem, setMaximumItem] = useState(0);
	let [selectedItems, setSelectedItems] = useState([]);
	
	useEffect(() => {
		if (addon) {
			setMinimumItem(addon?.minimum_item);
			setMaximumItem(addon?.maximum_item);
		}
	}, [addon]);

	useEffect(() => {
		selectedItems = selectedAddons.filter((obj) => obj?.addonId === addon?.id).map((obj) => parseInt(obj?.addonItemId));
		setSelectedItems(selectedItems);
	}, [selectedAddons]);
    
    return (
       <>
      	<div className="flex-center-between">
      	  <div className="relative">
      	    <h4 className="h4 capitalize">{getNameBasedOnLanguage(addon?.addons_locales)}</h4>
						{minimumItem > 0?
							addon?.maximum_item > 0?
								<p className="p4">{t('storefront_SelectBetween')} {addon?.minimum_item} - {addon?.maximum_item} {t('storefront_options')}</p>:
								<p className="p4">{t('storefront_SelectMinimum')} {addon?.minimum_item} {t('storefront_options')}</p>
							:addon?.maximum_item > 0?
								<p className="p4">{t('storefront_SelectUpTo')} {addon?.maximum_item} {t('storefront_options')}</p>:
								null
						}
      	  </div>
					{minimumItem > 0?<span className="badges">{t("Required")}</span>: null}
      	</div>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            className="mb-4"
						onChange={(e) => {
							changeValue(e.target.value, addon?.id)
						}}
						value={selectedItems}
          >
						{addon?.addon_items?.length > 0 ? addon?.addon_items?.map((obj, ind) =>
							<div className="flex-center-between"  key={`addon-items-radio-${ind}`}>
								<FormControlLabel
									value={obj?.id}
									control={<Radio
										onClick={(e) => {
											if (selectedItems.includes(obj?.id)) {
												removeValue(e.target.value, addon?.id)
											}
										}}	
									/>}
									className="capitalize"
									label={getNameBasedOnLanguage(obj?.addon_items_locales)}
								/>
								{obj?.price > 0?
									<h4 className="h4">{t('storefront_QAR')} {displayPrice(obj?.price)}</h4>:
									<h4 className="h4">{t('storefront_Free')}</h4>
								}
							</div>
            ): null}

          </RadioGroup>
					<Divider className="my-4" />
       </>
    );
};

export default RadioComponent;