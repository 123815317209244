/**
 * @file Forgot.js
 * @description Forgot Password Dialog Box
 * @exports Forgot - Dialog Box
 * @exports handleClose - Function to close the dialog box
 * @exports openVerifyForgot - Function to open login dialog box
 * @imports identity - email or phone number to send the OTP
 */

import { useState, useEffect } from 'react';
import {
  Grid, Dialog, DialogContent, Button, TextField, InputAdornment, FormControl
} from '@mui/material';
import modalClose from "../assets/images/structure/close-black.svg"
import { postRequest } from '../Helpers/Axios/Services'
import { API_ROUTES } from '../Helpers/Axios/ApiRoutes'
import { axiosConstant } from '../Helpers/Axios/Constants'
import { useDispatch } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { PhoneMask } from '../global-modules/MaskInputs/MaskedInputs';
import { validateEmail } from '../Helpers/HelperFunctions'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Forgot = ({ identity, handleClose, openVerifyForgot, countryCode }) => {

  const { t } = useTranslation();
  let [forgotUName, setForgotUName] = useState('');
  let [country_code, setCountryCode] = useState(countryCode);
  const [forgotUNameError, setForgotUNameError] = useState('');
  const [isPhone, setPhone] = useState(false);
  const [isNotRobot, setIsNotRobot] = useState(false);
  let [isNotRobotError, setIsNotRobotError] = useState('');
  const dispatch = useDispatch();

  const onChangeReCAPTCHA = (value) => {
    if (value !== '') {
      setIsNotRobot(true);
      setIsNotRobotError('');
    }
  }

  const onExpiredReCAPTCHA = () => {
    setIsNotRobot(false);
    setIsNotRobotError('');
  };

  const handleSubmit = async (e) => {
    dispatch(changeLoader(true));
    e.preventDefault();
    let isValid = true;
    if (forgotUName === '') {
      setForgotUNameError(t('EnterEmailOrPhone'));
      isValid = false;
    }
    if (!isNotRobot) {
      setIsNotRobotError(t('isNotRobot'))
      isValid = false;
    }
    if (isValid) {
      const response = await postRequest(API_ROUTES.AUTHENTICATION.ForgotPasswordSendOtp, {
        uname: forgotUName.slice(country_code.length),
        country_code: country_code,
        role: axiosConstant.ROLE
      })
      dispatch(changeLoader(false));
      if (response.ack === 1) {
        openVerifyForgot({
          uname: forgotUName.slice(country_code.length),
          country_code: country_code,
        });
      } else {
        if (response?.msg && response?.msg !== '') {
          setForgotUNameError(response?.msg);
        } else if (response?.errMsg) {
          response?.errMsg.forEach(element => {
            for (const [key, value] of Object.entries(element)) {
              setForgotUNameError(value);
            }
          });
        }
      }
    } else {
      dispatch(changeLoader(false));
    }
  }

  const checkChangeIdentity = async (e) => {
    setForgotUName(e.target.value);
    setForgotUNameError('');
    if (e.target.value.length > 3 && !isNaN(e.target.value)) {
      setPhone(true);
      document.getElementById('identity').focus();
    } else {
      setPhone(false);
      setTimeout(() => { document.getElementById('identity').focus(); }, 100);
    }
  }

  const handlePropSet = () => {
    forgotUName = identity;
    setForgotUName(forgotUName);
    if (forgotUName > 3 && !isNaN(forgotUName)) {
      setPhone(true);
    }
  }

  useEffect(() => {
    handlePropSet()
  }, [])

  return (
    <>
      <Dialog open={true} onClose={handleClose} className="main-modal">
        <img src={modalClose} alt="close" onClick={handleClose} className='modal-close' />
        <DialogContent>
          <h2 className='h3 text-center'>{t('forgotPassword')}</h2>
          <p className='p3 text-center'>{t('enterYourRegisteredEmailOrMobileWellSendYouResetPasswordOTP')}</p>
          <form onSubmit={handleSubmit} action="#" method='post'>
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <FormControl variant="outlined">
                <PhoneInput
                  className="phone-input"
                  country={'qa'}
                  value={forgotUName}
                  enableLongNumbers={true}
                  onKeyDown={(e) => {
                    if ((forgotUName.length - country_code.length) >= 16 && e.keyCode !== 8) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(value, data) => {
                    setForgotUName(value);
                    setCountryCode(data?.dialCode);
                  }}
                />
                  {/* <TextField
                    id="identity"
                    label={t('emailOrMobileNo') + '*'}
                    value={forgotUName} onChange={checkChangeIdentity}
                    InputProps={isPhone ? {
                      startAdornment: <InputAdornment position="start">+{axiosConstant.COUNTRY_CODE}</InputAdornment>,
                      inputComponent: PhoneMask
                    } : null}
                  /> */}
                  <span className="errorspan">{forgotUNameError}</span>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ position: 'relative' }}>
                <ReCAPTCHA
                  className="recaptcha"
                  sitekey={axiosConstant.GOOGLE_RECAPTCHA_SITE_KEY}
                  onChange={onChangeReCAPTCHA}
                  onExpired={onExpiredReCAPTCHA}
                  onErrored={onExpiredReCAPTCHA}
                />
                <span className="errorspan bottom-18">{isNotRobotError}</span>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button type='submit' variant="contained" size="large" sx={{ width: 1, marginTop: '16px' }}>{t('send')}</Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
};

export default Forgot;