/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull browsersdk'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/baladiexpress/Baladi%20Express/implementation/browsersdk)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'default'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} default
 */
export const ApiKey = {
  default: 'f366580ce541d9e6f8a3a2af57953951'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'browsersdk',
    versionId: '1ef27c2f-42c6-4dba-a72d-1cf4489a91a8'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export class AddressAddded {
  constructor() {
    this.event_type = 'address_addded';
  }
}

export class CartCreated {
  constructor(properties) {
    this.event_type = 'CartCreated';
    this.event_properties = properties;
  }
}

export class CartPageViewed {
  constructor() {
    this.event_type = 'cart_page_viewed';
  }
}

export class CheckoutCompleted {
  constructor(properties) {
    this.event_type = 'CheckoutCompleted';
    this.event_properties = properties;
  }
}

export class FiltersApplied {
  constructor(properties) {
    this.event_type = 'FiltersApplied';
    this.event_properties = properties;
  }
}

export class HomepageViewed {
  constructor() {
    this.event_type = 'homepage_viewed';
  }
}

export class LocationChanged {
  constructor(properties) {
    this.event_type = 'LocationChanged';
    this.event_properties = properties;
  }
}

export class MenuCategoryViewed {
  constructor() {
    this.event_type = 'menu_category_viewed';
  }
}

export class LoggedIn {
  constructor(properties) {
    this.event_type = 'LoggedIn';
    this.event_properties = properties;
  }
}

export class LoggedOut {
  constructor(properties) {
    this.event_type = 'LoggedOut';
    this.event_properties = properties;
  }
}

export class OrderCancelled {
  constructor(properties) {
    this.event_type = 'OrderCancelled';
    this.event_properties = properties;
  }
}

export class OrderDelivered {
  constructor(properties) {
    this.event_type = 'OrderDelivered';
    this.event_properties = properties;
  }
}

export class OrderInfoPageViewed {
  constructor(properties) {
    this.event_type = 'OrderInfoPageViewed';
    this.event_properties = properties;
  }
}

export class OrderRated {
  constructor(properties) {
    this.event_type = 'OrderRated';
    this.event_properties = properties;
  }
}

export class PaymentInitiated {
  constructor(properties) {
    this.event_type = 'PaymentInitiated';
    this.event_properties = properties;
  }
}

export class ProductPageViewed {
  constructor() {
    this.event_type = 'product_page_viewed';
    }
}

export class ProductPurchased {
  constructor() {
    this.event_type = 'product_purchased';
  }
}

export class SearchMade {
  constructor() {
    this.event_type = 'search_made';
  }
}

export class StoreDetailPageViewed {
  constructor(properties) {
    this.event_type = 'StoreDetailPageViewed';
    this.event_properties = properties;
  }
}

export class SignedUp {
  constructor() {
    this.event_type = 'user_signed_up';
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user.
   *
   * @param {string|undefined} userId The user's id.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * CartCreated
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/CartCreated)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {number} [properties.product_id] Property has no description in tracking plan.
   * @param {number} [properties.store_id] Property has no description in tracking plan.
   * @param {string} [properties.uom_name] Property has no description in tracking plan.
   * @param {number} [properties.user_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  cartCreated(properties, options) {
    return this.track(new CartCreated(properties), options);
  }


    /**
   * address_addded
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/address_addded)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
addressAddded(options) {
  return this.track(new AddressAddded(), options);
}


    /**
   * cart_page_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/cart_page_viewed)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
cartPageViewed(options) {
  return this.track(new CartPageViewed(), options);
}
  /**
   * CheckoutCompleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/CheckoutCompleted)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.category] Property has no description in tracking plan.
   * @param {string} [properties.description] Property has no description in tracking plan.
   * @param {string} [properties.name] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  checkoutCompleted(properties, options) {
    return this.track(new CheckoutCompleted(properties), options);
  }

  /**
   * FiltersApplied
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/FiltersApplied)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.filter] Property has no description in tracking plan.
   * @param {number} [properties.user_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  filtersApplied(properties, options) {
    return this.track(new FiltersApplied(properties), options);
  }

    /**
   * homepage_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/homepage_viewed)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
homepageViewed(options) {
  return this.track(new HomepageViewed(), options);
}


  /**
   * LocationChanged
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/LocationChanged)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.address] Property has no description in tracking plan.
   * @param {number} [properties.latitude] Property has no description in tracking plan.
   * @param {number} [properties.longitude] Property has no description in tracking plan.
   * @param {number} [properties.user_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  locationChanged(properties, options) {
    return this.track(new LocationChanged(properties), options);
  }

   /**
   * menu_category_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/menu_category_viewed)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
menuCategoryViewed(options) {
  return this.track(new MenuCategoryViewed(), options);
}


  /**
   * LoggedIn
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/LoggedIn)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.contact_number] Property has no description in tracking plan.
   * @param {string} [properties.country_code] Property has no description in tracking plan.
   * @param {string} [properties.deviceId] Property has no description in tracking plan.
   * @param {number} [properties.user_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  loggedIn(properties, options) {
    return this.track(new LoggedIn(properties), options);
  }

  /**
   * LoggedOut
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/LoggedOut)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.contact_number] Property has no description in tracking plan.
   * @param {number} [properties.user_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  loggedOut(properties, options) {
    return this.track(new LoggedOut(properties), options);
  }

  /**
   * OrderCancelled
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/OrderCancelled)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.order_id] Property has no description in tracking plan.
   * @param {number} [properties.total_amount] Property has no description in tracking plan.
   * @param {number} [properties.user_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  orderCancelled(properties, options) {
    return this.track(new OrderCancelled(properties), options);
  }

  /**
   * OrderDelivered
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/OrderDelivered)
   *
   * Owner: Abdul Qadir
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.order_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  orderDelivered(properties, options) {
    return this.track(new OrderDelivered(properties), options);
  }

  /**
   * OrderInfoPageViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/OrderInfoPageViewed)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.order_id] Property has no description in tracking plan.
   * @param {number} [properties.total_amount] Property has no description in tracking plan.
   * @param {number} [properties.user_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  orderInfoPageViewed(properties, options) {
    return this.track(new OrderInfoPageViewed(properties), options);
  }

  /**
   * OrderRated
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/OrderRated)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.order_id] Property has no description in tracking plan.
   * @param {number} [properties.rating] Property has no description in tracking plan.
   * @param {string} [properties.rating_for] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  orderRated(properties, options) {
    return this.track(new OrderRated(properties), options);
  }

  /**
   * PaymentInitiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/PaymentInitiated)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {number} [properties.cart_id] Property has no description in tracking plan.
   * @param {string[]} [properties.product_ids] Property has no description in tracking plan.
   * @param {number} [properties.store_id] Property has no description in tracking plan.
   * @param {number} [properties.total_amount] Property has no description in tracking plan.
   * @param {number} [properties.user_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productPageViewed(options) {
    return this.track(new ProductPageViewed(), options);
  }

  /**
   * ProductUpdate
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/ProductUpdate)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {*} [properties.action] Property has no description in tracking plan.
   * @param {number} [properties.cart_id] Property has no description in tracking plan.
   * @param {number} [properties.user_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productUpdate(properties, options) {
    return this.track(new ProductUpdate(properties), options);
  }

  /**
   * SignedUp
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/SignedUp)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.contact_number] Property has no description in tracking plan.
   * @param {string} [properties.country_code] Property has no description in tracking plan.
   * @param {string} [properties.deviceId] Property has no description in tracking plan.
   * @param {string} [properties.email] Property has no description in tracking plan.
   * @param {string} [properties.full_name] Property has no description in tracking plan.
   * @param {number} [properties.user_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  signedUp(properties, options) {
    return this.track(new SignedUp(properties), options);
  }

   /**
   * search_made
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/search_made)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
searchMade(options) {
  return this.track(new SearchMade(), options);
}

  /**
   * StoreDetailPageViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/StoreDetailPageViewed)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {number} [properties.store_id] Property has no description in tracking plan.
   * @param {string} [properties.store_name] Property has no description in tracking plan.
   * @param {number} [properties.user_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  storeDetailPageViewed(properties, options) {
    return this.track(new StoreDetailPageViewed(properties), options);
  }

  /**
   * user_signed_up
   *
   * [View in Tracking Plan](https://data.amplitude.com/baladiexpress/Baladi%20Express/events/main/latest/user_signed_up)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  userSignedUp(options) {
    return this.track(new UserSignedUp(), options);
  }
}

export const ampli = new Ampli();
