/** 
 * @File Name: CardPage.js
 * @description: This file is used to display the cart page
*/
import "./CardCheckout.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid, Button, TextField, InputAdornment } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../layout/footer/Footer";
import heartMove from "../../assets/images/structure/heart-move.svg";
import cardEmpty from "../../assets/images/structure/empty-cart.webp";
import couponIcon from "../../assets/images/structure/coupon.png";
import youSavedIcon from "../../assets/images/structure/you-saved-icon.webp";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import AddressBox from "./AddressBox";
import RefreshCart from "../../DialogBox/RefreshCart";
import StareDetailBox from "./StareDetailBox";
import MoveToWishlist from "../../DialogBox/MoveToWishlist";
import jwt_decode from "jwt-decode";
import { getRequest, postRequest, EstimateDeliveryFee } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeCart, currentCart, currentOrderSummery, currentCartStore, currentCartCount, currentCartCoupon, currentCartProductIds, currentCartUomIds } from '../../redux/reducers/cart';
import { changeLoader } from "../../redux/reducers/loader";
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import { displayPrice } from '../../Helpers/HelperFunctions';
import { currentAddress, changeAddress } from '../../redux/reducers/address';
import AddressList from "../../DialogBox/Address/AddressList";
import AddAddress from "../../DialogBox/Address/AddAddress";
import CartItem from "./CartItem";
import SpecialRequestPopup from "../../DialogBox/SpecialRequestPopup";
import { max } from "moment";
import { ampli } from "../../../src/ampli";
import { currentBaladiData } from "../../redux/reducers/baladiData";

const CardPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbar = useSelector(currentSnackbar);
  let cartData = useSelector(currentCart);
  const baladiData = useSelector(currentBaladiData);
  let cartSummery = useSelector(currentOrderSummery);
  const cartCount = useSelector(currentCartCount)
  const cartCoupon = useSelector(currentCartCoupon)
  let cartStore = useSelector(currentCartStore);
  let addressDefault = useSelector(currentAddress);
  let [minimumOrderDelivery, setMinimumOrderDelivery] = useState(0);
  const [openMoveToWishlist, setOpenMoveToWishlist] = useState(false);
  let [userToken, setUserToken] = useState(null);
  let [cartRemoveId, setCartRemoveId] = useState(null);
  let [isLoggedIn, setIsLoggedIn] = useState(false);
  let [openAddAddress, setOpenAddAddress] = useState(false);
  let [openAddressList, setOpenAddressList] = useState(false);
  let [deliveryCharge, setDeliveryCharge] = useState(0);
  let [openRefreshCart, setOpenRefreshCart] = useState(false);
  let [userData, setUserData] = useState(null);
  let [editNotePopup, setEditNotePopup] = useState(false);
  let [editNoteData, setEditNoteData] = useState({ type: "", noteId: "" });
  let [couponCode, setCouponCode] = useState('');
  let cartProductIds = useSelector(currentCartProductIds);

  const handleOpenNotePopup = (type, noteId) => {
    editNoteData = { type: type, noteId: noteId, };
    setEditNoteData(editNoteData);
    setEditNotePopup(true);
  }

  const headlogin = () => {
    document.getElementById("headlogin").click();
  }

  const handleMoveCartToWishlist = async () => {
    dispatch(changeLoader(true));
    const { ack, msg } = await postRequest(API_ROUTES.CART.MOVE_CART_TO_WISHLIST);
    dispatch(changeLoader(false));
    if (ack === 1) {
      getCartData();
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "success",
      }));
    } else {
      getCartData();
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "error",
      }));
    }
  }
  useEffect(() => {
    let titlesArray = [];
    cartData?.cart_items?.map((item, index) => {
      item?.product?.products_locales.map((i) => {
        titlesArray.push(i?.title);
      })
    })
    let productArr = []
    cartData?.cart_items?.map(item => productArr.push(item.productId))
    //ampli===
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    ampli.track({
      event_type: 'CartPageViewed',
      event_properties: {
        category: 'Docs',
        name: 'SDK Library',
        description: 'SDK Library Description',
        user_id: userID ? userID : 0,
        store_id: cartData.storeId,
        cart_id: cartData.id,
        product_names: cartData ? titlesArray : 0,
        product_ids: cartData ? productArr : 0,
        total_amount: cartSummery.totalAmount,
      },
    })
  }, [])

  const checkout = async () => {
    if (addressDefault?.id !== 0) {
      let userID = '';
      if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
        const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
        let decoded = jwt_decode(token);
        userID = decoded?.id;
      }
      const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
      let url = API_ROUTES.CART.GET_CART_DATA;
      url += `?latitude=${addressDefault?.latitude}&longitude=${addressDefault?.longitude}`
      const cartResponse = await postRequest(url, {
        userId: userID,
        deviceId: deviceId
      });
      setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
      dispatch(changeCart());
      if (!cartResponse?.data) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t('cart_cartIsEmpty'),
          state: "error",
        }));
        return false;
      }
      let stockAvailable = true;
      let unAvailableProducts = [];
      let unAvailableAddons = [];
      let changedAddons = [];
      let inactiveProducts = [];
      let minimumQuantity = [];
      let maximumQuantity = [];
      let unavailableStockQuantity = false;
      let invalidCode = false;
      let isProductInactive = false;
      let unavailablesName = [];
      // let categoryProductUnavailable = [];
      if (cartResponse?.couponResult?.invalidCode) {
        invalidCode = true;
      }
      cartResponse?.data?.cart_items.forEach(element => {
        // if (element.category && element.category[0].status === 'inactive') {
        //   categoryProductUnavailable.push(element.product.products_locales[0].title);
        // }
        if (element?.product && element?.product?.status === 'active') {
          const selected_uom = element.product?.uoms?.find(uom => uom.id === element.uomId);
          stockAvailable = element.product.brandId !== baladiData.BALADI_BRAND_Id ?
            selected_uom?.stock === 1 :
            element.product?.stockQuantity > 0;
          if (!stockAvailable) {
            unAvailableProducts.push(element.product.products_locales[0].title);
          }
          let addedQuantity = 0;
          const baseUom = element.product.uoms.find(uom => uom.isDefault === 1)
          cartResponse?.data?.cart_items.forEach(newItems => {
            if (element.productId === newItems.productId) {
              const uom = element.product.uoms.find(uom => uom.id === newItems.uomId)
              addedQuantity += newItems.quantity * uom.relationWithBase
            }
          });
          if (element?.product.brandId === baladiData.BALADI_BRAND_Id && (element.product?.stockQuantity - addedQuantity < 0)) {
            unavailableStockQuantity = true;
          }
          // if (element?.quantity > element.product?.stockQuantity) {
          //   unavailableStockQuantity = true
          // }
          let selectedAddons = [];
          if (element?.addonIds && element?.addonIds !== "") {
            if (selected_uom?.addons?.length > 0) {
              selectedAddons = element?.addonIds?.split(',');
              selected_uom?.addons.forEach(addon => {
                if (addon?.addon_items?.length > 0) {
                  addon?.addon_items?.forEach(addon_item => {
                    if (selectedAddons?.includes(addon_item?.id.toString())) {
                      const removeIndex = selectedAddons.indexOf(addon_item?.id.toString());
                      selectedAddons.splice(removeIndex, 1);
                    }
                  });
                }
              });
              unAvailableAddons = selectedAddons;
            } else {
              unAvailableAddons = element?.addonIds?.split(',');
            }
          }
          const hasStore = element?.product?.manage_store_products?.filter(store => store?.storeId === cartResponse?.data?.storeId);
          if (hasStore?.length > 0) {
            isProductInactive = true;
            unavailablesName.push(element?.product.products_locales[0].title);
          }
          let getUniqueAddonId = element?.addonitems?.map(addon => addon.addonId).filter((value, index, self) => self.indexOf(value) === index);
          selected_uom?.addons?.map(addon => {
            if (getUniqueAddonId?.includes(addon.id)) {
              const selectedAddons = element?.addonitems?.filter(addonItem => addonItem.addonId === addon.id);
              if (addon?.minimum_item > selectedAddons.length) {
                minimumQuantity.push(addon?.addons_locales[0]?.name)
              }
              if (addon?.maximum_item !== 0 && addon?.maximum_item < selectedAddons.length) {
                maximumQuantity.push(addon?.addons_locales[0]?.name)
              }
            }
          });
          let addOns = element?.addonitems;
          if (addOns?.length > 0) {
            addOns?.map(addon => {
              const oldAddon = element?.addonitems.find(addonItem => addonItem.id === addon.id);
              addon.change = oldAddon?.price !== addon.price;
              addon.oldPrice = oldAddon?.price;
              return addon;
            });
            const changedItems = addOns?.filter(addon => addon.change);
            if (changedItems?.length > 0) {
              changedAddons = [...changedAddons, ...changedItems];
            }
            const logUom = element?.itemLogs?.uomdata;
            if (selected_uom?.price !== logUom?.price || selected_uom.salePrice !== logUom.salePrice) {
              changedAddons?.push({
                ProductId: logUom?.productId, addon_items_locales: logUom?.uomName, change: true, id: logUom?.id,
                oldPrice: logUom?.price, price: logUom?.price, oldSalePrice: logUom?.salePrice, salePrice: logUom?.salePrice,
              });
            }
          }
        } else {
          inactiveProducts.push(element?.product ? element.product.products_locales[0].title : '');
        }
      });
      if (
        stockAvailable &&
        unAvailableAddons?.length === 0 &&
        inactiveProducts?.length === 0 &&
        changedAddons?.length === 0 &&
        minimumQuantity?.length === 0 &&
        maximumQuantity?.length === 0 &&
        // categoryProductUnavailable.length !== 0 &&
        !unavailableStockQuantity &&
        !invalidCode &&
        !isProductInactive
      ) {
        navigate('/checkout');
      } else {
        if (!stockAvailable) {
          let message = t('cart_Outofstock') + " " + unAvailableProducts.join(", ");
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: message,
            state: "error",
          }));
        } else if(isProductInactive) {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: t('cart_ProductsNotAvailable') + " " + unavailablesName.join(", "),
            state: "error",
          }));
        } else if (unAvailableAddons?.length > 0) {
          let unavailablesName = [];
          cartResponse?.data?.cart_items.forEach(element => {
            const unavailables = element?.addonitems.filter(addon => {
              if (unAvailableAddons?.includes(addon.id.toString())) {
                return addon?.addon_items_locales[0]?.name;
              }
            }).map(addon => addon?.addon_items_locales[0]?.name);
            unavailablesName = [...unavailablesName, ...unavailables];
          })

          let message = t('cart_AddonsNotAvailable') + " " + unavailablesName.join(", ");
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: message,
            state: "error",
          }));
        } else if (inactiveProducts?.length > 0) {
          let message = t('cart_ProductsNotAvailable') + " " + inactiveProducts.join(", ");
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: message,
            state: "error",
          }));
        } else if (changedAddons?.length > 0) {
          setOpenRefreshCart(true);
          dispatch(changeLoader(false));
        } else if (minimumQuantity?.length > 0 || maximumQuantity?.length > 0) {
          let message = '';
          if (minimumQuantity?.length > 0) {
            message = t('cart_MinimumQuantity') + " " + minimumQuantity.join(", ");
          }
          if (maximumQuantity?.length > 0) {
            message = message + " " + t('cart_MaximumQuantity') + " " + maximumQuantity.join(", ");
          }
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: message,
            state: "error",
          }));
        } else if (unavailableStockQuantity) {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: t('cart_QuantityNotAvailable'),
            state: "error",
          }));
        } else if (invalidCode) {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: t('cart_CouponCodeNotValid'),
            state: "error",
          }));
        } 
        // else if (categoryProductUnavailable.length > 0) {
        //   let message = t('cart_ProductsNotAvailable') + " " + categoryProductUnavailable.join(", ");
        //   dispatch(changeSnackbar({
        //     ...snackbar,
        //     isOpen: true,
        //     message: message,
        //     state: "error",
        //   }));
        // }
      }
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: t('cart_select_address'),
        state: "error",
      }));
    }
  }

  const getUserToken = async () => {
    const UserToken = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (UserToken) {
      userToken = UserToken;
      setUserToken(userToken);
      isLoggedIn = true;
      setIsLoggedIn(isLoggedIn);
      let decoded = jwt_decode(userToken);
      userData = decoded;
      setUserData(userData);
    } else {
      userToken = null;
      setUserToken(userToken);
    }
  };

  const handleDeliveryCharge = async () => {
    const deliveryResponse = await getRequest(API_ROUTES.CART.GET_DELIVERY_CHARGES(cartStore?.id));
    if (deliveryResponse?.ack === 1) {
      if (deliveryResponse?.delivery?.getFromTookan === 'yes') {
        getFairEstimate();
      } else {
        deliveryCharge = 0;
        minimumOrderDelivery = deliveryResponse?.delivery?.minimum_order_value
        setMinimumOrderDelivery(minimumOrderDelivery)
        if (deliveryResponse?.delivery?.minimum_order_value <= 0) {
          deliveryCharge = deliveryResponse?.delivery?.delivery_charges
        } else if (deliveryResponse?.delivery?.minimum_order_value > cartSummery?.totalAmount) {
          deliveryCharge = deliveryResponse?.delivery?.delivery_charges
        }
        setDeliveryCharge(deliveryCharge)
      }
    }
  }

  const getFairEstimate = async () => {
    deliveryCharge = 0;
    if (cartStore?.market_place === 0 || cartStore?.market_place === '0') {
      const coordinateData = {
        store_latitude: cartStore?.latitude,
        store_longitude: cartStore?.longitude,
        user_latitude: addressDefault?.latitude,
        user_longitude: addressDefault?.longitude,
      }
      const deliveryFee = await EstimateDeliveryFee(coordinateData);
      deliveryCharge = deliveryFee?.data?.total_fields['2'];
    }
    setDeliveryCharge(deliveryCharge)

  }

  const getCartData = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url += `?latitude=${addressDefault?.latitude}&longitude=${addressDefault?.longitude}`
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId
    });
    if (cartResponse) {
      setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
      dispatch(changeCart());
      handleDeliveryCharge();
    }
  }

  const getAndSetDefaultAddress = async () => {
    const response = await getRequest(API_ROUTES.ADDRESS.GET_ADDRESS);
    if (response.ack === 1) {
      dispatch(changeAddress());
    } else {
      dispatch(changeLoader(false));
    }
  }

  const applyCoupon = async () => {
    if (couponCode === undefined || couponCode.trim() === '') {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: 'Please enter coupon code',
        state: "error",
      }));
    } else {
      dispatch(changeLoader(true));
      const response = await postRequest(API_ROUTES.CART.APPLY_COUPON, {
        coupon_code: couponCode
      });
      if (response.ack === 1) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: 'Coupon applied successfully',
          state: "success",
        }));
        getCartData();
        dispatch(changeLoader(false));
      } else {
        if (response.errMsg.length > 0) {
          let message = '';
          response?.errMsg.forEach(element => {
            for (const [key, value] of Object.entries(element)) {
              message += value + ' \n';
            }
          });
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: message,
            state: "error",
          }));
        } else {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "error",
          }));
        }
        dispatch(changeLoader(false));
      }
    }
  }

  const removeCoupon = async (alet = 'yes') => {
    if ((isLoggedIn && cartSummery?.discount > 0) || alet === "yes") {
      dispatch(changeLoader(true));
      const response = await getRequest(API_ROUTES.CART.REMOVE_COUPON);
      if (response.ack === 1) {
        if (alet === 'yes') {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.message,
            state: "success",
          }));
        }
        couponCode = '';
        setCouponCode(couponCode);
        getCartData();
        dispatch(changeLoader(false));
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.message,
          state: "error",
        }));
        dispatch(changeLoader(false));
      }
    }
  }

  useEffect(() => {
    if (
      isLoggedIn && !getLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_ADDRESS)
    ) {
      getAndSetDefaultAddress();
    }
    getUserToken();
    getCartData();
    setInterval(() => {
      if (!isLoggedIn) {
        getUserToken();
      }
    }, 500);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, [])

  useEffect(() => {
    setCouponCode(cartCoupon?.coupon_code)
  }, [])

  useEffect(() => {
    handleDeliveryCharge();
  }, [cartData])

  useEffect(() => {
    if (cartSummery?.discountedAmount > 0 && couponCode !== '') {
      removeCoupon('no');
    }
  }, [cartSummery?.discountedAmount])

  return (
    <>
      <div>
        <div className="card-wrapper">
          <div className="container-body">
            {/* empty box start */}
            {cartCount === 0 ? (
              <div className="empty-box">
                <img src={cardEmpty} alt="..." className="empty-img" />
                <h4 className="h4">{t("cart_Yourcartisempty")}</h4>
                <p className="p2">{t("cart_ThereisnothinginyourbagLetsaddsomeproducts")}</p>
                <Link to={"/"} className="no-decoration">
                  <Button variant="contained" size="large">
                    {t("cart_ShopNow")}
                  </Button>
                </Link>
              </div>
            ) : (
              <Grid container columnSpacing={"30px"}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                  {userToken ? (
                    <AddressBox
                      userToken={userToken}
                      handleChangeAddress={() => setOpenAddressList(true)}
                    />
                  ) : null}
                  <Boxed className="my-product-header">
                    <h6 className="h6">{t("cart_MyProducts")}</h6>
                    <span
                      className="a-black"
                      onClick={() => {
                        setOpenMoveToWishlist(true);
                      }}
                    >
                      {userToken ? (
                        <>
                          <img src={heartMove} alt="..." className="icon20" />
                          {t("cart_Movealltowishlist")}
                        </>
                      ) : null}
                    </span>
                  </Boxed>
                  {cartData?.cart_items?.map((cartitem, index) => (
                    <CartItem
                      key={index}
                      cartitem={cartitem}
                      index={index}
                      userData={userData}
                      addSpecialRequest={(id, type) => {
                        handleOpenNotePopup(type, id)
                      }}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div className="checkout-box">

                    <div className="bg-white-round-border">
                      <h6 className="h6">{t("cart_OrderNote")}</h6>
                      {cartData?.specialRequest === '' ?
                        <div className="d-flex">
                          <p className="p2">{t("cart_OrderNoteText")}</p>
                          <span className="redspan" onClick={() => {
                            handleOpenNotePopup('order', cartData?.id)
                          }}>{t('cart_addHere')}</span>
                        </div> :
                        <div className="d-flex">
                          <p className="p2">{cartData?.specialRequest}</p>
                          <span className="redspan" onClick={() => {
                            handleOpenNotePopup('order', cartData?.id)
                          }}>{t('cart_edit')}</span>
                        </div>}
                    </div>

                    {
                    (!cartSummery?.discountedAmount || cartSummery?.discountedAmount === 0) && userToken && cartStore?.supportingCash === 'yes' ?
                    <div className="bg-white-round-border p-0">
                      <TextField
                        className={`w-100 remove-check-green`}
                        placeholder={t("cart_Enter_Coupon_Code")} variant="outlined"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        inputProps={{ maxLength: 20, }}
                        disabled={cartCoupon?.coupon_code}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">
                            <img src={couponIcon} alt="..." className="icon20" />
                          </InputAdornment>,
                        }}
                      />

                      {cartCoupon?.coupon_code ?
                        <Button
                          size="medium"
                          type="button"
                          className="apl-coupon-btn"
                          onClick={() => removeCoupon()}
                        >{t('cart_DELETE_coupon')}</Button> :

                        <Button
                          size="medium"
                          type="button"
                          className="apl-coupon-btn"
                          onClick={() => applyCoupon()}
                        >{t('cart_Apply_coupon')}</Button>}
                      {cartCoupon?.couponResult?.invalidCode ? <span className="redspan">{t("cart_CouponCodeNotValid")}</span> : null}
                    </div> : null}

                    <h6 className="h6">{t("cart_OrderSummary")}</h6>
                    <div className="d-flex">
                      <p className="p2">{t("cart_OrderTotal")}</p>
                      <p className="p2 latter-spacing1">
                        {t("QAR")} {displayPrice(cartSummery?.subtotal)}{" "}
                      </p>
                    </div>

                    {cartSummery?.discount && cartSummery?.discount > 0 ? <div className="d-flex">
                      <p className="p2">{t("cart_CouponDiscount")}</p>
                      <p className="p2 latter-spacing1">
                        - {t("QAR")} {displayPrice(cartSummery?.discount)}
                      </p>
                    </div> : null}
                    {cartSummery?.discountedAmount && cartSummery?.discountedAmount > 0 ? <div className="d-flex">
                      <p className="p2">{t("cart_DiscountOffer")}</p>
                      <p className="p2 latter-spacing1">
                        - {t("QAR")} {displayPrice(cartSummery?.discountedAmount)}
                      </p>
                    </div> : null}


                    {cartSummery?.tax > 0 ? <div className="d-flex ">
                      <p className="p2">{t("cart_Tax")}</p>
                      <p className="p2 latter-spacing1">
                        {t("QAR")} {displayPrice(cartSummery?.tax)}{" "}
                      </p>
                    </div> : null}
                    {deliveryCharge !== 0 ? (
                      <div className="d-flex mb-24">
                        <p className="p2">{t("cart_Deliverycharges")}</p>
                        <p className="p2 latter-spacing1">
                          {t("QAR")} {displayPrice(deliveryCharge)}{" "}
                        </p>
                      </div>
                    ) : null}
                    <Divider />
                    <div className="d-flex my-24-32">
                      <div className="relative">
                        <p className="p1">{t("cart_TotalAmount")}</p>
                        {/* <p className="p3">({t("cart_Taxincluded")})</p> */}
                      </div>
                      <p className="p1 font20 latter-spacing1">
                        {t("QAR")} {displayPrice(cartSummery?.totalAmount + deliveryCharge)}{" "}
                      </p>
                    </div>

                    {deliveryCharge !== 0?<div className="d-flex bg-alert-orrage">
                      {t('cart_forFreeDelivery', {
                        amount: displayPrice(minimumOrderDelivery)
                      })}
                    </div>: null}
                    
                    {userToken ? (
                      <Button variant="contained" size="large" onClick={() => checkout()} fullWidth>
                        {t("cart_Checkout")}
                      </Button>
                    ) : null}
                    {/* this is my offer code please don't remove code  */}
                    {/* <div className="bg-white-round-border mt-6">
                    <Boxed display={"flex"} alignItems={"start"}>
                      <div className="logo-white-box">
                        <img
                          src={youSavedIcon}
                          className="str-logo" alt="store-logo" />
                      </div>
                      <div className="relative" style={{ margin: '0px 12px' }}>
                        <p className="p2">{t("cart_Congratulations")}</p>
                        <p className="p3 p3">{t("cart_FLAT")}100 {t("cart_appliedSuccessfully")}.</p>
                        <p className="p3">{t("cart_CashbackOf")}<span className="bold">QAR 100.00</span> {t("cart_willBeCreditedToYourWalletOnceTheOrderIsSuccessfullyCompleted")}</p>
                      </div>
                    </Boxed>
                    </div> */}
                    {/* {cartSummery?.discountedAmount > 0 ? <div className="bg-white-round-border mt-6">
                      <Boxed display={"flex"} alignItems={"start"}>
                        <div className="logo-white-box">
                          <img
                            src={youSavedIcon}
                            className="str-logo" alt="store-logo" />
                        </div>
                        <div className="relative" style={{ margin: '0px 12px' }}>
                          <p className="p2">{t("cart_Congratulations")}</p>
                          <p className="p3">{t("cart_CashbackOf")}<span className="bold">{" "} QAR {displayPrice(cartSummery?.discountedAmount)}</span> {t("cart_willBeCreditedToYourWalletOnceTheOrderIsSuccessfullyCompleted")}</p>
                        </div>
                      </Boxed>
                    </div> : null} */}
                  </div>
                  {cartStore?.stores_locales ? <StareDetailBox cartStore={cartStore} /> : null}
                  {userToken ? null : (
                    <div div className="address-box">
                      <div className="relative">
                        <p className="p2 bold">{t("cart_LogInNow")}</p>
                        <p className="p2">{t("cart_Logintosuccessfullyplacetheorder")}</p>
                      </div>
                      <Button
                        type="button"
                        className="MuiButton-outlinedPrimary"
                        size="large"
                        onClick={() => headlogin()}
                      > {t("cart_Login")} </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </div>
        <Footer />
      </div>

      {openAddAddress ? (
        <AddAddress
          handleClose={() => {
            setOpenAddAddress(false);
            handleDeliveryCharge();
          }}
          addressSaved={() => {
            setOpenAddAddress(false);
            handleDeliveryCharge();
          }}
        />
      ) : null}
      {openMoveToWishlist ? (
        <MoveToWishlist
          CloseDialog={() => {
            setOpenMoveToWishlist(false);
          }}
          MoveCartToWishlist={() => {
            setOpenMoveToWishlist(false);
            handleMoveCartToWishlist();
          }}
        />
      ) : null}

      {openAddressList ? (
        <AddressList
          handleClose={() => {
            setOpenAddressList(false);
            handleDeliveryCharge();
          }}
          openAddAddress={() => {
            setOpenAddressList(false);
            setOpenAddAddress(true);
            handleDeliveryCharge();
          }}
        />
      ) : null}
      {openRefreshCart ?
        <RefreshCart
          userData={userData}
          closePopup={() => setOpenRefreshCart(false)}
          goToCheckOut={() => {
            setOpenRefreshCart(false);
          }}
        /> : null}
      {editNotePopup ?
        <SpecialRequestPopup
          type={editNoteData.type}
          noteId={editNoteData.noteId}
          handleClose={() => setEditNotePopup(false)}
          RefreshCart={() => {
            setEditNotePopup(false)
            getCartData()
          }}
        /> : null
      }
    </>
  );
};

export default CardPage;
