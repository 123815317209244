/**
 * @file RatingReview.js
 * @description Rating Review Dialog Box
 * @exports RatingReview - Dialog Box
 * @exports handleCloseRatingReview - Function to close the dialog box
 * @imports type - Type of rating review
 * @imports orderId - Order Id
 * @imports storeDetails - Store Details (if type is store)
 * @imports driverDetails - Driver Details (if type is driver)
 * @imports successReview - Function to call on success review
 */
import { useState } from 'react';
import { Button, TextField, FormControl, Rating, Dialog, DialogContent } from "@mui/material";
import './RatingReview.scss';
import modalClose from "../../assets/images/structure/close-black.svg";
import { postRequest } from '../../Helpers/Axios/Services'
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes'
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { useTranslation } from "react-i18next";
import { ampli } from "../../ampli";
import jwt_decode from "jwt-decode";
import { getLocalStorage, LOCAL_STORAGE_KEYS } from "../../Helpers/crypto/LocalStorage";


const RatingReview = ({ handleCloseRatingReview, type, orderId, storeDetails, driverDetails, successReview }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  let [rating, setRating] = useState(0);
  let [ratingError, setRatingError] = useState('');
  let [review, setReview] = useState("");
  let [reviewError, setReviewError] = useState("");

  const handleSubmitRatingReview = async () => {

    dispatch(changeLoader(true));
    let isValid = true;
    if (rating === 0) {
      isValid = false;
      setRatingError(t('order_Please_select_rating'));
    }
    if (review.trim() === "") {
      isValid = false;
      setReviewError(t('order_Please_enter_review'));
    }
    if (isValid) {
      const data = storeDetails ? {
        type: type,
        storeId: storeDetails.id,
        rating: rating,
        review: review
      } : {
        type: type,
        driverId: driverDetails.id,
        rating: rating,
        review: review
      };
      const { ack, msg } = await postRequest(API_ROUTES.ORDER.RATING_REVIEW(orderId), data);
      if (ack === 1) {
        dispatch(
          changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: msg,
            state: "success",
          })
        );
        successReview();
      } else {
        dispatch(
          changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: msg,
            state: "error",
          })
        );
      }
      //ampli==
      let userID = '';
      if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
        const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
        let decoded = jwt_decode(token);
        userID = decoded?.id;
      }
      ampli.track({
        event_type: 'OrderRated',
        event_properties: {
          category: 'Docs',
          name: 'SDK Library',
          description: 'SDK Library Description',
          user_id: userID,
          order_id: orderId,
          rating: rating,
          rating_for: "",
        },
      })
      dispatch(changeLoader(false));
    } else {
      dispatch(changeLoader(false));
    }
  }

  return (
    <Dialog className="main-modal" open={true} onClose={handleCloseRatingReview}>
      <img src={modalClose} alt="close" onClick={handleCloseRatingReview} className="modal-close" />
      <DialogContent className="rate-review-modal">
        <h4 className="h4">{t("order_Rate_Review")}</h4>
        {storeDetails ? <p className="p2">
          {t('order_rateYourExperienceForTheStore')} <span>({storeDetails?.stores_locales[0]?.business_name})</span>
        </p> : <p className="p2">
          {t('order_rateYourExperienceForTheDriver')} <span>({driverDetails?.first_name} {driverDetails?.last_name})</span>
        </p>}
        <div style={{
          position: 'relative'
        }}>
        <Rating
          name="rating"
          size="large"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
            setRatingError('');
          }}
        />
        <p className="errorspan">{ratingError}</p>
        </div>
        <FormControl sx={{ margin: "24px 0px" }}>
          <TextField
            id="outlined-multiline-static"
            label={t('order_Write_a_review')}
            value={review}
            onChange={(e) => {
              setReview(e.target.value)
              setReviewError('');
            }}
            multiline
            rows={3} />
          <span className="errorspan">{reviewError}</span>
        </FormControl>
        <Button
          type="submit"
          onClick={handleSubmitRatingReview}
          className="PrimaryButton"
          variant="contained"
          size="large"
          sx={{ width: "100%" }}
        >
          {t("order_Submit")}
        </Button>
      </DialogContent>
    </Dialog>
  )
};

export default RatingReview;