import axios from 'axios';
import { axiosConstant } from './Constants';
import { getLocalStorage, LOCAL_STORAGE_KEYS } from '../crypto/LocalStorage';

const version = '/v6';

/**
 * @description This function is used to logout the user
 * @for session logout
 * @returns {void}
 */
const sessionLogout = async () => {
  const lang = localStorage.getItem('i18nextLng')
  localStorage.clear();
  sessionStorage.clear();
  localStorage.setItem('i18nextLng', lang)
  window.location.href = '/';
}

/**
 * @description This function is used to get the third party request
 * @for GET Third party request where url is not from our server
 * @param {*} url 
 * @returns api response
 */
export const getThirdPartyRequest = async (url) => {
  if (url !== undefined) {
    return new Promise((resolve, reject) => {
      axios.get(url).then(res => {
        resolve(res.data);
      }).catch(err => {
        resolve({
          ack: 0,
          msg: 'Something went wrong.'
        });
      });
    })
  }
}

/**
 * @description This function is used to get the request
 * @param {*} url 
 * @for GET request
 * @returns api response 
 */
export const getRequest = async (url, options = {}) => {
  if (url !== undefined) {
    return new Promise((resolve, reject) => {
      axios.get(axiosConstant.BASE_URL + version + url, {
        headers: {
          'x-api-key': axiosConstant.API_KEY,
          'Authorization': (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) ? 'Bearer ' + getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN) : '',
          'lang': localStorage.getItem('i18nextLng') || 'ar'
        },
        ...options
      }).then(res => {
        if (res?.response?.status === 401) { sessionLogout(); }
        if (res?.response?.status === 404) {
          return { ack: 0, msg: 'Page not found' };
        }
        resolve(res.data);
      }).catch(err => {
        if (err?.response?.status === 401) { sessionLogout(); }
        if (err?.response?.status === 404) {
          return { ack: 0, msg: 'Page not found' };
        }
        resolve({
          ack: 0,
          msg: 'Something went wrong.'
        });
      });
    })
  }
}

/**
 * @description This function is used to post the request
 * @for POST request
 * @param {*} url 
 * @param {*} data 
 * @returns api response
 */
export const postRequest = async (url, data) => {
  if (url !== undefined) {
    try {
      return await axios.post(axiosConstant.BASE_URL + version + url, data, {
        headers: {
          'x-api-key': axiosConstant.API_KEY,
          'Authorization': (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) ? 'Bearer ' + getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN) : '',
          'lang': localStorage.getItem('i18nextLng') || 'ar'
        }
      }).then(res => {
        if (res?.response?.status === 401) { sessionLogout(); }
        if (res?.response?.status === 404) {
          return { ack: 0, msg: 'Page not found' };
        }
        return res.data;
      }).catch(err => {
        if (err?.response?.status === 401) { sessionLogout(); }
        if (err?.response?.status === 404) {
          return { ack: 0, msg: 'Page not found' };
        }
        return {
          ack: 0,
          msg: 'Something went wrong.'
        };
      });
    } catch (error) {
      if (error?.response?.status === 401) { sessionLogout(); }
      if (error?.response?.status === 404) {
        return { ack: 0, msg: 'Page not found' };
      }
      return {
        ack: 0,
        msg: 'Internal Server error'
      };
    }
  }
}

/**
 * @description This function is used to delete the request
 * @for DELETE request
 * @param {*} url 
 * @returns api response
 */
export const deleteRequest = async (url) => {
  if (url !== undefined) {
    return new Promise((resolve, reject) => {
      axios.delete(axiosConstant.BASE_URL + version + url, {
        headers: {
          'x-api-key': axiosConstant.API_KEY,
          'Authorization': (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) ? 'Bearer ' + getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN) : '',
          'lang': localStorage.getItem('i18nextLng') || 'ar'
        }
      }).then(res => {
        if (res?.response?.status === 401) { sessionLogout(); }
        if (res?.response?.status === 404) {
          return { ack: 0, msg: 'Page not found' };
        }
        resolve(res.data);
      }).catch(err => {
        if (err?.response?.status === 401) { sessionLogout(); }
        if (err?.response?.status === 404) {
          return { ack: 0, msg: 'Page not found' };
        }
        resolve({
          ack: 0,
          msg: 'Something went wrong.'
        });
      });
    })
  }
}

/**
 * @description This function is used to put the request
 * @for PUT request
 * @param {*} url
 * @param {*} data
 * @returns api response
 */
export const putRequest = async (url, data) => {
  if (url !== undefined) {
    return await axios.put(axiosConstant.BASE_URL + version + url, data, {
      headers: {
        'x-api-key': axiosConstant.API_KEY,
        'Authorization': (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) ? 'Bearer ' + getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN) : '',
        'lang': localStorage.getItem('i18nextLng') || 'ar'
      }
    }).then(res => {
      if (res?.response?.status === 401) { sessionLogout(); }
      if (res?.response?.status === 404) {
        return { ack: 0, msg: 'Page not found' };
      }
      return res.data;
    }).catch(err => {
      if (err?.response?.status === 401) { sessionLogout(); }
      if (err?.response?.status === 404) {
        return { ack: 0, msg: 'Page not found' };
      }
      return {
        ack: 0,
        msg: 'Something went wrong.'
      };
    });
  }
}

/**
 * @description This function is used to get the request with TOOKANAPP_API_URL
 * @for Getting fare estimation
 * @param {*} data 
 * @contains store longitude, store latitude, user longitude and user latitude
 * @returns api response with fare estimation
 */
export const EstimateDeliveryFee = async (data) => {
  return await axios.post(axiosConstant.TOOKANAPP_API_URL, {
    "template_name": "Food_Delivery",
    "pickup_longitude": data.store_longitude,
    "pickup_latitude": data.store_latitude,
    "api_key": axiosConstant.TOOKANAPP_API_KEY,
    "delivery_latitude": data.user_latitude,
    "delivery_longitude": data.user_longitude,
    "formula_type": 3,
    "map_keys": {
      "map_plan_type": 1,
      "google_api_key": "GOOGLE_API_KEY"
    }
  }, {
    headers: {}
  }).then(res => {
    return res.data;
  }).catch(err => {
    return {
      ack: 0,
      msg: 'Something went wrong.'
    };
  });

}

export const getAddressFromOpenStreetMap = async (address) => {
  const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${address} qatar&format=json`);
  return response;
};