/**
 * @file AddMoney.js
 * @description Add Money Page
 * @exports AddMoney - Add Money Page
 */

import "./AddMoney.scss";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  Grid, Divider, Button, TextField, Checkbox, Radio,
  RadioGroup, FormControlLabel, FormControl, InputAdornment
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getRequest, postRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { cardMask, cardExpiryMask, cardCvvMask, amountMask } from '../../global-modules/MaskInputs/MaskedInputs';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { getLocalStorage, LOCAL_STORAGE_KEYS } from "../../Helpers/crypto/LocalStorage";
import { useEffect } from "react";
import Sidebar from "../layout/sidebar/Sidebar";
import jwt_decode from "jwt-decode";

const AddMoney = () => {

  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const { t } = useTranslation();
  let [paymentMethod, setPaymentMethod] = useState('creditcard');
  let [amount, setAmount] = useState('');
  let [amount_error, setAmountError] = useState('');
  let [credit_card, setCreditCard] = useState('');
  let [credit_card_error, setCreditCardError] = useState('');
  let [expiry_date, setExpiryDate] = useState('');
  let [expiry_date_error, setExpiryDateError] = useState('');
  let [cvv, setCvv] = useState('');
  let [cvv_error, setCvvError] = useState('');
  let [cardHolderName, setCardHolderName] = useState('');
  let [cardHolderName_error, setCardHolderNameError] = useState('');
  let [saveCard, setSaveCard] = useState(false);
  let [cardList, setCardList] = useState([]);
  let [maxWalletAmount, setMaxWalletAmount] = useState(0);

  const addMoney = async (cardToken, method) => {
    const data = {
      amount: amount, cardToken: cardToken, method: method,
      payment_sequence: saveCard ? 'recurring' : 'oneoff',
      redirectUrl: `${window.location.origin}/wallet-redirect`
    }
    const response = await postRequest(API_ROUTES.WALLET.ADD_WALLET_AMOUNT, data);
    const status = response.ack;
    if (status === 1) {
      const transaction_data = response?.transaction_data;
      if (paymentMethod === 'creditcard') {
        const checkout_link = transaction_data?.checkout_link;
        dispatch(changeLoader(false));
        window.location.href = checkout_link;
      } else if (paymentMethod === 'naps') {
        const checkout_link = transaction_data?.checkout_link;
        dispatch(changeLoader(false));
        window.location.href = checkout_link;
      } else {
        if (response?.transaction_data) {
          const checkout_link = transaction_data?.redirectUrl;
          dispatch(changeLoader(false));
          window.location.href = checkout_link;
        }
        dispatch(changeLoader(false));
      }
    } else {
      if (typeof response?.msg === String) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        }));
      } else if (response?.msg?.failureMessage) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg.failureMessage,
          state: "error",
        }))
      }
      dispatch(changeLoader(false));
    }
  }

  const getWalletAmount = async () => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      const decoded = jwt_decode(token);
      const url = API_ROUTES.WALLET.GET_WALLET_AMOUNT(decoded.id);
      const { ack, max_wallet_amount, transactions } = await getRequest(url);
      if (ack === 1) {
        if (transactions?.amount) {
          setMaxWalletAmount(max_wallet_amount -  transactions?.amount)
        } else {
          setMaxWalletAmount(max_wallet_amount)
        }
      }
    }
  }

  const getCardList = async () => {
    const response = await getRequest(API_ROUTES.USER_CARDS.GET_USER_CARDS);
    if (response.ack === 1 && response?.body?.count > 0) {
      cardList = response?.body?.cardTokens;
      setCardList(cardList);
    } else {
      cardList = [];
      setCardList(cardList);
    }
  }

  const handleCardToken = async () => {
    let validCard = true;
    if (credit_card === '') {
      validCard = false;
      setCreditCardError(t('wallet_Cardnumberisrequired'));
    }
    if (expiry_date === '') {
      validCard = false;
      setExpiryDateError(t('wallet_Expirydateisrequired'));
    }
    if (cvv === '') {
      validCard = false;
      setCvvError(t('wallet_CVVisrequired'));
    }
    if (cardHolderName === '') {
      validCard = false;
      setCardHolderNameError(t('wallet_Cardholdernameisrequired'));
    }
    if (validCard) {
      const cardExpiryMonth = expiry_date.split('/')[0];
      const cardExpiryYear = expiry_date.split('/')[1];
      const cardNumber = credit_card.split('-').join('');
      const cardData = {
        cardNumber: cardNumber,
        cardExpiryMonth: cardExpiryMonth,
        cardExpiryYear: cardExpiryYear,
        cardCVC: cvv,
        cardHolder: cardHolderName
      }
      const { ack, data } = await postRequest(API_ROUTES.CART.GET_CARD_TOKEN, cardData);
      if (ack === 1) {
        if (data.cardToken) {
          addMoney(data?.cardToken, paymentMethod);
        } else {
          const message = (data?.details)? data?.details : data?.failure?.failureMessage;
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: message,
            state: "error",
          }));
          dispatch(changeLoader(false));
        }
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t('wallet_invalid_card_details'),
          state: "error",
        }));
        dispatch(changeLoader(false));
      }
    } else {
      dispatch(changeLoader(false));
    }
  }

  const handleAddMoney = async () => {
    dispatch(changeLoader(true));
    let isValidate = true;
    if (amount === '') {
      amount_error = t('wallet_Enteramount');
      setAmountError(amount_error);
      isValidate = false;
    } else if (maxWalletAmount == 0) {
      amount_error = t('wallet_Maximumwalletamountreached');
      setAmountError(amount_error);
      isValidate = false;
    } else if (amount > maxWalletAmount) {
      amount_error = t('wallet_Amountshouldnotexceed', { amount: maxWalletAmount });
      setAmountError(amount_error);
      isValidate = false;
    }
    if (isValidate) {
      switch (paymentMethod) {
        case 'creditcard':
          handleCardToken();
          break;
        case 'naps':
          addMoney('', 'naps');
          break;
        default:
          let selectedCard = cardList.find(card => card.id === paymentMethod);
          if (selectedCard) {
            saveCard = true;
            setSaveCard(saveCard);
            addMoney(selectedCard?.id, 'creditcard');
          } else {
            dispatch(changeSnackbar({
              ...snackbar,
              isOpen: true,
              message: t('cart_PaymentmethodisInvalid'),
              state: "error",
            }));
            dispatch(changeLoader(false));
          }
          break;
      }
    } else {
      dispatch(changeLoader(false));
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    }
  }

  useEffect(() => {
    getCardList();
    getWalletAmount();
    setTimeout(() => { window.scrollTo(0, 0); }, 500);
  }, []);

  return (
    <div>
      <div className="main-wrapper full-bg">
        <Sidebar />
        <div className="main-mid-wrapper">
          <div className="store-detail-wrapper money-content">
            <div className="store-detail-head">
              <h3 className="h3">{t("wallet_AddMoney")}</h3>
            </div>
            <Grid container columnSpacing={0}>
              <Grid item xs={12}>
                <div className="card-white">
                  <p className="p2">{t("wallet_Enterbelowamounttoaddmoneyinyourwallet")}</p>
                  <div className="form-group">
                    <TextField
                      label={t("wallet_EnterAmount")}
                      className="width542"
                      value={amount}
                      // onChange={(e) => setAmount(e.target.value)}
                      onChange={(e) => {
                        let val = e.target.value;
                        if (val === "") {
                          setAmount("");
                          setAmountError("");
                          return;
                        }
                        let numVal = parseInt(val);
                        if (numVal > 0) {
                          setAmount(val);
                          setAmountError("");
                        } else {
                          setAmountError("Value should be greater than 0");
                        }
                      }}
                      InputProps={{
                        inputComponent: amountMask,
                        endAdornment: (
                          <InputAdornment position="end" className="bg-theme">
                            {t("wallet_QAR")}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <span className="errorspan">{amount_error}</span>
                  </div>
                </div>
                <div className="card-white">
                  <h6 className="h6">{t("wallet_ChoosePaymentMethod")}</h6>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      {cardList.length > 0? cardList.map((card, index) => <span key={`card-${index}`}>
                      <div className={`radio-label ${paymentMethod === "wallet" ? "no-event disabled-for-wallet" : ""}`}>
                        <FormControlLabel value={card?.id} control={<Radio />} />
                        <div className="custom-label">
                          <p>{card?.cardHolder} ({card?.cardScheme})</p>
                          <span> **** **** **** {card?.cardLast4} </span>
                        </div>
                      </div>
                      <Divider />
                      </span>) :null}
                      <div className="radio-label">
                        <FormControlLabel value="naps" control={<Radio />} />
                        <div className="custom-label">{t("wallet_DebitCard")}</div>
                      </div>
                      <Divider />
                      <div className="radio-label">
                        <FormControlLabel value="creditcard" control={<Radio />} />
                        <div className="custom-label">{t("wallet_CreditCard")}</div>
                      </div>
                      {paymentMethod === "creditcard" ? (
                        <Grid
                          container
                          xs={12}
                          sm={12}
                          md={12}
                          lg={10}
                          spacing={{ xs: 0, sm: '15px', md: '20px' }}
                          className="after-checked-div"
                        >
                          <Grid item xs={12} sm={6} md={5} lg={5}>
                            <div className="form-group">
                              <TextField
                                value={credit_card}
                                onChange={(e) => setCreditCard(e.target.value)}
                                InputProps={{ inputComponent: cardMask }}
                                placeholder={t("wallet_CardNumber") + "*"}
                                label={t("wallet_CardNumber") + "*"}
                                mask="0000-0000-0000-0000"
                              />
                              <span className="errorspan">{credit_card_error}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={5} lg={5}>
                            <div className="form-group">
                              <TextField
                                value={cardHolderName}
                                onChange={(e) => setCardHolderName(e.target.value)}
                                placeholder={t("wallet_CardHolderName") + "*"}
                                label={t("wallet_CardHolderName") + "*"}
                              />
                              <span className="errorspan">{cardHolderName_error}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={5} lg={5}>
                            <div className="form-group">
                              <TextField
                                InputProps={{ inputComponent: cardExpiryMask }}
                                value={expiry_date}
                                onChange={(e) => setExpiryDate(e.target.value)}
                                placeholder={"MM/YY"}
                                label={t("wallet_ExpiryDate") + "*"}
                              />
                              <span className="errorspan">{expiry_date_error}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={5} lg={5}>
                            <div className="form-group">
                              <TextField
                                type="password"
                                value={cvv}
                                onChange={(e) => setCvv(e.target.value)}
                                placeholder={"***"}
                                InputProps={{ inputComponent: cardCvvMask }}
                                label={t("wallet_CVV") + "*"}
                              />
                              <span className="errorspan">{cvv_error}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="medium"
                                  checked={saveCard}
                                  onChange={(e) => {
                                    saveCard = e.target.checked;
                                    setSaveCard(saveCard);
                                  }}
                                  name="saveCard"
                                />
                              }
                              label={t("wallet_save_card")}
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} className="ltr-align-right">
                <Button
                  variant="contained"
                  onClick={handleAddMoney}
                  type="button"
                  size="large"
                  sx={{ width: "115px", marginTop: "0px" }}
                >
                  {t("wallet_Add")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMoney;
