import "./Grocery.scss";
import { useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
// @mui
import ClipLoader from "react-spinners/ClipLoader";
import { TextField, Tabs, Tab, MenuItem, FormControl, Select } from "@mui/material";
// others
import { Boxed } from '../../Styles-Elements/Box';
import { getRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { axiosConstant } from '../../Helpers/Axios/Constants';
import { changeActiveLink } from "../../redux/reducers/activeLink";
import { setLocalStorage, LOCAL_STORAGE_KEYS, getLocalStorage } from '../../Helpers/crypto/LocalStorage';
import { currentAddress } from '../../redux/reducers/address';
import useIntersection from '../../Helpers/useIntersection'
import sortbyIcon from "../../assets/images/structure/sortby-icon.svg";
import searchBlack from "../../assets/images/structure/search-black.svg";
import defaultImage from "../../assets/images/structure/default.jpeg";
import Footer from "../layout/footer/Footer";
import Breadcrumb from '../../shared/Breadcrumb';
import Products from './products/Products';
import Stores from './stores/Stores';
import NoDataFound from '../../global-modules/EmptyPages/NoDataFound';
import ComingSoon from '../../global-modules/ComingSoon/ComingSoon';
import Image from "../../global-modules/Image/Image";
import { ampli } from "../../../src/ampli";
import jwt_decode from "jwt-decode";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Boxed>
          {children}
        </Boxed>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Storefront = () => {

  const userAddress = useSelector(currentAddress)
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const ref = useRef(null);
  const ref2 = useRef(null);
  const inViewport = useIntersection(ref, '500px');
  const inViewport2 = useIntersection(ref2, '500px');
  const [value, setValue] = useState(0);
  let [isLoading, setIsLoading] = useState(false);
  let [crumValue, setCrumValue] = useState('stores');
  let [businessType, setBusinessType] = useState(null);
  let [search, setSearch] = useState('');
  let [sortBy, setSortBy] = useState('fastest_delivery');
  let [storeCanLoad, setStoreCanLoad] = useState(false);
  let [storeCanLoad2, setStoreCanLoad2] = useState(false);
  let [loadmore, setLoadmore] = useState(false);
  let [limit, setLimit] = useState(18);
  let [page, setPage] = useState(1);
  let [stores, setStores] = useState([]);
  let [recomendedStores, setSecomendedStores] = useState([]);
  let [storesFirst, setStoresFirst] = useState([]);
  let [storesSecond, setStoresSecond] = useState([]);
  let [limitProduct, setLimitProduct] = useState(10);
  let [pageProduct, setPageProduct] = useState(1);
  let [storeProducts, setStoreProducts] = useState([]);
  let [breadcrums, setBreadcrums] = useState([]);
  let [loadmore2, setLoadmore2] = useState(false);
  let [searchAble, setSearchAble] = useState(false);
  let [allBusinessTypes, setAllBusinessTypes] = useState(false);
  let [discountDataOfAll, setDiscountDataOfAll] = useState([]);
  let [discountType, setDiscountType] = useState('all');

  const getAllBusinessTypes = async () => {
    const response = await getRequest(API_ROUTES.COMMON.GET_BUSINESS_CATEGORIES);
    allBusinessTypes = response.businesstypes.map((category) => {
      let item = category;
      let offers = category?.discounts_and_offer_relations?.map((offer) => offer.discounts_and_offer);
      offers = [...new Map(offers?.map(item => [item["id"], item])).values()]
      item.offers = offers;
      return item;
    });
    setAllBusinessTypes(allBusinessTypes);
    businessType = allBusinessTypes?.find(type => type?.slug === slug);
    setBusinessType(businessType);
  };

  useEffect(() => {
    if (allBusinessTypes?.length > 0) {
      businessType = allBusinessTypes?.find(type => type?.slug === slug);
      setBusinessType(businessType);
    }
  }, [slug])

  const setBreadcrumsValue = () => {
    breadcrums = [{
      title: t('storefront_homepage'),
      haveLink: true,
      link: '/'
    }, {
      title: businessType?.business_type_locales[0].name,
      haveLink: false,
      link: ''
    }];
    setBreadcrums(breadcrums);
  }

  const handleChange = (event, newValue) => {

    page = 1; setPage(page);
    switch (newValue) {
      case 0:
        crumValue = 'stores';
        setCrumValue(crumValue);
        setBreadcrumsValue();
        getStoreData();
        break;
      case 1:
        crumValue = 'products';
        setCrumValue(crumValue);
        setBreadcrumsValue();
        getproductsData();
        break;
      default:
        crumValue = 'stores';
        setCrumValue(crumValue);
        setBreadcrumsValue();
        getStoreData();
        break;
    }
    setValue(newValue);
    sessionStorage.setItem('crumvalue', crumValue);
  };

  const handleChange1 = (event) => {
    sortBy = event.target.value;
    setSortBy(sortBy);
    if (crumValue === 'stores') {
      setLimit(limit);
      page = 1; setPage(page)
      getStoresFromSlug()
      getRecomendedStoresFromSlug()
    } else {
      setLimitProduct(limitProduct);
      pageProduct = 1; setPageProduct(pageProduct)
      getproductsData();
    }
  };

  const handleSearch = (e) => {
    search = e.target.value;
    setSearch(search)
    setSearchAble(true)
  }

  const loadMoreData = async () => {
    setStoreCanLoad(false)
    setLoadmore(true)
    page++;
    setPage(page);
    let url = API_ROUTES.STOREFRONT.GET_STORES(slug);
    url += `?limit=${limit}&page=${page}`
    if (search.trim() !== '') {
      url += `&search=${search.trim()}`
    }
    if (sortBy !== '') {
      url += `&sort_by=${sortBy}`
    }
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const response = await getRequest(url)
    if (response.ack === 1) {
      const allStores = response.stores.map((store) => {
        let item = store;
        let offers = store?.discounts_and_offer_relations?.map((offer) => offer.discounts_and_offer);
        if (offers?.length === 0) {
          let storeOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'store').length > 0 ?
            discountDataOfAll?.filter(
              offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
            ).length > 0 ? discountDataOfAll?.filter(
              offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
            ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'store' && offer?.apply_on === 'all') : [];
          if (storeOffers.length === 0) {
            storeOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type').length > 0 ?
              discountDataOfAll?.filter(
                offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(store.businessTypeId)
              ).length > 0 ? discountDataOfAll?.filter(
                offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(store.businessTypeId)
              ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'all') : [];
          }
          if (offers?.length > 0) { offers = [] }
          if (storeOffers?.length > 0) { storeOffers = [] }
          offers = [...offers, ...storeOffers]
        }
        offers = [...new Map(offers?.map(item => [item["id"], item])).values()]
        item.offers = offers;
        return item;
      }); // .filter(store => parseInt(store.productCount) > 0);
      storesSecond.push(...allStores)
      setStoresSecond(storesSecond);
      setLoadmore(false)
      if (allStores?.length > 0) {
        setTimeout(() => {
          setStoreCanLoad(true)
        }, 2000);
      } else {
        setStoreCanLoad(false)
      }
    } else {
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
      setLoadmore(false)
    }
  }

  const getRecomendedStoresFromSlug = async () => {
    // let url = API_ROUTES.STOREFRONT.GET_RECOMMENDED_STORES(slug);
    let url = API_ROUTES.STOREFRONT.GET_STORES(slug);
    url += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    url += `&recommended=true`
    if (search.trim() !== '') {
      url += `&search=${search.trim()}`
    }
    if (sortBy !== '') {
      url += `&sort_by=${sortBy}`
    }
    if (discountType !== 'all' && discountType !== null) {
      url += `&offerType=${discountType}`
    }
    const response = await getRequest(url)
    if (response.ack === 1) {
      const allStores = response.stores.map((store) => {
        let item = store;
        let offers = store?.discounts_and_offer_relations?.map((offer) => offer.discounts_and_offer);
        if (offers?.length === 0) {
          let storeOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'store').length > 0 ?
            discountDataOfAll?.filter(
              offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
            ).length > 0 ? discountDataOfAll?.filter(
              offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
            ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'store' && offer?.apply_on === 'all') : [];
          if (offers?.length > 0) { offers = [] }
          if (storeOffers?.length > 0) { storeOffers = [] }
          offers = [...offers, ...storeOffers]
        }
        offers = [...new Map(offers?.map(item => [item["id"], item])).values()]
        item.offers = offers;
        return item;
      }); // .filter(store => parseInt(store.productCount) > 0);
      recomendedStores = allStores;
      setSecomendedStores(recomendedStores)
    } else {
      setSecomendedStores([])
    }
  }

  const getStoresFromSlug = async () => {
    dispatch(changeLoader(true));
    let url = API_ROUTES.STOREFRONT.GET_STORES(slug);
    url += `?limit=${limit}&page=${page}`
    url += search.trim() !== '' ? `&search=${search.trim()}` : '';
    url += sortBy !== '' ? `&sort_by=${sortBy}` : '';
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    url += (discountType !== 'all' && discountType !== null) ? `&offerType=${discountType}` : '';
    const response = await getRequest(url)
    const allStores = response.stores.map((store) => {
      let item = store;
      let offers = store?.discounts_and_offer_relations?.map((offer) => offer.discounts_and_offer);
      discountDataOfAll = response?.discountDataOfAll;
      setDiscountDataOfAll(discountDataOfAll);
      if (offers?.length === 0) {
        let storeOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'store').length > 0 ?
          discountDataOfAll?.filter(
            offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
          ).length > 0 ? discountDataOfAll?.filter(
            offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
          ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'store' && offer?.apply_on === 'all') : [];
        if (storeOffers.length === 0) {
          storeOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type').length > 0 ?
            discountDataOfAll?.filter(
              offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(store.businessTypeId)
            ).length > 0 ? discountDataOfAll?.filter(
              offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(store.businessTypeId)
            ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'all') : [];
        }
        if (offers?.length > 0) { offers = [] }
        if (storeOffers?.length > 0) { storeOffers = [] }
        offers = [...offers, ...storeOffers]
      }
      offers = [...new Map(offers?.map(item => [item["id"], item])).values()]
      item.offers = offers;
      return item;
    }); // .filter(store => parseInt(store.productCount) > 0);
    if (response.ack === 1) {
      if (page === 1) {
        stores = [];
        stores = allStores;
      } else {
        stores.append(allStores)
      }
      setStores(allStores)
      if (stores?.length > 6) {
        storesFirst = stores.splice(0, 6);
        storesSecond = stores;
      } else {
        storesFirst = stores;
        storesSecond = [];
      }
      setStoresFirst(storesFirst);
      setStoresSecond(storesSecond);
      dispatch(changeLoader(false));
      setTimeout(() => {
        setStoreCanLoad(true)
      }, 3000);
    } else {
      setStores([])
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
      dispatch(changeLoader(false));
    }
  }

  const handleChangeDiscount = async (type) => {
    discountType = type;
    setDiscountType(discountType);
    if (crumValue === 'stores') {
      setLimit(limit);
      page = 1; setPage(page)
      getStoresFromSlug();
    } else {
      setLimitProduct(limitProduct);
      pageProduct = 1; setPageProduct(pageProduct)
      getproductsData();
    }
  }

  if (!loadmore && storeCanLoad && inViewport && stores?.length > 0 && crumValue === 'stores') {
    loadMoreData();
  }

  const loadMoreproducts = async () => {
    setStoreCanLoad2(false)
    setLoadmore2(true)
    page++;
    setPage(page);
    let url = API_ROUTES.STOREFRONT.GET_PRODUCTS_BY_STORE(slug);
    url += `?limit=${limit}&page=${page}`
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    if (search !== '') {
      url += `&search=${search}`
    }
    const response = await getRequest(url)
    if (response.ack === 1) {
      const allStores = response.ProductByStores.map(store => {
        let item = store;
        let offers = store?.discounts_and_offer_relations?.map((offer) => offer.discounts_and_offer) || [];
        if (offers?.length === 0) {
          let storeOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'store').length > 0 ?
            discountDataOfAll?.filter(
              offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
            ).length > 0 ? discountDataOfAll?.filter(
              offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
            ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'store' && offer?.apply_on === 'all') : [];
          if (storeOffers.length === 0) {
            storeOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type').length > 0 ?
              discountDataOfAll?.filter(
                offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(store.businessTypeId)
              ).length > 0 ? discountDataOfAll?.filter(
                offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(store.businessTypeId)
              ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'all') : [];
          }
          if (offers?.length > 0) { offers = [] }
          if (storeOffers?.length > 0) { storeOffers = [] }
          offers = [...offers, ...storeOffers]
        }
        offers = [...new Map(offers?.map(item => [item["id"], item])).values()]
        item.offers = offers;
        return item
      }).filter(store => store?.products?.length > 0);
      storeProducts.push(...allStores)

      setStoreProducts(storeProducts);
      setLoadmore2(false)
      if (allStores?.length > 0) {
        setTimeout(() => {
          setStoreCanLoad2(true)
        }, 2000);
      } else {
        setStoreCanLoad2(false)
      }
    } else {
      setStores([])
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
      setLoadmore2(false)
    }
  }

  if (!loadmore2 && storeCanLoad2 && inViewport2 && stores?.length > 0 && crumValue === 'products') {
    loadMoreproducts();
  }

  const searchData = async () => {
    if (crumValue === 'stores') {
      setLimit(limit);
      page = 1; setPage(page)
      getStoresFromSlug();
    } else {
      setLimitProduct(limitProduct);
      pageProduct = 1; setPageProduct(pageProduct)
      getproductsData();
    }
  }

  const getProductsFromSlug = async () => {
    dispatch(changeLoader(true));
    pageProduct = 1; setPageProduct(pageProduct)
    let url = API_ROUTES.STOREFRONT.GET_PRODUCTS_BY_STORE(slug);
    url += `?limit=${limit}&page=${page}`;
    url += (search !== '') ? `&search=${search}` : '';
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    if (discountType !== 'all' && discountType !== null) {
      url += `&offerType=${discountType}`
    }
    const response = await getRequest(url)
    if (response.ack === 1) {
      const allStores = response.ProductByStores.map(store => {

        let item = store;
        let offers = store?.discounts_and_offer_relations?.map((offer) => offer.discounts_and_offer) || [];
        if (offers?.length === 0) {
          let storeOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'store').length > 0 ?
            discountDataOfAll?.filter(
              offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
            ).length > 0 ? discountDataOfAll?.filter(
              offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
            ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'store' && offer?.apply_on === 'all') : [];
          if (storeOffers.length === 0) {
            storeOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type').length > 0 ?
              discountDataOfAll?.filter(
                offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(store.businessTypeId)
              ).length > 0 ? discountDataOfAll?.filter(
                offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(store.businessTypeId)
              ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'all') : [];
          }
          if (offers?.length > 0) { offers = [] }
          if (storeOffers?.length > 0) { storeOffers = [] }
          offers = [...offers, ...storeOffers]
        }
        offers = [...new Map(offers?.map(item => [item["id"], item])).values()]
        item.offers = offers;
        return item
      }).filter(store => store?.products?.length > 0);
      storeProducts = allStores;
      setStoreProducts(storeProducts);
      dispatch(changeLoader(false));
      setTimeout(() => {
        setStoreCanLoad2(true)
      }, 3000);
    } else {
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
      dispatch(changeLoader(false));
    }

  }

  const getproductsData = async () => {
    isLoading = true; setIsLoading(isLoading);
    pageProduct = 1;
    await setPageProduct(pageProduct)
    await getProductsFromSlug();
    setIsLoading(false);
  }

  const getStoreData = async () => {
    isLoading = true; setIsLoading(isLoading);
    page = 1;
    setPage(page);
    await getStoresFromSlug();
    await getRecomendedStoresFromSlug();
    setIsLoading(false);
  }

  useEffect(() => {
    if (searchAble) {
      const getData = setTimeout(() => {
        searchData()
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [search]);

  const setActiveLink = async () => {
    setLocalStorage(LOCAL_STORAGE_KEYS.ACTIVE_LINK, slug)
    dispatch(changeActiveLink());
  }

  const checkCrumValud = () => {
    if (sessionStorage.getItem('crumvalue')) {
      crumValue = sessionStorage.getItem('crumvalue');
      setCrumValue(crumValue);
      sessionStorage.removeItem('crumvalue');
    } else {
      crumValue = 'stores';
      setCrumValue(crumValue);
      setValue(0);
    }
  }

  useEffect(() => {
    isLoading = true;
    setIsLoading(isLoading);
    getAllBusinessTypes()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    checkCrumValud();
    search = searchParams.get('s') ?
      searchParams.get('s') : '';
    setSearch(search);
    sortBy = "fastest_delivery";
    setSortBy(sortBy);
    if (crumValue === 'stores') {
      setValue(0);
      stores = [];
      setStores(stores)
      getStoreData();
    } else {
      setValue(1);
      getproductsData();
    }
    setBreadcrumsValue();
    setActiveLink();
  }, [slug, userAddress])

  useEffect(() => {
    setBreadcrumsValue();
  }, [businessType])

  return (
    <div>
      <div className='grocery-wrapper'>
        <Breadcrumb breadcrums={[{
          title: t('storefront_homepage'),
          haveLink: true,
          link: '/'
        }, {
          title: businessType?.business_type_locales[0]?.name,
          haveLink: false,
          link: ''
        }]} />
        {businessType ? <div>
          <div className="container-body">
            <div className='searches-wrapper'>
              <div className='searchesleft-wrap'>
                <Image
                  src={businessType?.image === '' ? defaultImage : axiosConstant.IMAGE_BASE_URL(businessType?.image)}
                  className="searches-img" alt={businessType?.business_type_locales[0]?.name} />
                <div>
                  <h3 className="h3 detail-chips-parent">
                    <span className="ml-1">{businessType?.business_type_locales[0]?.name}</span>
                    <div className="chips-row" style={{ padding: '0px' }}>
                      {businessType?.offers?.map((offer, index) =>
                        <span key={`offer-${index}`} className="chips">{offer?.name}</span>
                      )}
                    </div>
                  </h3>
                </div>
              </div>
              <div className="searchesright-wrap">
                <div className="searches-block">
                  <img src={searchBlack} className="searches-icon" alt="search" />
                  <TextField id="outlined-basic"
                    type={'search'}
                    sx={{ width: '100%', backgroundColor: '#fff', }} className="searches-input"
                    variant="outlined"
                    placeholder={t('storefront_searchForProductsAndStore')}
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div> : null}
        <div className="tabs-block">
          <Boxed className='tabs-head container-body' display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label={slug === 'food' ? t('storefront_restaurants') : t('stores')} {...a11yProps(0)} />
              <Tab label={slug === 'food' ? t('storefront_menu') : t('products')} {...a11yProps(1)} />
            </Tabs>
            {crumValue === 'stores' ? <Boxed className='sortby-dropdown'>
              <img src={sortbyIcon} className="sortby-icon" alt="sortby" />
              <FormControl fullWidth size="small">
                <Select
                  id="demo-select-small"
                  onChange={handleChange1}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  value={sortBy}
                >
                  {/* <MenuItem className="border-bottom" value={''}>{t('storefront_sortBy')}</MenuItem> */}
                  <MenuItem className="border-bottom" value={'fastest_delivery'}>{t('storefront_fastestDelivery')}</MenuItem>
                  <MenuItem className="border-bottom" value={'ratings'}>{t('storefront_ratings')}</MenuItem>
                  <MenuItem className="border-bottom" value={'newest'}>{t('storefront_newest')}</MenuItem>
                  <MenuItem className="border-bottom" value={'atoz'}>{t('storefront_aToz')}</MenuItem>
                </Select>
              </FormControl>
            </Boxed> : null}
          </Boxed>
          <TabPanel value={value} index={0}>
            <Stores
              search={search}
              slug={slug}
              storesFirst={storesFirst}
              storesSecond={storesSecond}
              businessType={businessType}
              recomendedStores={recomendedStores}
              handleChangeDiscount={handleChangeDiscount}
              discountDataOfAll={discountDataOfAll}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Products
              search={search}
              slug={slug}
              storeProducts={storeProducts}
              handleChangeDiscount={handleChangeDiscount}
              discountDataOfAll={discountDataOfAll}
            />
          </TabPanel>
          <div className='loader-wrap' ref={ref} >
            <ClipLoader color={"#EF4930"} loading={loadmore} speedMultiplier={0.5} />
          </div>
          <div className='loader-wrap' ref={ref2} >
            <ClipLoader color={"#EF4930"} loading={loadmore2} speedMultiplier={0.5} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
};

export default Storefront;