/**
 * @file This file contains all the English language translations
 * English Language
 * @type {Object}
 * @returns {Object} language - The language object
 */

import common from './en/common.json';
import header from './en/header.json';
import sidebar from './en/sidebar.json';
import signup from './en/signup.json';
import changepassword from './en/changepassword.json';
import storefront from './en/storefront.json';
import address from './en/address.json';
import cart from './en/cart.json';
import order from './en/order.json';
import wishlist from './en/wishlist.json';
import review from './en/review.json';
import wallet from './en/wallet.json';
import termsCondition from './en/termsCondition.json';
import faq from './en/faq.json';
import static_pages from './en/static_pages.json';
import manage_card from './en/manage_card.json';

const en = () => {
  const language = {
    ...common,
    ...header,
    ...sidebar,
    ...signup,
    ...changepassword,
    ...storefront,
    ...address,
    ...cart,
    ...order,
    ...wishlist,
    ...review,
    ...wallet,
    ...termsCondition,
    ...faq,
    ...static_pages,
    ...manage_card
  }
  return language;
}

export default en