import { useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./Faq.scss";
import Breadcrumb from "../../shared/Breadcrumb";
import Footer from "../layout/footer/Footer";
import { useTranslation } from "react-i18next";
import { getRequest } from '../../Helpers/Axios/Services'
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes'
import { currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";

const Faq = () => {
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  let [faqs, setFaq] = useState(null);

  const getFaq = async () => {
    changeLoader(true);
    const response = await getRequest(API_ROUTES.STATIC_PAGES.GET_FAQS);
    if (response?.ack) {
      faqs = response?.cms;
      setFaq(faqs);
      changeLoader(false);
    } else {
      changeLoader(false);
    }
  };

  useEffect(() => {
    getFaq();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="faq-condition-wrapper">
        <div className="container-body">
          <Breadcrumb
            breadcrums={[
              {
                title: t("storefront_homepage"),
                haveLink: true,
                link: "/",
              },
              {
                title: t("faq_FrequentlyAskedQuestions"),
                haveLink: false,
              },
            ]}
          />
          <div className="faq-condition-head">
            <h3 className="h3">{t("faq_FrequentlyAskedQuestions")}</h3>
          </div>
          <div className="faq-condition-body">
            <Accordion activeIndex={0}>
              {faqs?.length > 0? faqs?.map((faq, index) =>
              <AccordionTab key={`faq-${index}`} header={faq?.cms_locales[0]?.question}>
                <p className="p2">{faq?.cms_locales[0]?.answer}</p>
              </AccordionTab>
              ):null}
              {/* <AccordionTab header={t("faq_HowdoIknowwhenmyorderwillarrive_")}>
                <p className="p2">{t("faq_Youcanplaceanorderonthevendorswebsiteorappby_ans1")}</p>
              </AccordionTab>
              <AccordionTab header={t("faq_WhatifIneedtocancelormodifymyorder_")}>
                <p className="p2">{t("faq_Youcanplaceanorderonthevendorswebsiteorappby_ans1")}</p>
              </AccordionTab>
              <AccordionTab header={t("faq_Whatifthereisanissuewithmyorderordelivery_")}>
                <p className="p2">{t("faq_Youcanplaceanorderonthevendorswebsiteorappby_ans1")}</p>
              </AccordionTab> */}
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
