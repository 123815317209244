/**
 * @file App.js
 * @description This component is used to show app.
 * @exports App component
 * @fileContains App component and routes of app some default widgets like snack bar and loader
 */
import { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import './App.scss';
import './assets/scss/direction.scss';
import jwt_decode from "jwt-decode";
import { v4 as uuid } from 'uuid';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from './Helpers/crypto/LocalStorage';
import { getRequest } from './Helpers/Axios/Services';
import { API_ROUTES } from './Helpers/Axios/ApiRoutes';
import { useDispatch, useSelector } from "react-redux";
import { currentLoader } from "./redux/reducers/loader";
import { Alert, Snackbar } from "@mui/material";
import { changeSnackbar, currentSnackbar } from "./redux/reducers/snackbar";
import { useTranslation } from "react-i18next";
import { changeActiveLink } from "./redux/reducers/activeLink";
import moengage from '@moengage/web-sdk';
import ScrollToTop from "./Styles-Elements/ScrollToTop/ScrollToTop";
import ProtectedRouter from './RouteMiddleware/ProtectedRouter';
import Header from './pages/layout/header/Header';
import CheckoutHeader from './pages/layout/CheckoutHeader/CheckoutHeader';
import PaymentRedirect from './RouteMiddleware/PaymentRedirect';
import PaymentCancelRedirect from './RouteMiddleware/PaymentCancelRedirect';
import WalletPaymentRedirect from './RouteMiddleware/WalletPaymentRedirect';
// import pages
import Home from "./pages/Home/Home";
import SignUp from "./pages/auth/signUp/SignUp";
import MyProfile from "./pages/MyProfile/MyProfile"
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import TermsComponent from "./pages/TermsConditions/TermsComponent";
import Storefronts from "./pages/Storefront/Storefronts";
import Store from "./pages/businessCategory/BusinessCategory";
import BusinessCategory1 from "./pages/businessCategory1/BusinessCategory1";
import RecommendedStore from "./pages/recommendedStore/RecommendedStore";
import OrderHistory from "./pages/OrderHistory/OrderHistory";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import OrderDetailsProgress from "./pages/OrderDetails/OrderDetailsProgress";
import Loaders from "./Loaders";
import PageNotFound from "./pages/Error/PageNotFound";
import ManageCards from "./pages/ManageCards/ManageCards";
import Wishlist from "./pages/Wishlist/Wishlist";
import ManageAddress from "./pages/ManageAddress/ManageAddress";
import Wallet from "./pages/Wallet/Wallet";
import AddMoney from "./pages/AddMoney/AddMoney";
import ProductDetail from "./pages/ProductDetail/ProductDetailGrocery";
import DeleteAccount from "./pages/DeleteAccount";
import RatingReview from "./pages/RatingReview/RatingReview";
import CardPage from "./pages/CardCheckout/CardPage";
import CheckoutPage from "./pages/CardCheckout/CheckoutPage";
import ThankyouPage from "./pages/ThankyouPage/ThankyouPage";
import FieldTransaction from "./pages/FieldTransaction/FieldTransaction";
import Faq from "./pages/Faq/Faq";
import ContactUs from "./pages/ContactUs/ContactUs";
import StaticPages from "./pages/StaticPages/StaticPages";
import RedirectRoute from "./RouteMiddleware/redirect";
import { axiosConstant } from './Helpers/Axios/Constants';
import {changeBaladiData} from './redux/reducers/baladiData';
import { ampli, DefaultConfiguration, EventWithOptionalProperties } from "./ampli";
import MetaTagsx from "pages/layout/header/Meta";
 

const userId = "abdulqadir.shantiinfotech@gmail.com";
ampli.load({
  client: {
    apiKey: axiosConstant.REACT_APP_AMPLITUDE_API_KEY,
    configuration: { ...DefaultConfiguration, logLevel: 3 },
  },
});

const App = () => {

  const { i18n } = useTranslation();
  const loader = useSelector(currentLoader);
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const [scrollPosition, setScrollPosition] = useState(0);

  const timeDifference = (date1, date2) => {
    return Math.floor((date1.getTime() - date2.getTime()) / 1000);
  }

  const getBaladiData = async () => {
    const response = await getRequest(API_ROUTES.COMMON.BALADI_DATA_GET)
    setLocalStorage(LOCAL_STORAGE_KEYS.BALADI_DATA, JSON.stringify({
      BALADI_BRAND_Id: response.BALADI_BRAND_Id,
      BALADI_STORE_Id: response.BALADI_STORE_Id,
      BALADI_STORE_SLUG: response.BALADI_STORE_SLUG,
    }));
    dispatch(changeBaladiData())
  }

  const checkActiveLink = () => {
    if (
      !location.href.split('/').includes('storefront') &&
      !location.href.split('/').includes('recommended-store') &&
      !location.href.split('/').includes('store') &&
      !location.href.split('/').includes('product-detail')
    ) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVE_LINK)
      dispatch(changeActiveLink());
    }
  }

  const refreshAuthToken = useCallback(async () => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      const decoded = jwt_decode(token);
      const timeLeft = timeDifference(new Date(decoded.exp * 1000), new Date())
      if (timeLeft < 60) {
        const response = await getRequest(API_ROUTES.AUTHENTICATION.REFRESH_TOKEN)
        if (response.ack === 1) {
          setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response.data);
        }
      }
    }
  })

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  const changeLanguage = (lang) => {
    localStorage.setItem('i18nextLng', lang)
    i18n.changeLanguage(lang);
  }

  const getAndSetMachineId = () => {
    if (!getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID)) {
      setLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID, uuid());
    }
  }

  useEffect(() => {
    getAndSetMachineId();
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      refreshAuthToken();
      setInterval(() => { refreshAuthToken(); }, 2000);
    }
    setInterval(() => {
      checkActiveLink()
    }, 1000);
  }, [])

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')) {
      if (!['ar', 'en'].includes(localStorage.getItem('i18nextLng'))) {
        localStorage.setItem('i18nextLng', 'en')
        changeLanguage('en')
      }
      changeLanguage(localStorage.getItem('i18nextLng'))
    } else {
      localStorage.setItem('i18nextLng', 'en')
      changeLanguage('en')
    }
  }, [])

  useEffect(() => {
    if (snackbar?.snackbarOpen) {
      setTimeout(() => {
        dispatch(
          changeSnackbar({
            ...snackbar,
            isOpen: false,
          })
        );
      }, 2000);
    }
  }, [snackbar?.isOpen]);

  useEffect(() => {
    moengage.initialize(
      {
        app_id: 'AAQ4CGML94BROU2042F4FSRP',
        debug_logs: 0,
        cluster: 'DC_3',
      }
    );

    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    refreshAuthToken();
    getBaladiData()
  }, []);

  return (
    <>
    {/* <MetaTagsx /> */}
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/terms-conditions-app" element={<TermsComponent />} />
          <Route path="/product-detail/potato-red-doha" element={<RedirectRoute />} />
          <Route path="/" element={<Header />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/terms-conditions" element={<TermsConditions />} />
            <Route exact path="/:pageType/get" element={<StaticPages />} />
            <Route exact path="/storefront/:slug" element={<Storefronts />} />
            <Route exact path="/store/:storeslug/:categorySlug?" element={<Store />} />
            <Route exact path="/category/:storeslug" element={<BusinessCategory1 />} />
            <Route exact path="/recommended-store/:slug" element={<RecommendedStore />} />
            <Route exact path="/product-detail/:storeslug/:productslug/:categorySlug?" element={<ProductDetail />} />
            <Route exact path="/rating-review/:slug" element={<RatingReview />} />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route path="/" element={<ProtectedRouter />}>
              <Route exact path="/my-profile" element={<MyProfile />} />
              <Route exact path="/delete-account" element={<DeleteAccount />} />
              <Route exact path="/order-details" element={<OrderDetails />} />
              <Route exact path="/order-detail/:orderId" element={<OrderDetailsProgress />} />
              <Route exact path="/order-history" element={<OrderHistory />} />
              <Route exact path="/manage-address" element={<ManageAddress />} />
              <Route exact path="/wallet" element={<Wallet />} />
              <Route exact path="/add-money" element={<AddMoney />} />
              <Route exact path="/manage-cards" element={<ManageCards />} />
              <Route exact path="/wishlist" element={<Wishlist />} />
              <Route exact path="/thankyou/:orderid" element={<ThankyouPage />} />
              <Route exact path="/failed/:orderid" element={<FieldTransaction />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/404" element={<PageNotFound />} />
          </Route>
          <Route path="/" element={<CheckoutHeader />}>
            <Route exact path="/cart" element={<CardPage />} />
            <Route path="/" element={<ProtectedRouter />}>
              <Route exact path="/checkout" element={<CheckoutPage />} />
            </Route>
          </Route>
          <Route exact path="/redirect" element={<PaymentRedirect />} />
          <Route exact path="/cancel-redirect" element={<PaymentCancelRedirect />} />
          <Route exact path="/wallet-redirect" element={<WalletPaymentRedirect />} />
        </Routes>
      </Router>

      <Snackbar
        open={snackbar?.isOpen}
        onClose={() => {
          dispatch(
            changeSnackbar({
              ...snackbar,
              isOpen: false,
            })
          );
        }}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: snackbar?.vertical, horizontal: snackbar?.horizontal }}
      >
        <Alert
          onClose={() => {
            dispatch(
              changeSnackbar({
                ...snackbar,
                isOpen: false,
              })
            );
          }}
          severity={snackbar?.state}
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
      {loader ? <Loaders /> : null}
      {scrollPosition > 200 && (
        <button className="scroll-up-btn" onClick={handleClick}></button>
      )}
    </>
  );
};

export default App;
