/** 
 * @file RemoveItem.js
 * @description Remove Item Dialog Box
 * @exports RemoveItem - Dialog Box
 * @exports CloseDialog - Function to close the dialog box
 * @exports RemoveItem - Function to remove the item from cart and move to wishlist
 * @exports RemoveItemOnly - Function to remove the item from cart and not move to wishlist
*/

import { Button, Grid, Dialog, DialogContent } from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg"
import { useTranslation } from "react-i18next";

const RemoveItem = ({ CloseDialog, RemoveItem, RemoveItemOnly }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={true} onClose={CloseDialog} className="main-modal theme-dialog">
      <img src={modalClose} alt="close" onClick={CloseDialog} className='modal-close' />
      <DialogContent>
        <h2 className="h3" style={{ marginBottom: "8px" }}>
          {t("cart_Remove1item")}
        </h2>
        <p className="p3" style={{ marginBottom: "16px", fontSize: "14px" }}>
          {t("cart_Areyousureyouwanttoremove1productfromcart")}
        </p>
        <Grid item xs={12} textAlign="center" display={"flex"} justifyContent={"flex-end"}>
          <Button
            type="button"
            size="medium"
            variant="text"
            color="primary"
            onClick={RemoveItemOnly}
          >
            {t("cart_Remove")}
          </Button>
          <Button type="button" size="medium" variant="text" color="error"
            onClick={RemoveItem}
          >
            {t("cart_MovetoWishlist")}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveItem;
