/** @format */
import "./recommendedStore.scss";
import { useParams, Link } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { getRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeActiveLink } from "../../redux/reducers/activeLink";
import { changeLoader } from "../../redux/reducers/loader";
import { axiosConstant } from '../../Helpers/Axios/Constants';
import { setLocalStorage, LOCAL_STORAGE_KEYS } from "../../Helpers/crypto/LocalStorage";
import NoDataFound from '../../global-modules/EmptyPages/NoDataFound';
import Footer from "../layout/footer/Footer";
import Breadcrumb from "../../shared/Breadcrumb";
import timeGreen from "../../assets/images/structure/time-green.svg";
import starYellow from "../../assets/images/structure/star-yellow.svg";
import starGray from "../../assets/images/structure/star-gray.svg";
import ClipLoader from "react-spinners/ClipLoader";
import useIntersection from '../../Helpers/useIntersection'
import Image from "../../global-modules/Image/Image";
import {currentAddress} from '../../redux/reducers/address';

const RecommendedStore = () => {

  const { t } = useTranslation();
  const userAddress = useSelector(currentAddress)
  const { slug } = useParams();
  const ref = useRef(null);
  const inViewport = useIntersection(ref, '0px');

  let [loadmore, setLoadmore] = useState(false);
  let [storeCanLoad, setStoreCanLoad] = useState(false);
  let [limit, setLimit] = useState(15);
  let [page, setPage] = useState(1);
  let [stores, setStores] = useState([]);
  let [businessType, setBusinessType] = useState(null);
  let [breadcrums, setBreadcrums] = useState([]);

  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);

  const getAllBusinessTypes = async () => {
    const response = await getRequest(API_ROUTES.COMMON.GET_BUSINESS_CATEGORIES);
    businessType = response.businesstypes.find(type => type.id === parseInt(slug));
    setBusinessType(businessType);
  };

  const setBreadcrumsValue = () => {
    breadcrums = [{
      title: t('storefront_homepage'),
      haveLink: true,
      link: '/'
    }, {
      title: businessType?.business_type_locales[0].name,
      haveLink: true,
      link: '/storefront/' + businessType?.slug
    }, {
      title: t("recommendedStores"),
      haveLink: false,
      link: ''
    }];
    setBreadcrums(breadcrums);
  }


  const getRecomendedStoresFromSlug = async () => {
    dispatch(changeLoader(true));
    let url = API_ROUTES.STOREFRONT.GET_STORES(slug);
    url += `?limit=${limit}&page=${page}&recommended=true`
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const response = await getRequest(url)
    if (response.ack === 1) {
      stores = response.stores;
      setStores(stores)
      dispatch(changeLoader(false));
      setTimeout(() => {
        setStoreCanLoad(true)
      }, 3000);
    } else {
      setStores([])
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
      dispatch(changeLoader(false));
    }
  }


  const loadMoreData = async () => {
    setStoreCanLoad(false)
    setLoadmore(true)
    page++;
    setPage(page);
    let url = API_ROUTES.STOREFRONT.GET_STORES(slug);
    url += `?limit=${limit}&page=${page}&recommended=true`
    const response = await getRequest(url)
    if (response.ack === 1) {
      stores.push(...response.stores)
      setStores(stores);
      setLoadmore(false)
      if (response.stores.length > 0) {
        setTimeout(() => {
          setStoreCanLoad(true)
        }, 2000);
      } else {
        setStoreCanLoad(false)
      }
    } else {
      setStores([])
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
      setLoadmore(false)
    }
  }

  if (!loadmore && storeCanLoad && inViewport && stores?.length > 0) {
    loadMoreData();
  }

  const setActiveLink = async () => {
    setLocalStorage(LOCAL_STORAGE_KEYS.ACTIVE_LINK, slug)
    dispatch(changeActiveLink());
  }

  useEffect(() => {
    setBreadcrumsValue();
  }, [businessType])

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllBusinessTypes();
    getRecomendedStoresFromSlug();
    setActiveLink();
  }, [slug])

  useEffect(() => {
    getRecomendedStoresFromSlug
  }, [userAddress])

  return (
    <div>
      <div className="recommended-store-wrapper">
        <div className="container-body">
          <Breadcrumb breadcrums={breadcrums} />

          <div className="recommended-head">
            <h3 className="h3">{t("recommendedStores")}</h3>
          </div>

          {stores.length > 0 ? (
            <div className="recommended-content">
              {stores.map((store, index) => (
                <Link
                  to={`/store/${store?.slug && store?.slug !== '' ? store?.slug : store?.id}`}
                  className={`recommended-box ${store.online_status === 1 ? "" : "disable"}`}
                  key={`recomendation-${index}`}
                >
                  <div className="recommended-top">
                    <Image src={axiosConstant.IMAGE_BASE_URL(store.business_logo)}
                      alt={store?.stores_locales[0]?.business_name} />
                    <span className="closed-chips">Closed</span>
                  </div>
                  <div className="recommended-bottom">
                    <h6 className="h6">{store?.stores_locales[0]?.business_name}</h6>
                    <p className="p3">
                      {store?.business_address && store?.business_address !== '' ? store?.business_address :
                        `${store.zone_number}, ${store.street_number}, ${store.building_number}, ${store.country}`}
                    </p>
                    <Boxed
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      mb={"0px"}
                      className="always-ltr-center"
                    >
                      <img src={timeGreen} alt="timeGreen" />
                      <span className="p2 p2-mins">10 {t("mins")}</span>
                    </Boxed>
                    <Boxed
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      className="rating-show-block"
                    >
                      <img src={starYellow} className="star-icon" alt="starYellow" />
                      <img src={starYellow} className="star-icon" alt="starYellow" />
                      <img src={starYellow} className="star-icon" alt="starYellow" />
                      <img src={starGray} className="star-icon" alt="starGray" />
                      <img src={starGray} className="star-icon" alt="starGray" />
                    </Boxed>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <NoDataFound text={t("storefront_NoStoresFound")} />
          )}
          <div className="loader-wrap" ref={ref}>
            <ClipLoader color={"#EF4930"} loading={loadmore} speedMultiplier={0.5} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RecommendedStore;
