import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FormGroup, FormControlLabel, Checkbox, Divider
} from "@mui/material";
import { getNameBasedOnLanguage, displayPrice } from '../../Helpers/HelperFunctions';

const CheckboxComponent = ({
	addon,
	selectedAddons,
	changeValue,
	showErrorMessage
}) => {

	let [minimumItem, setMinimumItem] = useState(0);
	let [maximumItem, setMaximumItem] = useState(0);
	let [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		if (addon) {
			setMinimumItem(addon?.minimum_item);
			maximumItem = addon?.maximum_item;
			setMaximumItem(maximumItem);
		}
	}, [addon]);

	useEffect(() => {
		selectedItems = selectedAddons.filter((obj) => obj?.addonId === addon?.id).map((obj) => obj?.addonItemId);
		setSelectedItems(selectedItems);
	}, [selectedAddons]);

    const { t } = useTranslation();

    return (<>
    	<div className="flex-center-between">
        <div className="relative">
          <h4 className="h4 capitalize">{getNameBasedOnLanguage(addon?.addons_locales)}</h4>
					{minimumItem > 0?
						addon?.maximum_item > 0?
							<p className="p4">{t('storefront_SelectBetween')} {addon?.minimum_item} - {addon?.maximum_item} {t('storefront_options')}</p>:
							<p className="p4">{t('storefront_SelectMinimum')} {addon?.minimum_item} {t('storefront_options')}</p>
						:addon?.maximum_item > 0?
							<p className="p4">{t('storefront_SelectUpTo')} {addon?.maximum_item} {t('storefront_options')}</p>:
							null
					}
        </div>
				{minimumItem > 0?<span className="badges">{t("Required")}</span>: null}
      </div>
      <FormGroup>
        {addon?.addon_items?.length > 0 ? addon?.addon_items?.map((obj, ind) =>
					<div className="flex-center-between" key={`addon-items-${ind}`}>
						<FormControlLabel
							className="check-label-margin capitalize"
							control={<Checkbox
								checked={selectedItems.includes(obj?.id)}
								onChange={(e) => {
									if (e.target.checked && maximumItem > 0 && selectedItems.length >= maximumItem) {
										showErrorMessage('error', t('storefront_MaximumItemReached'));
										return false;
									}
									changeValue(e.target.checked, addon?.id, obj?.id)
								}}
							/>}
							label={getNameBasedOnLanguage(obj?.addon_items_locales)} />
						{obj?.price > 0?
							<h4 className="h4">{t('storefront_QAR')} {displayPrice(obj?.price)}</h4>:
							<h4 className="h4">{t('storefront_Free')}</h4>
						}
					</div>
				): null}
      </FormGroup>
			<Divider className="my-4" />
    </>)
};

export default CheckboxComponent;