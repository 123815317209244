/**
 * @file StatusBadge.js
 * @description This component is used to show status badge.
 * @exports StatusBadge component
 * @param {string} status - status of order
 */

import { useTranslation } from "react-i18next";

const StatusBadge = ({ status }) => {

  const { t } = useTranslation();

  return (
    <>
      {status === 'pending' ? <span className='badges pending'>{t('order_pending')}</span> : null}
      {status === 'placed' ? <span className='badges placed'>{t('order_placed')}</span> : null}
      {status === 'processing' ? <span className='badges processing'>{t('order_processing')}</span> : null}
      {status === 'readytopick' ? <span className='badges readytopick'>{t('order_processing')}</span> : null}
      {status === 'shipped' ? <span className='badges shipped'>{t('order_Dispatched')}</span> : null}
      {status === 'completed' ? <span className='badges completed'>{t('order_Delivered')}</span> : null}
      {status === 'rejected' ? <span className='badges rejected'>{t('order_rejected')}</span> : null}
      {status === 'cancelled' ? <span className='badges cancelled'>{t('order_cancelled')}</span> : null}
    </>
  );
}

export default StatusBadge;