/** @format */
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import { Button } from "@mui/material";
import "./ThankyouPage.scss";
import checkGreenIcon from "../../assets/images/structure/check-creem.webp";
import Footer from "../layout/footer/Footer";
import { useTranslation } from "react-i18next";
import { changeCart } from '../../redux/reducers/cart';
import { LOCAL_STORAGE_KEYS, getLocalStorage } from '../../Helpers/crypto/LocalStorage';
import { ampli } from "../../ampli";
import jwt_decode from "jwt-decode";


const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};
const ThankyouPage = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orderid } = useParams();
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  useEffect(() => {
    ampli.track({
      event_type: 'CheckoutCompleted',
      event_properties: {
        category: 'Docs',
        name: 'SDK Library',
        description: 'SDK Library Description',
      },
    });

    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    ampli.track({
      event_type: 'ProductPurchased',
      event_properties: {
        category: 'Docs',
        name: 'SDK Library',
        description: 'SDK Library Description',
        user_id: userID,
        total_amount: 0,
        order_id: orderid,
      },
    })
  }, [])


  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CART);
    dispatch(changeCart());
    setTimeout(() => { fire(); }, 500);
  }, [])



  return (
    <>
      <div className="thankyou-content">
        <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        <img src={checkGreenIcon} alt="..." className="" />
        <h3 className="h3">{t("cart_OrderPlaced")}</h3>
        <p className="p2">
          {t("cart_OrderID")}: {orderid}
        </p>
        <p className="p2">
          {t("cart_Thankyou")}
        </p>
        <Link to={'/order-history'} className="no-decoration">
          <Button type="button" variant="contained" size="large" sx={{ width: "150px" }}>
            {t("cart_myOrders")}
          </Button>
        </Link>
      </div>

      <Footer />
    </>
  );
};

export default ThankyouPage;
