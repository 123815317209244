import "./StaticPages.scss"
import { useTranslation } from "react-i18next";
import {useParams, useNavigate} from "react-router-dom";
import { useState, useEffect } from 'react';
import { getRequest } from '../../Helpers/Axios/Services'
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes'
import { currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import Footer from '../layout/footer/Footer';
import Breadcrumb from "../../shared/Breadcrumb";

const StaticPages = () => {
  
  const navigate = useNavigate();
  const { pageType } = useParams();
  const { t } = useTranslation();
  let [terms, setTerms] = useState(null);
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);

  const title = [
    {
      name: t('static_pages_disclaimer'),
      type: 'disclaimer'
    },
    {
      name: t('static_pages_warrenty_policy'),
      type: 'warrenty_policy'
    },
    {
      name: t('static_pages_delivery_information'),
      type: 'delivery_information'
    },
    {
      name: t('static_pages_about_baladi'),
      type: 'about_baladi'
    },
    {
      name: t('static_pages_privacy_policy'),
      type: 'privacy_policy'
    }
  ];

  const getTerms = async () => {
    changeLoader(true);
    const response = await getRequest(API_ROUTES.STATIC_PAGES.GET_STATIC_PAGES(pageType));
    if (response?.ack) {
      terms = response?.cms;
      setTerms(terms);
      changeLoader(false);
    } else {
      changeLoader(false);
      navigate('/404');
    }
  }

  useEffect(() => {
    getTerms();
    window.scrollTo(0, 0);
  }, [pageType]);

  return (
    <div>
      <div className="term-condition-wrapper">
        <div className='container-body'>
          <Breadcrumb
            breadcrums={[
              {
                title: t("storefront_homepage"),
                haveLink: true,
                link: "/",
              },
              {
                title: title.find((t) => t.type === pageType)?.name,
                haveLink: false,
              },
            ]}
          />
          <div className="term-condition-head">
            <h3 className="h3">{title.find((t) => t.type === pageType)?.name}</h3>
          </div>
          <div className="" dangerouslySetInnerHTML={{__html: terms?.cms_locales[0].description,}}></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default StaticPages;
