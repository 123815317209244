/**
 * @file activeLink.js
 * @description This file contains the active link reducer
 * @exports {Object} activeLink
 * @exports {Object} currentActiveLink
 * @exports {Object} changeActiveLink
 */

import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';

const localValue = () => {
  let link = getLocalStorage(LOCAL_STORAGE_KEYS.ACTIVE_LINK);
  if (link) {
    return link
  } else {
    return 0
  }
}

export const activeLink = createSlice({
  name: 'activeLink',
  initialState: {
    value: localValue()
  },
  reducers: {
    changeActiveLink: (state, action) => {
      state.value = localValue()
    }
  },
})

export const { changeActiveLink } = activeLink.actions;
export const currentActiveLink = (state) => state.activeLink.value
export default activeLink.reducer;
