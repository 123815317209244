/**
 * @file RefreshCart.js
 * @description Check Cart Dialog Box
 * @exports RefreshCart - Dialog Box
 * @exports DiscardGuest - Function to discard guest cart
 * @exports DiscardUser - Function to discard user cart
 */

import { Grid, Dialog, DialogContent, Button } from '@mui/material';
import modalClose from "../assets/images/structure/close-black.svg";
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import { postRequest } from '../Helpers/Axios/Services';
import { API_ROUTES } from '../Helpers/Axios/ApiRoutes';

const RefreshCart = ({ closePopup, userData, goToCheckOut }) => {
  const { t } = useTranslation();

  const reFreshCart = async (e) => {
    e.preventDefault();
    const response = await postRequest(API_ROUTES.CART.CART_REFRESH, {
        userId: userData?.id,
        deviceId: getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID)
    });
    goToCheckOut();
  };

  return (
    <Dialog open={true} onClose={reFreshCart} className="main-modal theme-dialog">
      {/* <img src={modalClose} alt="close" onClick={closePopup} className='modal-close' /> */}
      <DialogContent>
        <h2 className='h3 text-center' style={{ marginBottom: '8px' }}>{t('cart_RefreshCart')}</h2>
        <p className='p3 text-center' style={{ marginBottom: '12px', fontSize: '14px' }}>{t('cart_RefreshCartText')}</p>
        <Grid item xs={12} textAlign="center" display={'flex'} justifyContent={'flex-end'}>
          <Button type='button' size="medium" variant="text" color="primary" onClick={reFreshCart}>{t('cart_OK')}</Button>
          {/* <Button type='button' size="medium" variant="text" color="error">{t('cart_discard_user')}</Button> */}
        </Grid>
      </DialogContent>
    </Dialog>
  )
};

export default RefreshCart;