/**
 * @file AddAddress.js
 * @description Add Address Dialog Box
 * @exports AddAddress - Dialog Box
 * @exports handleClose - Function to close the dialog box
 * @exports addressSaved - Function to close the dialog box and show success message
 * @imports addressEditId - Id of the address to be edited (if any)
 */
import { useState, useEffect } from 'react';
import { Grid, Dialog, DialogContent, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import modalClose from "../../assets/images/structure/close-black.svg"
import { postRequest, getRequest, putRequest, getThirdPartyRequest } from '../../Helpers/Axios/Services'
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes'
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { useTranslation } from "react-i18next";
import { axiosConstant } from '../../Helpers/Axios/Constants';
import jwt_decode from "jwt-decode";
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import { changeAddress } from '../../redux/reducers/address';
import { PhoneMask } from '../../global-modules/MaskInputs/MaskedInputs';
import { getAdressFromLatLng } from '../../Helpers/Geocode';
import { geocodeByPlaceId, } from 'react-google-places-autocomplete';
import MyMapComponent from './MapComponent';
import GoogleAutocompletePlaces from './GoogleAutocompletePlaces';
import AutocompleteAddress from './AutocompleteAddress';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ampli } from "../../../src/ampli";
import axios from 'axios';
// import Select from 'react-select';

const AddAddress = ({
  handleClose,
  addressSaved,
  addressEditId,
  placeHolder = 'google',
}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const [selectedOption, setSelectedOption] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  let [fullName, setFullName] = useState('');
  let [fullNameError, setFullNameError] = useState('');
  let [countryCode, setCountryCode] = useState(axiosConstant.COUNTRY_CODE);
  let [phone, setPhone] = useState('');
  let [phoneError, setPhoneError] = useState('');
  let [title, setTitle] = useState('Other');
  let [titleError, setTitleError] = useState('');
  let [zoneNumber, setZoneNumber] = useState('');
  let [zoneNumberError, setZoneNumberError] = useState('');
  let [apartmentNumber, setApartmentNumber] = useState('');
  let [apartmentNumberError, setApartmentNumberError] = useState('');
  let [streetNumber, setStreetNumber] = useState('');
  let [streetNumberError, setStreetNumberError] = useState('');
  let [buildingNumber, setBuildingNumber] = useState('');
  let [buildingNumberError, setBuildingNumberError] = useState('');
  let [buildingAddress, setBuildingAddress] = useState('');
  let [buildingAddressError, setBuildingAddressError] = useState('');
  let [latitude, setLatitude] = useState('');
  let [longitude, setLongitude] = useState('');
  let [country, setCountry] = useState('Qatar');
  let [defaultAddress, setDefaultAddress] = useState('true');
  let [userToken, setUserToken] = useState(null);
  let [googleAddress, setGoogleAddress] = useState([]);
  let [placeId, setPlaceId] = useState('');


  const getAddresses = async () => {
    const response = await getRequest(API_ROUTES.ADDRESS.GET_ADDRESS);
    if (response.ack === 1) {
      const address = response.addresses.find((address) => address.default_address === true);
      setLocalStorage(LOCAL_STORAGE_KEYS.TOTAL_ADDRESS, response.addresses.length);
      setLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_ADDRESS, JSON.stringify(address));
      dispatch(changeAddress());
    }
  }

  const popolateAddress = async (address) => {
    if (address) {
      if (placeHolder === 'google') {
        // ---------- Google Address ---------- //
        googleAddress = address;
        setGoogleAddress(googleAddress);
        enableResetGoogleAddress();
        placeId = address.value.place_id;
        setPlaceId(placeId);
        const geocode = await geocodeByPlaceId(address.value.place_id);
        buildingAddress = geocode[0].formatted_address;
        setBuildingAddress(buildingAddress);
        setBuildingAddressError('');
        latitude = geocode[0].geometry.location.lat();
        setLatitude(latitude);
        longitude = geocode[0].geometry.location.lng()
        setLongitude(longitude);
        callApiForCheckAddress(latitude, longitude);
        // ---------- Google Address ---------- //
      } else {
        // ---------- Open Street Map Address ---------- //
        buildingAddress = address.display_name;
        callApiForCheckAddress(address.lat, address.lon);
        setLatitude(address.lat);
        setLongitude(address.lon);
        setBuildingAddressError('');
        setBuildingAddress(buildingAddress);
        // ---------- Open Street Map Address ---------- //
      }
    }
  };

  const setAddressFronSearch = (address, place_id) => {
    placeId = place_id;
    setPlaceId(placeId);
    buildingAddress = address.formatted_address;
    setBuildingAddress(buildingAddress);
    setBuildingAddressError('');
    // latitude = address.geometry.location.lat;
    latitude = address.lat;
    setLatitude(latitude);
    // longitude = address.geometry.location.lng;
    longitude = address.lng;
    setLongitude(longitude);
    callApiForCheckAddress(latitude, longitude);
  };

  const enableResetGoogleAddress = () => {
    try {
      let elements = document.getElementsByClassName("css-tlfecz-indicatorContainer");
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener("click", resetGoogleAddress, true);
      }
    } catch (error) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: 'Something went wrong',
        state: "error",
      }));
    }
  };

  const handleSubmit = async (e) => {
    dispatch(changeLoader(true));
    e.preventDefault();
    let isValid = true;
    if (userToken) {
      if (fullName === '') {
        setFullNameError(t('Address_FullNameRequired'));
        isValid = false;
      }
      if (phone === '') {
        setPhoneError(t('Address_PhoneRequired'));
        isValid = false;
      }
      // else if (phone.length !== 8) {
      //   setPhoneError(t('Address_PhoneLimit'));
      //   isValid = false;
      // }

      if (title !== 'Other') {
        if (zoneNumber === '') {
          setZoneNumberError(t('Address_ZoneNumberRequired'));
          isValid = false;
        }
        if (streetNumber === '') {
          setStreetNumberError(t('Address_FloorNumberRequired'));
          isValid = false;
        }
        if (buildingNumber === '') {
          setBuildingNumberError(t('Address_BuildingNumberRequired'));
          isValid = false;
        }
      }
    }
    if (buildingAddress === '') {
      buildingAddressError = t('Address_PleaseEnterBuildingAddress');
      setBuildingAddressError(buildingAddressError);
      isValid = false;
    }

    if (userToken) {
      if (title === '') {
        setTitleError(t('Address_PleaseEnterAddressTitle'));
        isValid = false;
      }
    }
    if (isValid) {
      if (userToken) {
        let data = {
          full_name: fullName,
          contact_number: phone.slice(countryCode.toString().length),
          country_code: countryCode,
          title: title,
          zone_number: zoneNumber,
          street_number: streetNumber,
          apartment_number: apartmentNumber,
          building_number: buildingNumber,
          business_address: buildingAddress,
          latitude: latitude,
          longitude: longitude,
          country: country,
          default_address: defaultAddress
        };
        let response;
        if (addressEditId) {
          response = await putRequest(API_ROUTES.ADDRESS.EDIT_ADDRESS(addressEditId), data);
        } else {
          response = await postRequest(API_ROUTES.ADDRESS.ADD_ADDRESS, data);
        }
        if (response.ack === 1) {
          dispatch(changeLoader(false));
          dispatch(changeSnackbar({ ...snackbar, isOpen: true, message: response.msg, state: "success", }));
          await getAddresses();
          addressSaved();
        } else {
          dispatch(changeLoader(false));
          if (response?.msg && response?.msg !== '') {
            dispatch(changeSnackbar({ ...snackbar, isOpen: true, message: response.msg, state: "error", }));
          } else if (response?.errMsg) {
            response?.errMsg.forEach(element => {
              for (const [key, value] of Object.entries(element)) {
                if (key === 'full_name') { setFullNameError(value) }
                if (key === 'contact_number') { setPhoneError(value) }
                if (key === 'title') { setTitleError(value) }
                if (key === 'zone_number') { setZoneNumberError(value) }
                if (key === 'street_number') { setStreetNumberError(value) }
                if (key === 'building_number') { setBuildingNumberError(value) }
                if (key === 'latitude') {
                  dispatch(changeSnackbar({ ...snackbar, isOpen: true, message: value, state: "error", }));
                }
                if (key === 'longitude') {
                  dispatch(changeSnackbar({ ...snackbar, isOpen: true, message: value, state: "error", }));
                }
                if (key === 'country') {
                  dispatch(changeSnackbar({ ...snackbar, isOpen: true, message: value, state: "error", }));
                }
              }
            });
          }
        }
      } else {
        let changedAddress = {
          id: 0,
          zone_number: zoneNumber,
          street_number: streetNumber,
          building_number: buildingNumber,
          business_address: buildingAddress,
          apartment_number: apartmentNumber,
          latitude: latitude,
          longitude: longitude,
          country: country,
        };
        setLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_ADDRESS, JSON.stringify(changedAddress));
        dispatch(changeAddress());
        dispatch(changeLoader(false));
        addressSaved();
      }
      //ampli===
      let userID = '';
      if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
        const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
        let decoded = jwt_decode(token);
        userID = decoded?.id;
      }

      ampli.track({
        event_type: 'AddressAddded',
        event_properties: {
          category: 'Docs',
          name: 'SDK Library',
          description: 'SDK Library Description',
          user_id: userID,
          address: buildingAddress,
          latitude: latitude,
          longitude: longitude,
        },
      })
    } else {
      dispatch(changeLoader(false));
    }
  }

  const getAddressFromLatLng = async (lat, lng) => {
    latitude = lat;
    setLatitude(latitude);
    longitude = lng;
    setLongitude(longitude);
    if (placeId === '') {
      buildingAddress = await getAdressFromLatLng(latitude, longitude);
      setBuildingAddress(buildingAddress);
      setBuildingAddressError('');
    }
  }

  const getAddressEdit = async (id) => {
    const response = await getRequest(API_ROUTES.ADDRESS.GET_SPECIFIC_ADDRESS(id));
    setTitle(response.address.title);
    setFullName(response.address.full_name);
    countryCode = response?.address?.country_code;
    setCountryCode(countryCode);
    setPhone(`${countryCode}${response.address.contact_number}`);
    setZoneNumber(response.address.zone_number);
    setStreetNumber(response.address.street_number);
    setApartmentNumber(response.address.apartment_number);
    setBuildingNumber(response.address.building_number);
    setBuildingAddress(response.address.business_address);
    setLatitude(response.address.latitude);
    setLongitude(response.address.longitude);
    setCountry(response.address.country);
    setDefaultAddress(response.address.default_address);
  }

  const getUserNameAndPhone = async () => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      userToken = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      setUserToken(userToken)
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      setFullName(decoded?.full_name);
      countryCode = decoded?.country_code;
      setPhone(`${countryCode}${decoded?.contact_number}`);
      setCountryCode(countryCode)
    } else {
      userToken = null;
      setUserToken(userToken);
    }
  }

  useEffect(() => {
    if (addressEditId) {
      getUserNameAndPhone();
      getAddressEdit(addressEditId);
    } else {
      getUserNameAndPhone();
    }
  }, [addressEditId])

  const callApiForCheckAddress = async (lat, lng) => {
    let url = API_ROUTES.ADDRESS.CHECK_ADDRESS;
    let params = {
      latitude: lat,
      longitude: lng
    }
    getRequest(url, {
      params: params
    }).then((result) => {
      if (result?.ack == 1) {
        getAddressFromLatLng(lat, lng)
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: result?.msg,
          state: "success",
        }));
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: result?.msg,
          state: "error",
        }));
      }
    }).catch((err) => {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: `Something went wrong : ${err}`,
        state: "error",
      }));
    })
  }

  return (
    <Dialog open={true} onClose={handleClose} className="main-modal maxwidth-576">
      <img src={modalClose} alt="close" onClick={handleClose} className='modal-close' />
      <DialogContent className="padding-24">
        <form onSubmit={handleSubmit} action="#" method='post'>
          <h5 className='h5' style={{ fontSize: '16px', marginBottom: '16px', marginTop: '10px' }}>
            {userToken ?
              addressEditId ? t('Address_EditAddress') : t('Address_AddNewAddress') :
              t('Address_changeAddress')}
          </h5>
          <Grid container spacing={3} >
            {userToken ? <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
              <TextField label={t('Address_FullName') + '*'} value={fullName}
                onChange={(e) => { setFullName(e.target.value); setFullNameError(''); }}
                inputProps={{ maxLength: 100 }}
              />
              <span className="errorspan">{fullNameError}</span>
            </Grid> : null}
            {userToken ? <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
              <PhoneInput
                country={'qa'}
                value={phone}
                className="phone-input"
                enableLongNumbers={true}
                onKeyDown={(e) => {
                  if ((phone.length - countryCode.length) >= 16 && e.keyCode !== 8) {
                    e.preventDefault();
                  }
                }}
                onChange={(value, data) => {
                  setPhone(value);
                  setPhoneError('');
                  setCountryCode(data?.dialCode);
                }}
              />
              <span className="errorspan">{phoneError}</span>
            </Grid> : null}

            {userToken ? <Grid item xs={12} style={{ position: 'relative' }} >
              {/* <h5 className='h5' style={{ fontSize: '16px', marginBottom: '16px', marginTop: '0px' }}>{t('Address_SaveAddressAs')}</h5> */}
              <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t('Address_suchashomeofficeworketc')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={title}
                label={t('Address_suchashomeofficeworketc')}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setTitleError('')
                }}
              >
                <MenuItem value={'Home'}>{t('Address_Home')}</MenuItem>
                <MenuItem value={'Office'}>{t('Address_Office')}</MenuItem>
                <MenuItem value={'Other'}>{t('Address_Other')}</MenuItem>
              </Select>
            </FormControl>
              <span className="errorspan">{titleError}</span>
            </Grid> : null}

            <Grid item xs={12} style={{ position: 'relative' }} >
              <AutocompleteAddress
                setAddress={setAddressFronSearch}
              />
              {/* {placeHolder === 'google' ?
                <GoogleAutocompletePlaces
                  googleAddress={googleAddress}
                  onChange={(val) => {
                    popolateAddress(val);
                    enableResetGoogleAddress();
                  }}
                /> :
                <AutocompleteAddress
                  setAddress={popolateAddress}
                />
              } */}
            </Grid>
            <Grid item xs={12} style={{ position: 'relative' }} >
              <MyMapComponent
                lat={latitude}
                lng={longitude}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${axiosConstant.GOOGLE_GEOCODE_API_KEY}&libraries=places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `200px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                ChangeAddress={(lat, lng) => {
                  placeId = '';
                  setPlaceId(placeId);
                  callApiForCheckAddress(lat, lng);
                }}
              />
            </Grid>
            
            <Grid item xs={12} style={{ position: 'relative' }} >
              <TextField label={t('Address_BuildingAddress') + '*'}
                value={buildingAddress} />
              <span className="errorspan">{buildingAddressError}</span>
            </Grid>
            <Grid item xs={12} sm={6} style={{ position: 'relative' }} >
              <TextField label={`${t('Address_ApartmentNumber')}`}
                value={apartmentNumber} onChange={(e) => {
                  apartmentNumber = e.target.value.replace(/\D/g, "");
                  setApartmentNumber(apartmentNumber);
                  setApartmentNumberError('')
                }} />
              {/* <span className="errorspan">{streetNumberError}</span> */}
            </Grid>
            <Grid item xs={12} sm={6} style={{ position: 'relative' }} >
              <TextField label={`${t('Address_BuildingNumber')} ${title !== 'Other'? '*': ''}`}
                value={buildingNumber} onChange={(e) => {
                  buildingNumber = e.target.value.replace(/\D/g, "");
                  setBuildingNumber(buildingNumber);
                  setBuildingNumberError('')
                }} />
              <span className="errorspan">{buildingNumberError}</span>
            </Grid>
            <Grid item xs={12} sm={6} style={{ position: 'relative' }} >
              <TextField label={`${t('Address_FloorNumber')} ${title !== 'Other'? '*': ''}`}
                value={streetNumber} onChange={(e) => {
                  streetNumber = e.target.value.replace(/\D/g, "");
                  setStreetNumber(streetNumber);
                  setStreetNumberError('')
                }} />
              <span className="errorspan">{streetNumberError}</span>
            </Grid>

            <Grid item xs={12} sm={6} style={{ position: 'relative' }} >
              <TextField label={`${t('Address_ZoneNumber')} ${title !== 'Other'? '*': ''}`}
                value={zoneNumber} onChange={(e) => {
                  zoneNumber = e.target.value.replace(/\D/g, "");
                  setZoneNumber(zoneNumber);
                  setZoneNumberError('')
                }} />
              <span className="errorspan">{zoneNumberError}</span>
            </Grid>

            <Grid item xs={12} sm={6} style={{ position: 'relative', pointerEvents: 'none' }} >
              <TextField label={t('Address_Country') + '*'} value={country} />
            </Grid>


            
          </Grid>
          <Grid item xs={12} textAlign="center" display={'flex'} justifyContent={'flex-end'}>
            <Button type='submit' size="large" variant="contained" style={{ width: '115px', marginTop: '24px' }} >{userToken ? t('Address_save') : t('Address_change')}</Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
};

export default AddAddress;