import "./TermsConditions.scss"
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import TermsComponent from './TermsComponent'
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../shared/Breadcrumb";

const TermsConditions = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="term-condition-wrapper">
        <div className='container-body'>
          <Breadcrumb
            breadcrums={[
              {
                title: t("storefront_homepage"),
                haveLink: true,
                link: "/",
              },
              {
                title: t('terms_Conditions'),
                haveLink: false,
              },
            ]}
          />
          <div className="term-condition-head">
            <h3 className="h3">{t('terms_Conditions')}</h3>
          </div>
          <TermsComponent />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsConditions;
