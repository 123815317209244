import Button from "@mui/material/Button";
import "./CardCheckout.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { currentAddress, totalAddress } from "../../redux/reducers/address";

const AddressBox = ({ userToken, handleChangeAddress }) => {

  const { t } = useTranslation();
  const userAddress = useSelector(currentAddress);
  const total_address = useSelector(totalAddress);

  return (
    <div className="address-box">
      {userAddress ? <span>
        {userAddress?.id !== 0 ?
          <div className="relative">
            {userAddress?.full_name ?
              <p className="p2 bold">
                {t('cart_Deliver_to')} : {userAddress?.full_name} | {userAddress?.contact_number}
              </p> : null}
            <p className="p2">
              {userAddress?.business_address && userAddress?.business_address !== "" ?
                `${userAddress?.apartment_number} ${userAddress?.building_number} ${userAddress?.street_number} ${userAddress?.zone_number} ${userAddress?.business_address}` :
                `${userAddress?.zone_number}, ${userAddress?.building_number}, ${userAddress?.street_number}, ${userAddress?.country}`
              }
            </p>
          </div> : null}
      </span> : null}

      {userToken ?
        <Button
          className="MuiButton-outlinedPrimary"
          size="large"
          onClick={handleChangeAddress}
        >{total_address > 0 ? t("cart_ChangeAddress") : t('cart_AddNewAddress')}</Button>
        : null}
    </div>
  );
};

export default AddressBox;
